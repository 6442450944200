.dataset {
  position: relative;
  width: 100%;
  height: 100%;

  .arco-divider-vertical {
    border-left: 5px solid #406EFF !important;
    margin-left: 0 !important;
  }

  .outline {
    width: 87px;
    height: 32px;
    border-radius: 8px;
  }

  .arco-empty .arco-empty-wrapper img {
    width: 129px;
    height: 113px;
  }

  .Empty-none {
    margin-top: 118px
  }

  .Emptydata-btns {
    text-align: center;
    margin-top: 24px;
  }

  .Emptydata-btn {
    width: 150px;
    height: 48px;
    background: #406eff;
    border-radius: 14px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      // background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }
  }

  .GroupTable-Search {

    .arco-input-inner-wrapper {
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }


  .arco-progress-large .arco-progress-line-text {
    margin-left: 13px;
    color: #0c0c0f;
  }

  .table-button {
    button {
      padding: 0 2px;
    }
  }

  .arco-table-custom-filter {
    padding: 10px;
    background-color: var(--color-bg-5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  .multi {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.Tooltiphid {
  max-width: calc(100% - 300px) !important;
  max-height: 200px;
  overflow-y: scroll;
}

// .custom-upload-progress .arco-upload-list-item-text-content {
//     flex-wrap: wrap;
//   }

//   .custom-upload-progress .arco-upload-list-start-icon,
//   .custom-upload-progress .arco-upload-list-cancel-icon {
//     right: 0;
//     left: unset;
//     top: -22px;
//     transform: none;
//   }

//   .custom-upload-progress .arco-upload-list-status {
//     display: block;
//   }

//   .custom-upload-progress .arco-upload-list-progress {
//     display: block;
//     height: 0;
//     margin-top: 0;
//     transition: all 0.2s ease;
//     opacity: 0;
//     overflow: hidden;
//   }

//   .custom-upload-progress .arco-upload-list-item-uploading .arco-upload-list-progress {
//     margin-top: 8px;
//     opacity: 1;
//     height: 16px;
//   }