.myapp {
  position: relative;
  padding: 0 35px;
  background: #f9fbff;

  .suspension {
    position: fixed;
    right: 9px;
    bottom: 60px;
    width: 39px;
    height: 119px;
    background: linear-gradient(180deg, #7fafff, #2f76ff 58%);
    border-radius: 7px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    line-height: 15px;
    padding: 6px;
    cursor: pointer;
    z-index: 99;

    &:hover {
      background: linear-gradient(180deg, #5997ff, #0158ff 58%);
    }

    &:active {
      background: linear-gradient(180deg, #085cff, #5997ff);
    }

    .IconCloseCircle {
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 14px;
      color: #5591ff;
      cursor: pointer;

      &:hover {
        color: #79aaff;
      }

      &:active {
        color: #2f76ff;
      }
    }

    .IconCloseCircle.arco-icon[stroke='currentColor'] {
      fill: #ffffff;
    }
  }

  .myapplist {
    position: relative;
    height: 100%;

    .tableCard {
      border-radius: 22px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-table-header {
      overflow: hidden !important;
    }

    .outlineTable {
      border-radius: 8px !important;
    }

    .GroupTable-fixe {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .arco-btn-icon-only {
        width: 45px;
        height: 45px;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      }
    }

    .GroupTable-Search {
      .arco-input-inner-wrapper {
        height: 45px;
        background: #ffffff;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      }
    }

    .GroupTable {

      width: auto;
      height: 45px;
      background: #f2f6ff;
      border-radius: 24px;
      overflow: hidden;

      .arco-radio-button {
        width: 140px;
        height: 38px;
        line-height: 38px;
        color: #1d2129;
        font-size: 14px;
        text-align: center;
      }

      .arco-radio-button:hover {
        border-radius: 24px;
      }

      .arco-radio-button::after {
        background: #f2f6ff;
      }

      .arco-radio-button:not(:first-of-type)::before {
        background: transparent;
        color: transparent;
      }

      .arco-radio-button.arco-radio-checked {
        width: 140px;
        height: 38px;
        background: #406eff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }
    }

    .ApplicationList-Swiper {
      position: relative;
      width: 100%;
      margin-top: 12px;
    }

    .ApplicationList-wrap {
      position: relative;
      width: 100%;
      padding-top: 20px;
      height: 680px;
      margin: 0 auto;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      box-sizing: border-box;
      scrollbar-color: #d3dbeb rgba(0, 0, 0, 0.1);
      /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
      scrollbar-width: thin;
      /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
      -ms-overflow-style: none;
    }

    /*整个滚动条*/
    .ApplicationList-wrap::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: transparent;
    }

    /*定义滚动条轨道*/
    .ApplicationList-wrap::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /*定义滑块*/
    .ApplicationList-wrap::-webkit-scrollbar-thumb {
      border: 4px solid #d3dbeb;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: #d3dbeb;

    }

    .content {
      width: 100%;
      padding-left: 18px;
      margin: 0 auto;
      // display: inline-flex;
      // flex-wrap: wrap;
    }
    .content-left{
      width: 100%;
      padding-left: 18px;
    }

    .ApplicationList-item.grid-item {
      // display: inline-block;
      // margin-right: 38px;
      position: relative;
      width: 379px;
      height: 162px;
      background: #ffffff;
      border-radius: 22px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      // padding: 14px 30px;
      box-sizing: border-box;
      text-align: left;
      // padding: 16px;
      transition: all 0.4s ease-out;
      margin-bottom: 27px;
      cursor: pointer;

      .founder {
        height: auto !important;
      }

      .arco-typography-operation-edit {
        display: none;
      }

      .arco-tag-icon {
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }

      .arco-icon-hover {
        display: none;
      }

      .ischeckbox {
        .arco-icon-hover {
          display: block;
        }
      }

      .arco-checkbox {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        .arco-checkbox-text {
          height: 100%;
          margin: 0;
        }

        .arco-checkbox-mask {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 2px solid #406eff;
        }

        .arco-checkbox-mask-wrapper {
          position: absolute;
          top: -9px;
          left: -9px;
        }
      }

      .arco-checkbox-checked .arco-checkbox-mask-icon {
        transform: scale(1.3)
      }

      .arco-checkbox-checked .arco-checkbox-mask {
        background-color: #406eff;
      }

      .arco-checkbox-disabled .arco-checkbox-mask {
        border-color: transparent;
      }
    }

    .ApplicationList-item:hover {
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
      transform: translateY(-5px);
      transition: all 0.4s ease-out;
    }

    .arco-checkbox-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .grid-header {
      width: 100%;
    }

    .grid-top {
      width: 100%;
      height: 104px;
      border-radius: 14px;
      padding: 16px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      img {
        width: 20px;
        height: 20px;
      }

      .group-label {
        display: block;
        font-size: 16px;
        color: #20242f;
      }
    }

    .grid-bottom {
      width: 100%;
      height: 100%;
      border-radius: 0 0 22px 22px;
      overflow: hidden;

      .editable-box {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        height: 58px;
        color: #ffffff;
        font-size: 14px;

        .editable-Paragraph {
          color: #ffffff;
          font-size: 14px;
        }
      }

      .editable-Paragraph-Input.arco-textarea {
        resize: none;
        font-size: 12px;
        height: 100%;
        border-radius: 8px;
        color: #ffffff;
        background: transparent;

        &:focus {
          border-color: #e6ebf5;
        }
      }

      .nohover-bottom {
        height: 100%;
        margin: 0 16px;
        border-top: 1px solid #e7e7e7;
        color: #20242F;
        font-size: 14px;

        .Clock-time {
          width: 50%;
          text-align: left;
          font-size: 12px;
          color: #4e5969;

          .arco-icon.IconClockCircle {
            margin-right: 6px;
            color: #4e5969;
            font-size: 12px;
          }
        }
      }

      &:hover {
        .arco-typography-operation-edit {
          display: inline-block;
          font-size: 12px;
          color: #ffffff;

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }

    // .ApplicationList-item:nth-child(4n+4) {
    //   margin-right: 0;
    // }

    .state-icon {
      text-align: right;
      font-size: 12px;

      .arco-tag {
        border-radius: 14px;
      }
    }

    .applistAvatar {
      position: relative;
      width: 24px;
      height: 24px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      overflow: hidden;
    }

    .grid-top-title {
      width: 100%;
      color: #20242f;
      margin-top: 16px;

      .arco-space-item:last-of-type {
        width: 100%;

        a {
          display: none;
        }
      }

      .editable-title.arco-input {
        height: 30px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        color: #1d2129;

        &:focus {
          border-color: #e7e7e7;
        }
      }

      .rowSpaceBetween {
        .swiper-Title {
          width: 80%;
        }
      }

      .swiper-Title {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 18px;
        color: #1d2129;

        .label-name {
          padding: 4px;
          margin-right: 5px;
        }

        .hover-title:hover .arco-icon {
          display: inline-block;
        }

        .arco-icon {
          display: none;
          color: #4e5969;

          &:hover {
            background: #f7f8fa;
            border-radius: 2px;
          }
        }

        .hover-title:hover {
          .label-name {
            background: #f7f8fa;
            border-radius: 2px;
          }
        }
      }
    }

    .swiper-fix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #ffffff;

      .delcircle {
        color: #4e5969;
        font-size: 14px;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          color: #9DA5B1;
        }

        &:hover {
          color: #406EFF;
        }

        &:active {
          color: #0E42D2;
        }
      }

      .arco-tag {
        height: 28px;
      }
    }

    .ellipasedom {
      min-width: 50px;
      max-width: 100%;
      overflow: hidden;
      /* 三行显示省略号 */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      .hover-desc:hover {
        .arco-icon {
          display: inline-block;
        }
      }

      .arco-icon {
        display: none;
        margin-left: 5px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 2px;
        }
      }
    }

    .editable-Paragraph {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;

      .arco-typography-operation-edit {
        background-color: transparent;
      }

      a {
        display: none;
      }
    }

    .dispose_icon {
      display: block;
      width: 18px;
      height: 18px;
      background-image: url('/asserts/dispose_icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .myapp-demo {
    max-height: 710px;

    .arco-empty .arco-empty-wrapper .arco-empty-image {
      margin-bottom: 23px;
    }

    .arco-empty-image {
      img {
        width: 128px;
        height: 113px;

      }
    }
  }

  .leftCard {
    height: 720px;
    overflow: auto;
  }

  .arco-list-wrapper {
    margin-top: 20px;
  }

  .Skeletonlist {
    margin-bottom: 10px;
    background: #f9fbff;
    padding: 10px;
    text-align: left;
    align-items: center;

  }

  .arco-list-item-meta-content {
    width: 100%;
  }

  .SkeletonBadge {
    margin-left: 20px;
  }

  .arco-list-item:not(:last-child):hover {
    border: 0.5px solid #406EFF;
  }

  .arco-list-item:not(:last-child) {
    border-bottom: 0;
    margin-bottom: 10px !important;
    background: #ffffff;
    border-radius: 4px;
    border: 0.5px solid #cdcdcd;
    cursor: pointer;
  }

  .arco-list-item-action>li:not(:last-child) {
    margin: 0 10px;
  }

  .arco-list-item-action {
    margin-left: 10px;
  }

  .arco-list-item-meta {
    text-align: left;
  }

  .list-demo-actions-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.1s;
  }

  .list-demo-actions-icon:hover {
    background-color: var(--color-fill-3);
  }

  .list-demo-actions-button {
    position: relative;
    padding: 0 4px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s;
  }

  .list-demo-actions-button:hover {
    background-color: var(--color-fill-3);
  }

  .list-demo-actions .arco-list-item-action li:not(:last-child) .list-demo-actions-button::after {
    content: '';
    position: absolute;
    top: 3px;
    right: -10px;
    width: 1px;
    height: 12px;
    background-color: var(--color-fill-3);
  }

  .Emptydata {
    font-size: 16px;
    text-align: center;
    color: #4e5969;
  }

  // 创建
  .addmyapp {
    position: relative;
    opacity: 0;
    .cardform {
      position: relative;
      width: 77%;
      height: 100%;
      background: #ffffff;
      border-radius: 22px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

      .arco-card-body {
        // padding: 41px 31px;
      }

      .startParagraph {
        text-align: center;
        font-size: 24px;
        color: #0a1629;
        padding-top: 40px;
        padding-bottom: 43px;
        margin-bottom: 0;
      }
    }

    .arco-radio-group .arco-radio {
      margin-bottom: 0;
      margin:0 15px;
      margin-top: 20px;
    }

    .selsect-fix-label-marginTop {
      margin-top: 42px;
      box-sizing: border-box;
    }
    // .selsect-fix-label{
    //   padding-left: 15px;
    //   box-sizing: border-box;
    // }
    .selsect-fix-tag{
      padding: 2px 5px;
      color: #BA8928;
      font-size: 12px;
      background: linear-gradient(90deg, #FFF0C1 0%, #F6D493 100%);
      border-radius: 4px;
      margin-left: 10px;
    }
    .arco-space-item {
      margin-bottom: 0px !important;
    }

    .forminput {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #4E5969;
      margin-bottom: 15px;
      padding: 0 10rem;
      overflow: auto;

      .arco-space {
        text-align: left;
      }

      .formtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 11px;
      }

      .arco-textarea,
      .arco-input-inner-wrapper {
        padding: 8px 11px;
        background-color: #ffffff;
        border-color: #d8e0f0;
        border-radius: 14px;
        box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
      }

      .arco-input-inner-wrapper:focus,
      .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
      .arco-textarea:focus,
      .arco-textarea.arco-textarea-focus {
        border-color: #406EFF;
      }
    }

    .select-foot {
      width: 100%;
      padding: 0px 31px;
      text-align: left;

      .select-foot-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4.1rem 0;
      }
      .arco-select-size-default.arco-select-single .arco-select-view{
        width: 166px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        border-radius: 10px;
      }
      .arco-select .arco-select-view{
        border: 1px solid #D8E0F0;
        background: #FFFFFF;
      }
      .arco-select.arco-select-focused .arco-select-view {
        background-color: var(--color-bg-2);
        border-color: #406EFF;
      }
    }

    .Model-selsect-fix {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // padding-left: 18px;

      .Model-selsect-item {
        width: 166px;
        padding: 13px 0;
        font-size: 16px;
        color: #20242f;
        border: 1px solid #d8e0f0;
        border-radius: 10px;
        box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        text-align: center;
        box-sizing: border-box;
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      .Model-type {
        margin-right: 40px;
      }

      .Model-selsect-item:nth-child(3n+3) {
        margin-right: 0px;
      }

      .Model-selsect-item-hover {
        background: #406eff;
        color: #ffffff;
      }

      .Model-selsect-item-tag {
        position: relative;
      }

      .Model-selsect-item-tag::before {
        position: absolute;
        content: '\5b98\65b9\6a21\578b';
        width: 64px;
        height: 22px;
        line-height: 20px;
        background: linear-gradient(90deg, #fff0c1, #f6d493);
        border-radius: 4px;
        color: #ba8928;
        font-size: 12px;
        text-align: center;
        top: -12px;
        right: -24px;
      }
    }

    .listloadMore {
      position: relative;
      // max-height: 360px;
      // overflow: auto;
    }

    .addData {
      margin-top: 16px;

      .arco-upload {
        padding-left: 18px;
        margin-bottom: 16px;
      }

      .upload-Link {
        padding-left: 18px;
        margin-bottom: 13px;

        .arco-link {
          font-size: 16px;
          padding-left: 0;
        }
      }

      .TipsData {
        width: 465px;
        background: #f6faff;
        border-radius: 14px;
        padding: 13px;
        font-size: 14px;
        margin-left: 18px;
      }

      .TipsData-link {
        padding-left: 20px;
        margin-top: 8px;

        .arco-link {
          font-size: 14px;
          text-decoration: underline;
        }
      }


      .fie_img {
        margin-top: 11px;
      }
    }

    .arco-divider-horizontal {
      margin: 0;
    }

    .arco-icon {
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .Prev {
      font-size: 16px;
      width: 132px;
      height: 48px;
      border-radius: 10px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .Next {
      width: 145px;
      height: 48px;
      font-size: 16px;
      background: #406eff;
      border-radius: 14px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .cardstep {
      height: 100%;
      padding: 0 35px;
      padding-right: 23px;
      color: #ffffff;
      margin-left: 39px;

      .arco-card-body {
        padding: 0;
      }

      .arco-typography {
        font-size: 16px;
        color: #ffffff;
      }

      .Step-model {
        .arco-steps-item-tail {
          margin-left: 37px;
        }
      }

      .Step-dispose .arco-steps-item-title,
      .Step-corpus .arco-steps-item-title,
      Step-training .arco-steps-item-title {
        font-size: 16px !important;
      }

      .arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail {
        left: 12px;
      }

      .Step-training,
      .Step-dispose,
      .Step-corpus,
      .Step-model {
        .arco-steps-item-tail {
          left: 14px !important;
        }
      }

      .arco-steps-icon {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }

      .Step-dispose .arco-steps-icon,
      .Step-corpus .arco-steps-icon,
      .Step-training .arco-steps-icon {
        width: 22px;
        height: 22px;
        line-height: 22px;
      }

      .Step-dispose .arco-steps-item-icon,
      .Step-corpus .arco-steps-item-icon,
      .Step-training .arco-steps-item-icon {
        margin-left: 3px;
      }

      .arco-steps-vertical .arco-steps-item:not(:last-child) {
        min-height: 66px;
      }

      .Typography-Titles {
        margin-left: 4px;
      }

      h1.arco-typography {
        margin-bottom: 5px;
        margin-top: 0;
        font-size: 28px;
        color: #ffffff;
      }

      h5.arco-typography {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 16px;
        color: #ffffff;
      }

      .Space-large {
        margin-top: 52px;
      }

      .arco-steps-item-finish .arco-steps-item-title {
        font-size: 18px;
        color: #ffffff;
      }

      .arco-steps-item-active .arco-steps-item-title {
        color: #ffffff !important;
      }

      .arco-steps-item-process .arco-steps-item-title,
      .arco-steps-item-wait .arco-steps-item-title {
        color: rgba(255, 255, 255, 0.30);
        font-size: 18px;
      }

      .arco-steps-item:not(:last-child).arco-steps-item-finish .arco-steps-item-tail::after {
        background: #ffffff;
      }

      .arco-steps-item-finish .arco-steps-icon {
        background: #ffffff;
      }

      .arco-steps-item-process .arco-steps-icon {
        color: transparent;
        background: rgba(255, 255, 255, 0.30);
        border: 2px solid #ffffff;
      }

      .arco-steps-item-wait .arco-steps-icon {
        opacity: 0.5;
        color: transparent;
        background: transparent;
        border: 2px solid #ffffff;
      }

      .Health_coverages {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 290px;
        height: 350px;

      }

      .Health_coverage {
        background: url('/asserts/Health_coverage.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 22px;
      }

      .Health_coverage2 {
        background: url('/asserts/Health_coverage2.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 22px;
      }

      .Health_coverage3 {
        background: url('/asserts/Health_coverage3.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 22px;
      }

      .Health_coverage4 {
        background: url('/asserts/Health_coverage4.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 22px;
      }

      .Health_coverage5 {
        background: url('/asserts/Health_coverage5.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 22px;
      }
      .ai_ppt_buttom{
        background: url('/asserts/ai_ppt_buttom.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .ai_resume_buttom{
        background: url('/asserts/ai_resume_buttom.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .ai_marketing_buttom{
        background: url('/asserts/ai_marketing_buttom.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .ai_pact_buttom{
        background: url('/asserts/ai_pact_buttom.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .addmyapp-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .arco-card {
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .model_tag{
    border-radius: 4px;
    color: #BA8928;
    font-size: 12px;
    padding: 3px 8px;
    background: linear-gradient(90deg, #FFF0C1 0%, #F6D493 100%);
    white-space: nowrap;
    user-select: none;
  }
  .modelv_name{
    margin-right: 6px;
  }
  .arco-table-virtualized div.arco-table-body div.arco-table-td{
    line-height: 20px;
  }
  .custom-radio-card-title{
    width: 104px;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.payment-modal.arco-modal.combo-modal{
  width: 860px;
}
.chose-model-list .arco-checkbox{
  margin-bottom: 20px;
}

.custom-radio-card-firm{
  opacity: 0.7; // 降低透明度，使元素看起来变灰
  pointer-events: none; // 禁用鼠标事件，使其不可点击
  cursor: not-allowed; // 更改光标样式，表示不可用

  // 可以根据需要添加更多样式
  background-color: #f0f0f0; // 设置背景颜色为浅灰色
  color: #888888; // 设置文本颜色为灰色
  border: 1px solid #cccccc; // 设置边框颜色为灰色
}
.custom-radio-card-firm ::before{
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  background-image: url('/asserts/icon-enterprise.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.custom-radio-card-box{
  margin-bottom: 10px;
  .custom-radio-card-titles{
    font-size: 18px;
    line-height: 1.5;
    color: var(--color-text-1);
    font-weight: 600;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1550px) {
  .myapp .myapplist{
  .GroupTable{
    white-space: nowrap;
    overflow: overlay;
    .arco-radio-button {
      width: 10em;
      height: 38px;
      line-height: 38px;
      color: #1d2129;
      font-size: 12px;
      text-align: center;
    }
    .arco-radio-button.arco-radio-checked {
      width: 10em;
      height: 38px;
      background: #406eff;
      border-radius: 20px;
      color: #ffffff;
      font-size: 12px;
      line-height: 36px;
      text-align: center;
    }
  }
}

}