.step-list{
    position: relative;
    width: 100%;
    font-size: 12px;
    line-height: 24px;
    color: #4E5969;
    .step{
        width: 205px;
        margin-left: 2px;
        text-align: center;
        
    .step-title{
        height:24px;
        font-size: 14px;
        color: #0A1629;
    }
    .description{
        height: 24px;
    }
    .step-dot{
        width: 100%;
        margin: 15px 0;
        .dot{
            width: 12px;
            height: 12px;
            background: #406EFF;
            border-radius: 50%;
            margin: 2px;
            }
            .steps-item-tail{
                width: calc(50% - 8px);
                height: 1px;
                border:1px dashed #979797;
                &.steps-item-tail-active{
                    border-color: #406EFF;
                }
            }
            
            
    }
    &.custom-steps-item-active{
        .step-dot{
            .dot{
                background:transparent;
                border:1px solid #406EFF;
                box-sizing: border-box;
            }
        }
    }
    &.custom-steps-item-wait{
        .step-title{
            color: #989898;
        }
        .step-dot{
            .dot{
                background:#D1D1D1;
            }
        }
    }
    &:first-of-type .step-dot{
        .steps-item-tail:first-of-type{
            border-color:transparent;
        }
    } 
    &:last-of-type .step-dot{
        .steps-item-tail:last-of-type{
            border-color:transparent;
        }
    } 
    &.steps-item-tail-fail .step-dot{
        .dot{
            background: #F53F3F;
            color: #FFFFFF;
        }
    }
}
}