.arco-modal.dissolution-tips {
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-header {
    height: 80px;
    padding-left: 32px;
  }

  .arco-modal-header .arco-modal-title {
    font-size: 22px;
    color: #0a1629;
    text-align: left;
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #f5f8ff;
    border-radius: 14px;
    font-size: 22px;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .arco-input-inner-wrapper {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 14px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  }


  .arco-input-inner-wrapper:focus,
  .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
    border-color: #406EFF;
  }

  .arco-modal-footer {
    padding-right: 39px;

    .arco-btn {
      width: 98px;
      height: 48px;
      border-radius: 14px;
    }

    .arco-btn.arco-btn-primary:not(.arco-btn-disabled) {
      width: 117px;
    }
  }
}

.arco-modal.dissolution-tips {
  height: auto;

  .arco-modal-content {
    height: auto;
    font-size: 16px;
    color: #0a1629;
    padding-bottom: 48px;
    padding-top: 11px;
  }
}

.dissolution-tips .serviceQRcode {
  width: 244px;
  height: 244px;
  margin: auto;
  background-image: url('/asserts/serviceQRcode.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 26px;
}