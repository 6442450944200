.echnicalsupport {
  padding: 0 34px;

  .arco-table-th-item {
    padding: 20px 0;
  }

  .Card-main {
    width: 100%;
    height: 100%;
  }

  .arco-divider-vertical {
    border-left: 5px solid #406EFF !important;
    margin-left: 0 !important;
  }

  .outline {
    width: 87px;
    height: 32px;
    border-radius: 8px;
  }

  .arco-empty .arco-empty-wrapper img {
    width: 129px;
    height: 113px;
  }

  .Empty-none {
    margin-top: 118px
  }

  .Emptydata-btns {
    text-align: center;
    margin-top: 24px;
  }

  .Emptydata-btn {
    width: 150px;
    height: 48px;
    background: #406eff;
    border-radius: 14px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }
  .GroupTable-Search {
    .arco-input-inner-wrapper {
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      // background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .GroupTable {

      width: auto;
      height: 45px;
      background: #f2f6ff;
      border-radius: 24px;
      overflow: hidden;

      .arco-radio-button {
        width: 111px;
        height: 38px;
        line-height: 38px;
        color: #1d2129;
        font-size: 14px;
        text-align: center;
      }

      .arco-radio-button:hover {
        border-radius: 24px;
      }

      .arco-radio-button::after {
        background: #f2f6ff;
      }

      .arco-radio-button:not(:first-of-type)::before {
        background: transparent;
        color: transparent;
      }

      .arco-radio-button.arco-radio-checked {
        width: 111px;
        height: 38px;
        background: #406eff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }
    }
  }

  .arco-table {
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .arco-modal-header .arco-modal-title {
    text-align: left !important;
  }

  .arco-upload-trigger-drag {
    padding: 20px 0 !important;
  }

  .arco-upload-trigger-drag-text {
    color: #4e5969;
  }

  .arco-upload-trigger-drag .arco-icon-plus {
    margin-bottom: 5px !important;
    font-size: 14px;
  }

  .upload-demo-trigger .trigger {
    background-color: var(--color-fill-2);
    color: var(--color-text-1);
    height: 100%;
    width: 380px;
    border-radius: 2;
    text-align: center;
  }

  .arco-upload-list-item-picture>img {
    border: 1px solid var(--color-neutral-3);
  }

  .arco-upload-trigger-picture-wrapper {
    margin-bottom: 8px;
  }
}