.authentication{
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;
    .authentication-head{
        position: relative;
        width: 100%;
        padding: 24px 0;
        background: #406EFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        overflow: hidden;
       
        .arrowLeft{
            position: absolute;
            top: 24px;
            left: 27px;
            cursor: pointer;
            z-index: 9;
            .arco-link{
                padding:0 10px;
                color: #FFFFFF;
                font-size: 16px;
                &:hover{
                    color: #406EFF;
                }
                .arco-icon{
                    margin-right: 8px;
                    margin-left: 0;
                    stroke-linecap: round; 
                    stroke-linejoin: round;
                }
            }
            
        }
        .authentication-bg{
            position: absolute;
            left: 0;
            top: 0;
            width: 744px;
            height: 932px;
            background-image: url('/asserts/authentication_img.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .arco-icon{
            margin-left: 19px;
        }
        .authentication-img{
            position: absolute;
            right: -263px;
            top: -91px;
            width: 246px;
            height: 246px;
            background-image: url('/asserts/authentication_img2.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
        .authentication-text{
            position: relative;
        }
        
    }
    .authentication-box{
        width: 868px;
        margin: auto;
        padding-bottom: 61px;
        box-sizing: border-box;
        .authentication-steps{
            margin-top: 39px;
            padding-bottom: 8px;
            .authentication-step{
                font-size: 16px;
                color: #A8A8A8;
                .dot{
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    font-size: 14px;
                    line-height: 22px;
                    color: #A8A8A8;
                    border-radius: 50%;
                    margin-right: 12px;
                    border: 1px solid #A8A8A8;
                    box-sizing: border-box;
                }
                .arco-icon{
                    margin-left: 27px;
                    margin-right: 17px;
                    color: #A8A8A8;
                }
                &.active{
                    color: #0A1629;
                    .dot{
                        background: #406EFF;
                        color: #FFFFFF;
                        border: 1px solid #406EFF;
                    }
                    .arco-icon{
                        color: #406EFF;
                    }
                }
            }
        }
        .authentication-form{
            .authentication-item{
                position: relative;
                padding-left: 18px;
                box-sizing: border-box;
                .authentication-lable{
                    position: relative;
                    color: #0A1629;
                    font-size: 14px;
                    margin-bottom: 17px;
                    span{
                        position: absolute;
                        left: -18px;
                        color: #E0280F;
                        font-size: 18px;
                    }
                }
                .authentication-value{
                    margin-bottom: 18px;
                    .arco-upload{
                        position: relative;
                        width: 177px;
                        height: 107px;
                        border-radius: 6px;
                        border: 1px solid #D8E0F0;
                        text-align: center;
                        .arco-upload-trigger,.arco-upload-trigger>div,.trigger{
                            width: 100%;
                            height: 100%;
                            color: #88909D;
                            font-size: 12px;
                        }
                    }
                    .arco-upload-list .arco-card{
                        position: relative;
                        width: 177px;
                        height: 107px;
                        border-radius: 6px;
                        background: #D8D8D8;
                        border: 1px solid #D8E0F0;
                        box-sizing: border-box;
                        overflow: hidden;
                        .arco-card-cover {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            padding: 0px;
                            img {
                            width: 100%;
                            margin: auto;
                            }
                
                            img.fileType {
                            width: 82px;
                            height: 116px;
                            }
                        }
                        .arco-card-body {
                            display: none;
                            position: absolute;
                            width: 100%;
                            background: rgba(0, 0, 0, 0.70);
                            bottom: 0;
                            right: 0;
                            font-size: 16px;
                
                            .arco-card-actions {
                            margin-top: 0;
                            }
                
                            .file-Delete {
                            position: relative;
                            width: 100%;
                            color: #ffffff;
                
                            &:hover {
                                color: #406EFF;
                            }
                
                            &:active {
                                color: #0E42D2;
                            }
                            }
                        }
                        &:hover {
                            .arco-card-body {
                            display: block;
                            }
                        }
                    }
                    .authentication-remarks{
                        margin-top: 9px;
                        font-size: 14px;
                        line-height: 22px;
                        color: #8E95A1;
                    }
                }
                .arco-select .arco-select-view,.arco-input-inner-wrapper{
                    height: 48px;
                    line-height: 46px;
                    border-radius: 14px;
                    background-color: transparent;
                    border: 1px solid #D8E0F0;
                    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
                }
                .arco-select.arco-select-focused .arco-select-view,.arco-input-inner-wrapper:focus,
                .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
                    background-color: var(--color-bg-2);
                    border-color: #406EFF;
                }
                .arco-checkbox-text{
                    color: #919499;
                    a{text-decoration: underline;}
                }
          }
          .arco-alert-warning{
            border: 1px solid #FDC38B;
            border-radius: 4px;
            margin-top: 52px;
          }
          .authentication-primary{
            text-align: right;
            margin-top: 56px;
            .next-step{
                width: 98px;
                height: 48px;
            }
            .submit-step{
                width: 98px;
                height: 48px;
                border-radius: 14px;
                margin-left: 17px;
            }
          }
          .next-step{
            height: 48px;
            line-height: 46px;
            border-radius: 14px;
            color: #406EFF;
            font-size: 16px;
            background: #E7EEFF;
            &:hover {
                color: #406EFF;
                background: #eef3ff;
              }
              &:active {
                background: #e6ecfa;
                color: #2759E0;
              }
          }
          .position{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
          .arco-icon{
            stroke-linecap: round; stroke-linejoin: round;
          }
          .authentication-loding{
            text-align: center;
            font-size: 14px;
            line-height: 22px;
            color: #0A1629;
            .loding-box{
                position: relative;
                width: 88px;
                height: 88px;
                box-sizing: border-box;
                border-radius: 50%;
                margin: 25px auto;
                border: 8px solid #EFF2FA;
                box-sizing: border-box;
                .loding-line{
                    position: absolute;
                    left: -8px;
                    top: -8px;
                    width: 88px;
                    height: 88px;
                    border-radius: 50%;
                    border: 8px solid transparent;
                    border-left-color: #406EFF;
                    box-sizing: border-box;
                    animation: rotate 1.2s infinite linear;
                }
            }
            .loding-text{
                display: flex;
                text-align: center;
            }
            .ellipsis{
                position: relative;
                width: 15px;
            }
            .ellipsis:after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom; 
                animation: ellipsis 1.5s infinite;
                content: "\2026"; /* ascii code for the ellipsis character */
                /* \2026 == ... */ 
                /* content: "..." */
            }
          }
          .authentication-wait{
            font-size: 14px;
            line-height: 22px;
            color: #0A1629;
            text-align: center;
            .wait-check{
                position: relative;
                width: 80px;
                height: 80px;
                box-sizing: border-box;
                margin: 25px auto;
                box-sizing: border-box;
                background-image: url(../../asserts/WAIT_AUDIT.svg);
            }
            .wait-text{
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: 0px;
                color: #0A1629;
            }
          }
          .authentication-success{
            font-size: 14px;
            line-height: 22px;
            color: #0A1629;
            text-align: center;
            .success-check{
                position: relative;
                width: 88px;
                height: 88px;
                box-sizing: border-box;
                border-radius: 50%;
                margin: 25px auto;
                border: 8px solid #406EFF;
                box-sizing: border-box;
                .icon-check{
                    width: 29px;
                    height: 18px;
                    div{
                        width: 8px;
                        height: var(--height);
                        background: #406EFF;
                        border-radius: 6px;
                        // animation: success 1.5s forwards;
                    }
                    div:nth-of-type(1){
                        transform: rotate(-45deg);
                    }
                    div:nth-of-type(2){
                        height: var(--height);
                        margin-top: -31px;
                        margin-left: 16px;
                        transform: rotate(45deg);
                    }
                }
            }
            .arco-btn{
                width: 141px;
                height: 48px;
                line-height: 46px;
                border-radius: 14px;
                margin-top: 82px;
            }
          }
          .authentication-fail{
            font-size: 14px;
            line-height: 22px;
            color: #0A1629;
            text-align: center;
            .fail-check{
                position: relative;
                width: 88px;
                height: 88px;
                box-sizing: border-box;
                border-radius: 50%;
                margin: 25px auto;
                border: 8px solid #FFF7E8;
                box-sizing: border-box;
                .icon-check{
                    width: 9px;
                    height: 36px;
                    div{
                        width: 100%;
                        background: #FF7D00;
                        border-radius: 2px;
                    }
                    div:nth-of-type(1){
                        height: 24px;
                    }
                    div:nth-of-type(2){
                        height: 9px;
                        margin-top: 3px;
                    }
                }
            }
            .fail-steps{
                margin-top: 103px;
            }
            .next-step{
                width: 98px;
            }
            .arco-btn.next-back{
                width: 193px;
                height: 48px;
                line-height: 46px;
                border-radius: 14px;
                margin-left: 17px;
            }
            .arco-link{
                background: transparent;
            }
          }
        }
    }
    @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }
    @keyframes ellipsis {
        from {
          width: 2px;
        }
        to {
          width: 15px;
        }
      }
    @keyframes success {
        from {
            height: 0px;
          }
          to {
            height: var(--height);
          }
    }
}