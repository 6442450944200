.offmodel.arco-modal {
  width: 1258px;
  height: 759px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-close-icon {
    top: 14px;
  }

  .arco-modal-close-icon {
    width: 24px;
    height: 24px;
    background: #f5f8ff;
    border-radius: 7px;
    text-align: center;
    line-height: 24px;
  }

  .arco-icon-hover::before {
    width: 24px;
    height: 24px;
    border-radius: 7px;
  }

  .arco-modal-header .arco-modal-title {
    text-align: left;
  }

  .arco-tabs-header-title {
    img {
      height: 20px;
    }
  }

  .arco-modal-content {
    padding: 27px 30px;
    padding-top: 7px;
  }

  /*整个滚动条*/
  .arco-list::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: transparent;
  }

  /*定义滚动条轨道*/

  .arco-list::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /*定义滑块*/
  .arco-list::-webkit-scrollbar-thumb {
    border: 4px solid #d3dbeb;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #d3dbeb;

  }

  .arco-spin {
    padding: 0 !important;
  }

  .arco-list-item {
    padding: 0 !important;
  }

  .arco-tabs-content {
    height: 640px;
  }

  .Spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ApplicationList-item.grid-item {
    display: block !important;
    margin: 0 14px;
    position: relative;
    // width: 370px;
    height: 284px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.20);
    padding: 14px 30px;
    box-sizing: border-box;
    text-align: left;
    padding: 16px;
    transition: all 0.4s ease-out;
    margin-bottom: 27px;
    cursor: pointer;

    .divider-name {
      border-left: 5px solid #9D9D9D;
      border-radius: 2px;
      margin-left: 0px;
      margin-right: 12px;

    }

    .Typography-desc {
      font-size: 12px;
      padding-left: 17px;
    }

    h6.arco-typography {
      font-size: 14px;
    }

    .arco-typography {
      margin-bottom: 0;
      margin-top: 0px;
    }

    .arco-tag-icon {
      font-size: 14px;

      span {
        font-size: 14px;
      }
    }

    .arco-input {
      height: 30px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
      color: #ffffff;

      &:focus {
        border-color: #ffffff;
      }
    }

    .editable-Paragraph-Input.arco-textarea {
      resize: none;
      font-size: 12px;
      height: 42px;
      border-radius: 8px;
      color: #9d9d9d;

      &:focus {
        border-color: #e6ebf5;
      }
    }

    .arco-icon-hover {
      display: none;
    }

    .ischeckbox {
      .arco-icon-hover {
        display: block;
      }
    }

    .arco-checkbox {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      .arco-checkbox-text {
        height: 100%;
        margin: 0;
      }

      .arco-checkbox-mask {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid #406eff;
      }

      .arco-checkbox-mask-wrapper {
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }

    .arco-checkbox-checked .arco-checkbox-mask-icon {
      transform: scale(1.3)
    }

    .arco-checkbox-checked .arco-checkbox-mask {
      background-color: #406eff;
    }

    .arco-checkbox-disabled .arco-checkbox-mask {
      border-color: transparent;
    }


    .ApplicationList-item:hover {
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
      transform: translateY(-5px);
      transition: all 0.4s ease-out;
    }

    .arco-checkbox-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .grid-header {
      width: 100%;
    }

    .grid-top {
      width: 100%;
      height: 131px;
      border-radius: 14px;
      padding: 15px;
      margin-bottom: 13px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
    }

    .grid-bottom {
      width: 100%;

      .arco-divider-horizontal {
        margin: 15px 0;
      }

      .open-now {
        width: 98px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #406eff;
        border-radius: 7px;
        font-size: 12px;

        .arco-icon {
          display: none;
          margin-left: 5px;
        }

        &:hover {
          background: #406eff;
          border: 1px solid #406eff;
          color: #ffffff;

          .arco-icon {
            display: inline-block;
          }
        }

        &:disabled {
          background: #fafafa;
          color: #aeaeae;
          border: 1px solid #fafafa;
        }

      }
    }

    .state-icon {
      text-align: right;
      font-size: 12px;

      .arco-tag {
        border-radius: 14px;
      }
    }

    .grid-top-title {
      width: 100%;
      margin-bottom: 18px;
      color: #ffffff;
      font-size: 14px;

      h5.arco-typography {
        font-size: 22px;
        margin-top: 14px;
        color: #ffffff
      }

      .arco-typography {
        color: #ffffff
      }

      img {
        width: 46px;
      }

      .arco-typography-operation-edit {
        font-size: 16px;
        color: #ffffff;

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      .arco-space-item:last-of-type {
        width: 100%;

        a {
          display: none;
        }
      }
    }

    .swiper-Title {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 22px;
      color: #ffffff;
    }

    .group-label {
      display: block;
      font-size: 14px;
      color: #ffffff;
    }

    .swiper-fix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #ffffff;

      .delcircle {
        width: 28px;
        height: 28px;
        background: rgba(0, 0, 0, 0.50);
        color: #ffffff;
      }

      .arco-tag {
        height: 28px;
      }
    }

    .swiper-fix>span:last-of-type {
      font-size: 12px;
      color: #ffffff;

      .arco-icon.IconClockCircle {
        margin-right: 6px;
      }
    }

    .ellipasedom {
      width: 180px;
      // word-break: break-all;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }

    .editable-Paragraph {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 12px;
      color: #9d9d9d;

      .arco-typography-operation-edit {
        color: #9d9d9d;
      }

      a {
        display: none;
      }
    }

    .dispose_icon {
      display: block;
      width: 18px;
      height: 18px;
      background-image: url('/asserts/dispose_icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .ApplicationList-item:hover {
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
    transform: translateY(-5px);
    transition: all 0.4s ease-out;
  }
}