.writing {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    margin-top: 14px;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }


    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

  }

  .semanticprototype-Layout {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    .arco-layout-sider-light.Sider-left {
      width: 409px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-right: 3.3rem;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 1rem;
      padding-bottom: 2rem;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-textarea {
          height: 100%;
          resize: none;
          background: #ffffff;
          border-radius: 1rem;
          color: #0b172a;
          padding: 0.8rem;
        }
      }
    }

    .semanticprototype-title {
      font-size: 16px;
      color: #0a1629;
    }

    .Upload-pictures-bottom {
      width: 100%;
      text-align: right;
      padding-right: 1.2rem;
      margin-top: 2rem;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }

  .continuation-body {
    position: relative;
    width: 100%;
    margin-top: 17px;
    overflow: auto;
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 14px;
    padding: 15px 26px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    text-align: left;

    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      padding-left: 7px;
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      .Continuation-list {
        width: 100%;
        text-align: left;

        .Continuation-item {
          width: 100%;
          color: #0c0c0f;
          height: auto;
          background: #fbfbfb;
          border: 1px solid #fbfbfb;
          border-radius: 8px;
          padding: 19px 25px;
          font-size: 14px;
          white-space: pre-line;
          margin-bottom: 20px;

          &:hover {
            background: #f2f7ff;
            border: 1px solid #406eff;
          }

          .item-title {
            font-size: 16px;
            color: #0a1629;
          }

          .Preamble {
            color: #406EFF;
          }
        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 16.6rem;
      height: 4.8rem;
      background: #ffffff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      margin-top: 2rem;
      border: 1px solid #d8e0f0;
      border-radius: 1rem;
    }

    .Spin-loding {
      margin-bottom: 2rem;
    }
  }

  .Continuation-Empty,
  .Continuation-loding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.writing.currency{
  .Sider-left-configure{
    font-size: 14px;
    line-height: 24px;
    color: #4E5969;
    padding: 20px 18px;
    text-align: left;
    box-sizing: border-box;
    div{
      width: 100%;
    }
    .writing-input-title.arco-textarea{
        height: 100%;
        resize: none;
        height: 72px;
        border-radius: 14px;
        padding:12px 18px;
        background: #F9FBFF;
        box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        box-sizing: border-box;
    }
    .writing-key{
      font-size: 16px;
      line-height: 24px;
      color: #0A1629;
      margin-top: 14px;
      margin-bottom: 8px;
    }
    .writing-key:first-of-type{
      margin-top: 0;
    }
  }
  .semanticprototype-Layout .Sider-left-configure .Continuation-input .arco-textarea{
    padding: 18px;
    border-radius: 14px;
    background: #F9FBFF;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    box-sizing: border-box;
  }
}


.arco-modal.custom-modal-download {
  .arco-modal-footer {
    text-align: center;
  }

  .arco-btn.download-btn {
    height: 44px;
    border-radius: 10px;
  }

  .custom-modal-download-content {
    width: 100%;
    height: 456px;
    background: #ffffff;
    padding: 42px 36px;
    border-radius: 8px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10)
    }
  }

  .arco-modal-close-icon {
    top: 0px;
    right: -63px;
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    font-size: 18px;
    color: #0a1629;
    border-radius: 14px;
    line-height: 46px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}