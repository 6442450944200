// 训练中
.In-training {
  position: relative;
  width: 100%;
  height: 780px;
  background: linear-gradient(180deg, #ffffff, #ffffff);
  border-radius: 22px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  margin-top: 30px;
  padding: 25px 28px;
  box-sizing: border-box;
  .ellipsis-text{
    width: 232px;
    margin-bottom: 0;
    text-align: left;
  }
  .Carousel-text {
    background: #ffffff;
  }

  .trained {
    margin-top: 160px;
    text-align: center;

    .arco-result-subtitle {
      margin-bottom: 57px;
    }
  }

  .warningback {
    width: auto;
    height: 48px;
    background: #406eff;
    border-radius: 16px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }

  .colseadd {
    position: absolute;
    right: 25px;
    width: 48px;
    height: 48px;
    background: #e6ecfa;
    border-radius: 14px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    color: #4E5969;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

  }

  .In-training-content {
    margin-top: 100px;
    text-align: center;
    font-size: 20px;
    color: #0a1629;

    .loding-img {
      width: 364px;
      height: 339px;
      background-image: url('/asserts/loding-img.gif');
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
    }

    .training-text {
      margin-top: 35px;
      margin-bottom: 30px;
    }

    .training-loging {
      position: relative;
      width: 470px;
      padding: 17px 23px;
      background: #406eff;
      border-radius: 14px;
      margin: auto;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

      .arco-space-align-center {
        width: 100%;
      }

      .arco-typography {
        color: #ffffff;
      }

      .openAdd {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: 47px;
        font-size: 20px;
        background: #ffffff;
        color: #416ffe;
        border-radius: 10px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      }

      .training-loging-left>.arco-space-item:last-of-type {
        width: 40%;
        text-align: right;
      }

      .training-loging-left>.arco-space-item:first-of-type {
        width: 60%;
      }

      .circular-loding {
        position: relative;
        width: 26px;
        height: 26px;
        background: linear-gradient(180deg, #00f5f1 0%, rgba(0, 231, 223, 0.00));
        border-radius: 50%;
      }

      .circular-loding::before {
        position: absolute;
        top: 7.5px;
        left: 7.5px;
        content: '';
        width: 11px;
        height: 11px;
        background: #00e7df;
        border-radius: 50%;
      }
    }
  }

  .In-training-foot {
    text-align: center;
    font-size: 14px;
    margin: 30px 0;
  }

  .arco-result-icon-tip {
    width: 140px;
    height: 140px;
    line-height: 158px;
  }

  .arco-result-icon {
    font-size: 60px;
  }

  .arco-result-title {
    color: #0c0c0f;
    font-size: 22px;
    margin-bottom: 17px;
    margin-top: 28px;
  }

  .arco-result-subtitle {
    color: #4f5969;
    font-size: 16px;
    margin-bottom: 112px;
  }

  .istrained-list {
    position: absolute;
    right: 28px;
    bottom: 38px;
    width: 100%;
    text-align: right;
    font-size: 16px;
    z-index: 99;

    .istrainedList {
      position: absolute;
      right: 0;
      bottom: 65px;
      background: #ffffff;
      border-radius: 14px;
      border: 0;
      box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
      padding: 16px;

      .arco-list-item.training-Item {
        width: 401px;
        height: 62px;
        background: #f5f8fb;
        border-radius: 14px;
        border-color: transparent;
        padding: 19px 16px;
        margin-bottom: 16px;
        cursor: pointer;

        &:hover {
          background: #eef3ff;
        }

        &:active {
          background: #e6ecfa;
        }

        .training-loging {
          position: relative;
          width: 100%;
          height: 100%;
          font-size: 16px;

          .arco-space-align-center {
            width: 100%;
          }

          .arco-typography {
            color: #4e5969;
          }

          .training-loging-left>.arco-space-item:last-of-type {
            width: 40%;
            text-align: right;
          }

          .training-loging-left>.arco-space-item:first-of-type {
            width: 60%;
          }

          .circular-loding {
            position: relative;
            width: 22px;
            height: 22px;
            background: linear-gradient(180deg, #00f5f1 0%, rgba(0, 231, 223, 0.00));
            border-radius: 50%;
          }

          .circular-loding::before {
            position: absolute;
            top: 6.5px;
            left: 6.5px;
            content: '';
            width: 9px;
            height: 9px;
            background: #00e7df;
            border-radius: 50%;
          }

          .istrained-Text {
            span {
              color: #406eff;
            }
          }
        }
      }



      .istrained-delete {
        color: #979FA8;
        font-size: 16px;
        margin-left: 15px;

        &:hover {
          color: #406EFF;
        }

        &:active {
          color: #0E42D2;
        }

        &.disabled {
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }

      .arco-list-item.training-Item.training-Iteming {
        background: #406eff;


        .istrained-Text {
          span {
            color: #ffffff;
          }
        }

        .arco-typography {
          color: #ffffff;
        }

        .istrained-delete {
          color: #ffffff;

          &:hover {
            color: rgba(255, 255, 255, 0.5);
          }

          &:active {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }

    .istrained-btn {
      width: 111px;
      height: 48px;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }
}