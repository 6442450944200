.creative-model.arco-modal {
  width: 1258px;
  height: 695px;
  background: #ffffff;
  border-radius: 24px;
  padding-top: 10px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
  box-sizing: border-box;
  .arco-modal-close-icon {
    top: 14px;
  }

  .arco-modal-close-icon {
    width: 24px;
    height: 24px;
    background: #f5f8ff;
    border-radius: 7px;
    text-align: center;
    line-height: 24px;
  }

  .arco-icon-hover::before {
    width: 24px;
    height: 24px;
    border-radius: 7px;
  }

  .arco-modal-header .arco-modal-title {
    text-align: left;
  }

  .arco-tabs-header-title {
    img {
      height: 20px;
    }
  }

  .arco-modal-content {
    padding: 27px 30px;
    padding-top: 7px;
  }
  /*整个滚动条*/
  .arco-list::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: transparent;
  }

  /*定义滚动条轨道*/

  .arco-list::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /*定义滑块*/
  .arco-list::-webkit-scrollbar-thumb {
    border: 4px solid #d3dbeb;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #d3dbeb;

  }

  .arco-spin {
    padding: 0 !important;
  }

  .arco-list-item {
    padding: 0 !important;
  }

  .arco-tabs-content {
    height: 570px;
  }

  .Spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .arco-list-item-content{
    margin-top: 16px;
  }
  .ApplicationList-item.grid-item {
      position: relative;
      width: 272px;
      height: 94px;
      border-radius: 16px;
      box-sizing: border-box;
      text-align: left;
      border: 1px solid #F0EBE8;
      white-space: nowrap;
      display: inline-block;
      margin-bottom: 0;
      transition: transform 0.4s ease-out;
      cursor: pointer;
      overflow: hidden;
      .apply-content{
        width: 100%;
        height: 100%;
        padding: 0 13px;
        font-size: 14px;
        box-sizing: border-box;
        
        .apply-img{
          width: 50px;
          height: 50px;
          border-radius: 12px;
          background: #FFFFFF;
          margin-right: 13px;
          img{
            width: 42px;
          }
        }
        .label-name{
          color: #1D2129;
        }
        .label-modelname{
          color: #4E5969;
        }
      }
      .model_img{
        position: absolute;
        top: 0;
        right: -38px;
        width: 144px;
      }
      .model-describe{
        color: #8D93A1;
        font-size: 12px;
        margin-top: 9px;
      }
      .ellipasedom{
        max-width: 128px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .dispose_icon {
        display: block;
        width: 18px;
        height: 18px;
        background-image: url('/asserts/dispose_icon.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 11px;
      }
  }
    
  .ApplicationList-item:hover {
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: transform 0.4s ease-out;
  }
}