.ConfirmOrder{
  position: relative;
  width: 100%;
  height: calc(100% - 46px);
    // 确认订单
    .confirm-order{
        width: 100%;
        height: 100%;
      .order-lable{
          text-align: center;
          margin-top: 12px;
          margin-bottom: 19px;
      }
      .confirm-info{
          width: 100%;
          max-height: calc(100% - 222px);
          border-radius: 14px;
          background: #FFFFFF;
          overflow: auto;
      .ordersid{
          width: 100%;
          padding: 11px 28px;
          background: #F2F6FF;
          text-align: left;
          box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
          font-size: 16px;
          line-height: 28px;
          color: #0A1629;
      }
      .order-info{
          width: 100%;
          padding: 32px;
          box-sizing: border-box;
      }
      .total-amount{
          text-align: right;
          line-height: 28px;
          letter-spacing: 0px;
          font-size: 14px;
          color: #4E5969;
          span{
              color: #406EFF;
              font-size: 16px;
          }
      }
  }
  }
   
  .from-table{
    color: #4E5969;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    .from-item{
        margin-bottom: 14px;
        .from-item-lable{
            width: 80px;
            margin-right: 5px;
        }
        .from-item-value{
            color: #0A1629;
        }
        .from-item-nuit{
            margin-left: 3px;
        }
    }
    
}

  .order-hr{
    height: 1px;
    background-color: #E6EBF5;
    margin: 25px 0;
}
  .promotion-bottom{
    position: absolute;
    width: 100%;
    right: 0px;
    bottom: 0px;
    font-size: 16px;
    .promotion-back{
        position: absolute;
        left: 70px;
        width: 145px;
        height: 48px;
        border-radius: 14px;
        color: #3F8CFF;
        .arco-icon { stroke-linecap: round; stroke-linejoin: round; }
        .arco-link-icon{
            font-size: 16px;
        }
    }
      .promotion-End{
          position: relative;
          right:41px;
          // bottom: 79px;
          margin: 40px 0;
          margin-bottom: 79px;
      }
      .arco-statistic-content .arco-statistic-value-int,.arco-statistic-value-prefix,.arco-statistic-value-decimal {
          color: #406EFF;
          font-size: 32px;
        }
        .arco-statistic-value-decimal{font-size: 22px;}
        .arco-statistic{
          line-height: 24px;
      }
        .settlement{
          width: 152px;
          height: 43px;
          border-radius: 7px;
          font-size: 14px;
          margin-left: 40px;
        }
        .arco-icon-wechat{
          color: #00C800;
          font-size: 24px;
          
      }
      .arco-icon-alipay-circle{
          font-size: 24px;
          color: #06B4FD;
          margin-right: 18px;
          margin-left: 12px;
      }
  }
}