.payment-orderlist {
    position: relative;
    width: 100%;
    height: 100%;
    .arco-tag{
        border-radius: 7px;
    }
    .columns-render{
        min-height: 40px;
    }
}

.arco-modal.Payment-order {
    width: 559px;
    height: auto;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

    .arco-modal-header {
        height: 80px;
        padding-left: 32px;
    }

    .arco-modal-header .arco-modal-title {
        font-size: 22px;
        color: #0a1629;
        text-align: left;
    }

    .arco-modal-close-icon {
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #f5f8ff;
        border-radius: 14px;
        font-size: 22px;
    }

    .arco-icon-hover::before {
        width: 100%;
        height: 100%;
        border-radius: 14px;
    }

    .arco-input-inner-wrapper {
        width: 100%;
        height: 48px;
        background: #ffffff;
        border: 1px solid #d8e0f0;
        border-radius: 14px;
        box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }

    .arco-modal-content {
        height: auto;
        padding: 0px 48px;
        padding-right: 39px;
        padding-bottom: 48px;
        font-size: 14px;
        color: #4e5969;
        overflow: hidden;
    }
}
.arco-modal.custom-modal .arco-modal-header{
    padding: 0 28px;
}
.arco-modal.custom-information{
    .arco-modal-content{
        padding:0 28px;
        margin-bottom: 28px;
        .custom-information-body{
            padding: 20px 29px;
            border-radius: 14px;
            background: #F9FBFF;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
            margin-bottom: 28px;
            margin-top: 20px;
            .custom-form{
                width: 100%;
                margin-bottom: 13px;
                line-height: 24px;
                font-size: 14px;
                .custom-form-lable{
                    width: 100px;
                    text-align: right;
                    margin-right: 16px;
                    color: #4E5969;
                }
                .custom-form-value{
                    width: calc(100% - 120px);
                    color: #0A1629;
                    .arco-icon{
                        font-size: 15px;
                        cursor: pointer;
                        margin-left: 7px;
                        border-radius: 5px;
                        &:hover{
                            background: #E7EEFF;
                        }
                        &:active{
                            background: #d8e0f0;
                        }
                    }
                }
            }
        }
    }

}
.arco-modal.Payment-order {
    height: auto;

    .arco-modal-content {
        height: auto;
        font-size: 20px;
        color: #0a1629;
        padding-bottom: 48px
    }
}

.Payment-order .serviceQRcode {
    width: 247px;
    height: 244px;
    margin: auto;
}
.promotion-payment{
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 17px;
}