.myapp-share {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  background: #f9fbff;

  .wh100 {
    width: 100%;
    height: 100%;
  }

  .arco-spin {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    .loding-title {
      font-size: 22px;
      margin-top: 16px;
      margin-bottom: 10px;
      font-weight: normal;
      line-height: 32px;
      letter-spacing: 0px;
      color: #0C0C0F;
    }

    .loding-tips {
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0px;
      color: #4F5969;
    }
  }

  .content-box-flex {
    display: flex;
  }

  .content-box-flex-c {
    justify-content: center;
  }

  .share-header {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    .header-left {
      align-items: center;
      width: calc(100% - 200px);
    }

    .header-title {
      font-family: AlibabaPuHuiTi-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0px;
      color: #0A1629;
      margin-left: 18px;
      margin-right: 14px;
    }

    .header-tips {
      font-family: AlibabaPuHuiTi-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #4E5969;
    }
  }

  .headr-btn {
    width: 188px;
    height: 35px;
    border-radius: 4px;
    background: linear-gradient(90deg, #FFF0C1 0%, #F6D493 100%);
    font-family: AlibabaPuHuiTi-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    color: #BA8928;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #ab770d;
      background: linear-gradient(90deg, #faf1d4 0%, #f9d99e 100%);
    }

    &:active {
      opacity: 0.7;
    }
  }


  .share-bottom {
    cursor: pointer;
    width: 100%;
    height: 158px;
    border-radius: 20px;
    margin-top: 28px;
    background: linear-gradient(93deg, #4079FF 0%, #3B7CFF 99%);
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    overflow: hidden;

    .bottom-content {
      width: 950px;
      height: 100%;
      margin: 0 auto;
      position: relative;

      .bg-bc1,
      .bg-bc2,
      .bg-bc3 {
        position: absolute;
      }

      .bg-bc1 {
        position: absolute;
        left: 12px;
        top: 116px;
        border-radius: 50%;
        width: 193px;
        height: 179px;
        background: #C2ADFF;
        filter: blur(73px);
      }

      .bg-bc2 {
        position: absolute;
        right: -173px;
        top: 23px;
        border-radius: 50%;
        width: 314px;
        height: 314px;
        background: #61CDFF;
        filter: blur(90px);
      }

      .bg-bc3 {
        position: absolute;
        left: 352px;
        top: -241px;
        width: 254px;
        border-radius: 50%;
        height: 288px;
        transform: rotate(180deg);
        background: #57FFF4;
        filter: blur(122px);
      }

      .content-box {
        position: absolute;
        width: 950px;
        height: 100%;
        padding: 26px 0;
        box-sizing: border-box;

        .bottom-title {
          font-family: AlibabaPuHuiTi-Medium;
          font-size: 22px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0px;
          color: #FFFFFF;
          text-align: center;
          margin-bottom: 28px;
        }
      }


      .content-box-item {
        padding-right: 10px;
        box-sizing: border-box;

        .bottom-icon {
          width: 24px;
          height: 23.99px;
          margin-right: 17px;
        }

        .bottom-arrow {
          margin-top: 6px;
          width: 36px;
          height: 25.5px;
          margin-right: 58px;
        }

        .item-title,
        .item-tips {
          min-width: 130px;
          padding-right: 25px;
        }

        .item-title {
          font-family: AlibabaPuHuiTi-Medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0px;
          color: #FFFFFF;
        }

        .item-tips {
          font-family: AlibabaPuHuiTi-Medium;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .content-box-index {
        align-items: center;

        > :first-child {
          width: 19px;
          height: 19px;
          opacity: 0.5;
          background: #FFFFFF;
          font-family: AlibabaPuHuiTi-Bold;
          font-size: 12px;
          font-weight: bold;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0em;
          color: #416FFF;
          border-radius: 50%;
          margin-right: 10px;
        }
      }

      .content-box-img {
        position: absolute;
        width: 289px;
        height: 289px;
        right: -119px;
        top: -19px;
      }
    }
  }

  #shareContent {
    position: relative;

    .arco-spin-children {
      width: 100%;
      height: 100%;
    }

    .ShareError {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .shareErrorTitle {
        margin-top: 20px;
        font-family: AlibabaPuHuiTi-Regular;
        font-size: 22px;
        font-weight: normal;
        line-height: 31px;
        letter-spacing: 0px;
        color: #0C0C0F;
      }

      p {
        font-family: AlibabaPuHuiTi-Regular;
        font-size: 16px;
        font-weight: normal;
        line-height: 32px;
        letter-spacing: 0px;
        color: #4F5969;
        margin-bottom: 50px;
      }
    }

    .product-emotionClass .Paragraph-generate .Continuation-loding {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .product-experience,
    .question,
    .writing,
    .entityExtraction,
    .semanticprototype,
    .wordCloud,
    .image2text,
    .resumeDocument,
    .knowledge,
    .embroidery,
    .product-globalModle,
    .product-emotionClass {
      width: 100%;
      height: 100%;
      opacity: 1;

      .arco-layout-content {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .knowledge {
      .arco-layout-content {
        height: 100%;
        flex-direction: row;
      }

      #result-kg,
      #result {
        height: calc(100% - 70px);
      }
    }

    .resumeDocument {
      #layout_id {
        width: 100%;
        height: calc(100% - 65px);
        margin-top: 0;
      }
    }

    #layout_id {
      width: 100%;
      height: 100%;
      margin-top: 0;
    }

    .question,
    .embroidery {
      #result {
        height: 100%;
        margin: 0;
      }

      #continuation {
        height: calc(100% - 175px);
      }
    }





    .Continuation-Empty {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: (-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .Empty-none {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

  }

  .shareErrorReload {
    width: 132px;
    height: 48px;
    border-radius: 14px;
    background: #E7EEFF !important;
    font-size: 16px;
    font-weight: normal;
    color: #406EFF !important;
    margin-right: 20px !important;

    &:hover {
      background: #dce7ff !important;
    }

    &:active {
      background: #e7edff !important;
    }
  }

  .shareErrorGo {
    width: 184px;
    height: 48px;
    border-radius: 14px;
    background: #406EFF;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    font-family: AlibabaPuHuiTi-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
  }


}