.knowledge {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    margin-top: 14px;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

  }

  .Search-input {
    position: relative;
    width: 100%;
    height: auto;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;

    .arco-input.Search {
      width: 630px;
      height: 45px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-input::-webkit-input-placeholder {
      color: #8d93a1;
      font-size: 14px;
    }

    .Start-generation.arco-btn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      margin-left: 18px;
    }
  }

  .semanticprototype-Layout {
    display: block;
    width: 100%;
    height: 100%;

    .arco-layout-sider-light.input-top {
      width: 100% !important;
      height: auto;
      background: transparent;
      box-shadow: none;

    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 1rem;
      background-color: transparent;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-textarea {
          height: 95px;
          resize: none;
          background: #ffffff;
          border-radius: 1rem;
          color: #0b172a;
          padding: 0.8rem;
        }
      }
    }

    .semanticprototype-title {
      font-size: 16px;
      color: #0a1629;
    }

    .Upload-pictures-bottom {
      position: absolute;
      display: inline-block;
      bottom: 20px;
      right: 20px;
      text-align: right;

      .Start-generation {
        width: 10.3rem;
        height: 4.8rem;
        background: #406eff;
        border-radius: 1.4rem;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }

  .continuation-body {
    position: relative;
    width: 100%;
    margin-top: 17px;
    overflow: auto;

    .lh {
      cursor: pointer;
      color: #406eff;
      text-decoration: none !important;
    }
  }

  .arco-layout-content {
    height: calc(100% - 75px);
    display: flex !important;
    flex-wrap: wrap;

    .experience-result {
      position: relative;
      width: calc(100% - 595px);
      background: #ffffff;
      padding: 0;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      text-align: left;
      border-radius: 22px;

      &.kg-result {
        width: 570px;
        margin-right: 22px;

        position: relative;

        .span {
          font-size: 16px;
          position: absolute;
          font-weight: 400;
          line-height: 20px;
          left: 0;
          top: -30px;
        }

        svg {
          text {
            font-size: 10px;
            pointer-events: none;
          }

          .edgeText {
            font-size: 12px;
          }
        }

        .kg-instruct {
          display: none;
          position: absolute;
          bottom: 31px;
          right: 23px;
          z-index: 2;
          padding: 0;
          border: 0.5px solid #cdcdcd;
          border-radius: 4px;
          backdrop-filter: blur(8px);

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 25px;
            cursor: pointer;
            padding: 0 10px;

            transition: background-color 0.5s, opacity 0.5s;

            &:first-of-type {
              border-radius: 4px 4px 0 0;
            }

            &:last-of-type {
              border-radius: 0 0 4px 4px;
            }

            i {
              display: inline-block;
              width: 11px;
              height: 11px;
              border-radius: 50%;
              border: 1px solid transparent;
              margin-right: 8px;
            }

            svg {
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-right: 5px;
              animation: load360 0.8s linear infinite;
            }

            b {
              display: inline-block;
              color: #0c0c0f;
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }

      &.kg-jd-list {
        .span {
          font-size: 16px;
          position: relative;
          font-weight: 400;
          line-height: 20px;
          left: 0;
          top: -30px;
        }

        .result-list {
          overflow-y: auto;
          text-align: left;
          background-color: #fff;

          li {
            list-style: none;
            width: 45.5%;
            height: 40rem;
            min-height: 300px;
            display: inline-block;
            overflow-y: auto;
            padding: 15px;
            margin: 0 2% 34px 3%;
            border: 1px solid transparent;
            border-radius: 4px;
            color: #0c0c0f;
            line-height: 20px;
            font-size: 14px;
            text-align: left;
            background: #fbfbfb;

            &::-webkit-scrollbar {
              width: 5px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-clip: border-box;
              background-color: #dee4ed;
            }

            &:nth-child(n) {
              margin-right: 0;
            }

            &:hover {
              background-color: #f2f7ff;
            }

            h3 {
              display: flex;
              justify-content: space-between;

              .job-name {
                position: relative;
                font-size: 16px;
                padding-right: 26px;

                a {
                  display: inline-block;
                }

                i {
                  position: absolute;
                  right: 0;
                  display: inline-block;
                  background-image: url("../../../asserts/icon_look.svg");
                  background-size: cover;
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                }
              }

              .job-salary {
                font-size: 14px;
                text-align: right;
              }
            }

            h4 {
              display: flex;
              justify-content: space-between;
              line-height: 30px;

              .job-company {}

              .job-city {
                text-align: right;
              }
            }

            h5 {
              font-size: 14px;
            }

            .desc {
              padding: 10px 0;

              a {
                text-decoration: underline;
                margin: 0 2px;
              }
            }

            hr {
              width: 95%;
              height: 1px;
              background-color: #c9c9c9;
              border: none;
              margin: 10px auto;
            }

            .more {
              display: none;

              .tags-a {
                margin: 1rem auto;

                a {
                  display: inline-block;
                  border: 1px solid #0c0c0f;
                  background-color: #fff;
                  border-radius: 4px;
                  line-height: 30px;
                  padding: 0 10px;
                  text-align: center;
                  min-width: 62px;
                  margin-right: 5px;
                  margin-bottom: 5px;
                }
              }
            }

            &.active {
              border-color: #406eff;
              background-color: #f2f7ff;

              h3 .job-name i {
                display: none;
              }
            }
          }
        }
      }

      .arco-result-icon-tip {
        width: 90px;
        height: 90px;
        font-size: 30px;
        line-height: 95px;
      }

      .result_title {
        background-color: transparent;
        margin: 15px 0 0 21px;
        padding-right: 19px;
      }

      .arco-result .arco-result-title {
        margin-bottom: 10px;
      }

      .Empty-error.arco-empty .arco-empty-wrapper img {
        height: 113px;
      }

      .Empty-none.arco-empty .arco-empty-wrapper img {
        height: 113px;
      }
    }
  }

  .Continuation-Empty,
  .Continuation-loding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes load360 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.more-info {
  position: absolute;
  height: auto;
  padding: 2px 5px;
  font-size: 1.4rem;
  text-align: center;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  background-color: #eff3ff;
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}