.overview {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  padding: 0rem 1.6rem;
  text-align: left;
  overflow: auto;
  color: #1D2129;
  margin-bottom: 4rem;
  .hello-title {
    position: absolute;
    top: 54px;
    h4.arco-typography {
      margin-top: 0;
    }
    .hello-title-day{
      color: #406EFF;
      margin: 0 4px;
    }
    .weixiaoicon {
      display: block;
      width: 26px;
      height: 26px;
      background-image: url('/asserts/hello_icon.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .overview-modular{
    position: relative;
    width: 100%;
    margin-top: 55px;
    .overview-modular-left{
      width: 100%;
      min-width: 900px;
      overflow: hidden;
      .arco-carousel{
        // cursor: pointer;
        img{
          width: auto;
          height: 100%;
        }
      }
      .overview-modular-list{
        margin-top: 20px;
        padding: 20px;
        padding-right: 13px;
        border-radius: 22px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        box-sizing: border-box;
        .modular-headline{
          font-size: 16px;
          margin-bottom: 20px;
          .hed-icon{
            margin-right: 11px;
          }
        }
        .modular-title{
          font-size: 14px;
          .modular-title-more{
            margin-left: 17px;
            .arco-icon{
              margin-left: 8px;
            }
          }
        }
        .swiper-list {
          position: relative;
          display: inline-flex;
          flex-wrap: wrap;
          width: 100%;

          .swiper-wrap {
            position: relative;
            width: 100%;
            white-space: nowrap;
            align-items: center;
            padding-top: 13px;
            margin-bottom: 25px;
            overflow: auto;
            .applistAvatar {
              position: absolute;
              right: 10px;
              top: 12px;
              width: 24px;
              height: 24px;
              border: 1px solid #ffffff;
              border-radius: 50%;
              overflow: hidden;
            }
            .apply-content{
              width: 100%;
              height: 100%;
              padding: 0 13px;
              font-size: 14px;
              box-sizing: border-box;
              
              .apply-img{
                width: 50px;
                height: 50px;
                border-radius: 12px;
                background: #FFFFFF;
                margin-right: 13px;
                img{
                  width: 42px;
                }
              }
              .label-name{
                color: #1D2129;
              }
              .label-modelname{
                color: #4E5969;
              }
            }
            .ApplicationList-item.grid-item {
              margin-left: 17px;
              position: relative;
              width: 272px;
              height: 94px;
              border-radius: 16px;
              box-sizing: border-box;
              text-align: left;
              border: 1px solid #F0EBE8;
              white-space: nowrap;
              display: inline-block;
              margin-bottom: 0;
              transition: all 0.4s ease-out;
              cursor: pointer;
              overflow: hidden;

              &:hover {
                transform: translateY(-5px);
                transition: all 0.4s ease-out;
              }
              .arco-icon-hover {
                display: none;
              }
  
              .arco-checkbox {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
  
                .arco-checkbox-text {
                  height: 100%;
                  margin: 0;
                }
  
                .arco-checkbox-mask {
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  border: 2px solid #406eff;
                }
  
                .arco-checkbox-mask-wrapper {
                  position: absolute;
                  top: 6px;
                  left: 6px;
                }
              }
              .arco-checkbox-checked .arco-checkbox-mask-icon {
                transform: scale(1.3)
              }
              .arco-checkbox-checked .arco-checkbox-mask {
                background-color: #406eff;
              }
              .arco-checkbox-disabled .arco-checkbox-mask {
                border-color: transparent;
              }
              .arco-checkbox-text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
              }
            }
            .ApplicationList-item.createType-item{
              width: 32%;
              height: 700px;
              .apply-content{
                justify-content: flex-start;
                padding: 0 0 0 13px;
                .model-content{
                  height: 150px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 20px;
                  width: calc(100% - 11rem);
                  .label-name{
                    font-size: 16px;
                  }
                  .model-describe{
                    max-width: 80%;
                  }
                }
              }
              .model_img {
                top: 25px;
                right: 15px;
                width: 10rem;
                height: 10rem;
              }
              .ellipasedom.model-describe{
                max-width: 204px;
                white-space: pre-wrap;
              }
              .createList-item{
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                height: 520px;
                // padding-right: 0;
                padding-bottom: 25px;
                padding-right: 10px;
                overflow-y: auto;
                .create-list-item{
                  // height: 78px;
                  border-radius: 10px;
                  border: 1px solid #F0EBE8;
                  .arco-checkbox{
                    height: 78px;
                  }
                  .apply-content {
                    .apply-img{
                      width: 42px;
                      height: 42px;
                      border-radius: 6px;
                      border: 1px solid #F0EBE8;
                      img{
                        width: 32px;
                      }
                    }
                    .label-modelname{
                      margin-top: 2px;
                      white-space: break-spaces;
                    }
                    .label-icon-right{
                      flex: 1;
                      padding-right: 15px;
                      text-align: right;
                    }
                    /* 遮罩层样式 */
                    .mask {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      border-radius: 10px;
                      background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
                      display: flex;
                      justify-content: flex-end; /* 水平右对齐 */
                      align-items: center; /* 垂直居中 */
                      opacity: 0; /* 初始时不可见 */
                      transition: opacity 0.3s ease; /* 平滑过渡效果 */
                    }

                    /* 鼠标悬浮时显示遮罩层 */
                    .apply-content:hover .mask {
                      opacity: 1; /* 显示遮罩层 */
                    }

                    /* 创建应用按钮样式 */
                    .create-app-btn {
                      border-radius: 14px;
                      padding: 14px 16px;
                      background-color: #406EFF; /* 绿色背景 */
                      color: white; /* 白色文字 */
                      border: none;
                      cursor: pointer;
                      font-size: 16px;
                      margin-right: 14px;
                    }
                  }
                  .apply-content:hover .mask{
                    opacity: 1;
                  }
                }
                &::-webkit-scrollbar{
                  width: 13px;
                }
              }
            }
            .ApplicationList-item.grid-item:first-of-type{
              margin-left: 0;
            }
            .ApplicationList-item:hover {
              box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
              transform: translateY(-5px);
              transition: all 0.4s ease-out;
              transform: translateZ(0)
            }
            .model-describe{
              color: #8D93A1;
              font-size: 12px;
            }
            .ellipasedom{
              min-width: 50px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
          }
        }
        .circleloding.arco-btn-size-default.arco-btn-shape-circle {
          position: absolute;
          right: -11px;
          top: 45px;
          width: 30px;
          height: 30px;
          background: rgba(255, 255, 255, 0.72);
          box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
          border-radius: 50% !important;
    
          &:hover {
            color: #406EFF;
          }
    
          &:active {
            color: #0e42d2;
          }
        }
        .circleloding.left {
          left: -20px;
        }
        // 最近模型
        .swiper-model{
          .ApplicationList-item.grid-item{
            background: linear-gradient(278deg, #EEF1F7 1%, #FFFFFF 59%, #F0F5FA 97%);
          }
          .model-content{
            width: 100%;
            .arco-icon{
              font-size: 19px;
            }
          }
          .model-progress{
            margin-top: 11px;
          }
          .circleloding.arco-btn-size-default.arco-btn-shape-circle {
            background: #FFFFFF;
          }
        }
        //创作模型
        .creative-model{
          .dispose_icon {
            display: block;
            width: 18px;
            height: 18px;
            background-image: url('/asserts/dispose_icon.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            margin-right: 11px;
          }
          .model-describe{
            margin-top: 9px;
          }
          .model_img{
            position: absolute;
            top: 0;
            right: -38px;
            width: 144px;
          }
          .ellipasedom{
            max-width: 128px;
          }
          
          .circleloding.arco-btn-size-default.arco-btn-shape-circle {
            background: #FFFFFF;
          }
        }
        // 创作应用
        .official-model{
          .officia-content{
              width: 100%;
              height: 100%;
            .model-content{
              width: 100%;
              height: 100%;
              .apply-header{
                height: 103px;
                color: #ffffff;
                font-size: 12px;
                padding:12px;
                line-height: 22px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                .label-name{
                  font-size: 14px;
                  margin-top: 18px;
                }
              }
            }
          }
          
  
          .apply-desc{
            width: 100%;
            height: 54.17px;
            padding: 0 12px;
            box-sizing: border-box;
          }
          .ellipaseline{
            max-width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        
          .circleloding.arco-btn-size-default.arco-btn-shape-circle {
            background: #FFFFFF;
          }
        }
        .GroupTable {
          width: auto;
          height: 45px;
          background: #f2f6ff;
          border-radius: 24px;
          overflow: hidden;
    
          .arco-radio-button {
            width: 111px;
            height: 38px;
            line-height: 38px;
            color: #1d2129;
            font-size: 14px;
            text-align: center;
          }
    
          .arco-radio-button:hover {
            border-radius: 24px;
          }
    
          .arco-radio-button::after {
            background: #f2f6ff;
          }
    
          .arco-radio-button:not(:first-of-type)::before {
            background: transparent;
            color: transparent;
          }
    
          .arco-radio-button.arco-radio-checked {
            width: 111px;
            height: 38px;
            background: #406eff;
            border-radius: 20px;
            color: #ffffff;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
          }
        }
        .DatePicker-RangePicker {
          position: relative;
          .RangePicker-box {
            position: absolute;
            width: 560px;
            margin-top: 10px;
            z-index: 99;
      
            .arco-btn {
              border-radius: 4px;
            }
          }
          .custom-White-button{
            height: 40px;
            font-size: 22px;
          }
        }
        .linecharts{
          height: 600px;
          .arco-spin{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 500px;
          }
        }
        .call-select{
          .arco-select-view{
            width: 206px;
            height: 38px;
            border-radius: 14px;
            line-height: 36px;
            margin-left: auto;
            // background: #ffffff;
            border-color: #d8e0f0;
          }
        }
        .overview-speace .arco-space-item:nth-child(2){
          flex: 1;
        }
      }
    }
    .overview-modular-right{
      width: 466px;
      margin-left: 20px;
      user-select: none;
      .cardmodular-right{
        position: relative;
        width: 100%;
        height: auto;
        min-height: 340px;
        border-radius: 20px;
        padding: 25px 14px 14px;
        padding-bottom: 16px;
        background-color: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        background-image: url('/asserts/personage.png');
        background-repeat: no-repeat;
        .modular-head-box{
          position: relative;
          margin-left: 19px;
          .identity-level{
            position: absolute;
            right: 10px;
            top: -12px;
            font-size: 32px;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: 0px;
            background-image: -webkit-linear-gradient(top,#ffffff,#FFF3D4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 4px 4px 0px #A76A00;
            .identity-level-bg{
              width: 105px;
              height: 83.12px;
              background-repeat: no-repeat;
              background-size: 100%;
              background-position-y: bottom;
            }
            .svip1_icon{
              background-image: url('/asserts/svip1_icon.svg');
            }
            .svip2_icon{
              background-image: url('/asserts/svip2_icon.svg');
            }
            .svip3_icon{
              background-image: url('/asserts/svip3_icon.svg');
            }
            .svip1_bule_icon{
              background-image: url('/asserts/svip1_bule_icon.svg');
            }
            .svip2_bule_icon{
              background-image: url('/asserts/svip2_bule_icon.svg');
            }
            .svip3_bule_icon{
              background-image: url('/asserts/svip3_bule_icon.svg');
            }
          }
        }
        h4.arco-typography {
          margin-top: 0;
          margin-bottom: 0;
          color: #ffffff;
          margin-right: 15px;
        }
        .modular-head{
          margin-top: 16px;
        }
        .user-info-content{
          display: flex;
          .user-info-content-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            .user-info-content-right-name{
              font-size: 20px;
              font-weight: bold;
              line-height: 22px;
              letter-spacing: 0px;
              color: #FFFFFF;
            }
            .user-info-content-right-time{
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0px;
              color: #FFFFFF;
            }
          }
        }
        .avatar-quantity{
          width: 43px;
          height: 43px;
          border: 2px solid #F0EBE8;
          background: #FFFFFF;
          font-size: 16px;
          line-height: 40px;
          color: #406EFE;
          border-radius: 50%;
          text-align: center;
          z-index: 2;
          transform: translateX(-7px);
        }
        .modular-desc{
          color: #897160;
          margin-top: 13px;
        }
        .modular-renew{
          padding: 0 9px;
          height: 22px;
          border-radius: 4px;
          background: #FFFFFF;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0px;
          box-sizing: border-box;
          margin: 0 8px;
          cursor: pointer;
        }
        .modular-upgrade{
          padding: 0 9px;
          height: 22px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0px;
          box-sizing: border-box;
          cursor: pointer;
        }
        .modular-renew{
          background: #FFFFFF;
          color: #3B80FF;
          border: 1px solid #3B80FF;
          &:hover{
            background: #EEF3FF;
          }
          &:active{
            background: #E6ECFA;
          }
        }
        .modular-upgrade{
          background: linear-gradient(270deg, #367BFE 0%, #7CAAFE 100%);
          color: #FFFFFF;
          &:hover{
            background: linear-gradient(270deg, #679BFF 0%, #A7C7FF 100%);
          }
          &:active{
            background: linear-gradient(270deg, #225FD4 4%, #4D86EE 99%);
          }
        }
        .splitlist{
          width: 95%;
          padding: 12px 18px;
          border-radius: 14px;
          margin: 25px auto 0;
          color: #4E5969;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #F0EBE8;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
          .splititem{
            color: #4E5969;
          }
        }
        .splititemicon{
          border-color: #908877;
        }
        .view-permissions{
          position: absolute;
          right: 29px;
          bottom: 25px;
          color: #4270FF;
          font-size: 14px;
          cursor: pointer;
          .arco-icon{
            margin-left: 10px;
          }
          &:hover{
            text-decoration: underline;
          }
          &:active{
            opacity: 0.9;
          }
        }
        .skew-golden {
          position: absolute;
          left: 29px;
          bottom: 15px;
          width: 89%;
          height: 48px;
          border-radius: 14px;
          color: #472B00;
          font-size: 16px;
          background: linear-gradient(90deg, #FEE9B7 0%, #FAD889 100%);
          cursor: pointer;
          i{
          display: block;
          width: 18.53px;
          height: 15px;
          background-image: url('/asserts/golden_vips_icon.svg');
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 5px;
          }
          &:hover{
            background: linear-gradient(84deg, #FFF2D3 1%, #FED987 102%);
          }
          &:active{
            background: linear-gradient(264deg, #F0C25C -2%, #FEEBBC 98%);
          }
        }
         // 进度条
        .svg-module-list{
          margin-top: 20px;
          margin-bottom: 15px;
        }
        .user-info-content .arco-avatar-image{
          border: 3px solid #ffffff;
        }
        .module-item{
          display: flex;
          width: 45%;
          border-radius: 20px;
          padding: 22px 0;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #F0EBE8;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
          justify-content: space-evenly;
        }
        .modul-left{
          width: 60px;
          height: 60px;
          background: #EBF4FE;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .modul-num{
          font-size: 24px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0px;
          color: #002E7F;
        }
        .modul-name {
          font-family: Alibaba PuHuiTi 2.0;
          font-size: 16px;
          font-weight: normal;
          line-height: 17px;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          color: #472B00;
        }
        .modul-left_api{
          background-image: url('/asserts/api_modul.png');
        }
        .modul-left_app{
          background-image: url('/asserts/app_modul.png');
        }
        .modul-right{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
      .charts-title{
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #34302D;
        margin-bottom: 29px;
      }
      .Regular-title{
        margin-bottom: 12px;
      }
      .charts-body{
        margin-top: 10px;
        .expense-padding{
          padding: 4px 4px 0 4px;
          border-radius: 6px;
          border: 1px solid #F0EBE8;
          margin-right: 12px;
        }
        .expense-row{
          display: flex;
          flex-direction: column;
          .expense_num{
            font-weight: 700;
            font-size: 18px;
            color: #406EFF;
            span{
              font-size: 16px;
            }
          }
          .expense_text{
            color: #4E5969;
            font-size: 12px;
            font-weight: normal;
          }
        }
      } 
      .charts-body-btn:hover{
        color: #fff;
        background-color: #406EFF;
      }
      .first-row{
        padding-bottom: 10px;
        border-bottom: 1px solid #E6EBF5;
      }
      .bartable_top_img{
        height: 18px;
        margin-left: 15px;
      }
      .BarTable{
        width: 95%;
        .BarTable-Title{
          display: flex;
          justify-content: space-around;
          font-size: 14px;
          .AlibabaPuHuiTi-Medium{
            width: 25%;
            text-align: center;
            
          }
        }
        .BarTable-Content{
          .BarTable-Content-item{
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            // height: 40px;
            margin-top: 5px;
            margin-bottom: 5px;
            padding-bottom: 5px;
            padding-top: 5px;
            border-bottom: 1px dashed #E6EBF5;
            color: #0B172A;
            font-size: 14px;
            .AlibabaPuHuiTi-Medium{
              width: 25%;
              text-align: center;
            }
            // .item-top-left{
            //   position: absolute;
            //   top: 0;
            //   left: -5px;
            // }
            .item-top-right{
              position: absolute;
              top: 0;
              right: -25px;
            }
          }
          .BarTable-Content-item:last-child{
            border-bottom: none;
          }
        }
      }
      .cardpaddingR{
        padding: 15px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        box-sizing: border-box;
      }
      .overview-CardR {
        width: 466px;
        margin-top: 20px;
        .BarEcharts {
          width: 100%;
          height: 250px;
        }
        .Statistic-Space{
          padding: 11px;
          font-size: 12px;
          color: #4E5969;
          border-radius: 14px;
          background: #F8FAFC;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
          .statistic-space-title{
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 5px;
          }
          .statistic-space-lable{
            img{margin-right: 4px;}
          }
        }
      }
      .overview-CardR.overview-CardR-top{
        height: 260px;
        overflow: auto;
      }
      .card-engine{
        .card-engine-li{
          width: 31%;
          height: 42px;
          color: #8D93A1;
          font-size: 14px;
          margin:0 4px;
          margin-top: 26px;
          transition: all 0.4s ease-out;
          overflow: hidden;
          .engine-li-img{
            width: 42px;
            height: 42px;
            border-radius: 6px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #F0EBE8;
            margin-right: 8px;
            .li-img-grey{
              opacity: 40%;
              width:32px
            }
            .li-img-black{
              width:32px
            }
          }
          .engine-li-title-black{
            margin-bottom: 4px;
            color: #1D2129;
          }
          .engine-li-title-grey{
            margin-bottom: 4px;
            color: #8D93A1;
          }
          .engine-li-text-grey{
            color: #D0D3D6;
          }

          &:hover {
            cursor: pointer;
            transform: translateY(-5px);
            transition: all 0.4s ease-out;
          }
        }
      }
    }
  }
  .Swiper-init {
    position: relative;
    width: 100%;

    .circleloding {
      position: absolute;
      top: 40% !important;
      transform: translateY(-40%);
    }

    .circleloding.Right {
      right: 0rem;
    }

    .circleloding.Left {
      left: -2rem;
    }
  }


  .arco-table-no-data {
    padding-bottom: 86px !important;
    padding-top: 86px !important;
  }



  .overview-Cardleft {
    // width: 85.4rem;
    height: 300px;
    overflow: hidden;

    h6.arco-typography {
      font-size: 22px;
    }

    .vip_icon {
      display: inline-block;
      width: 24px;
      height: 19px;
      background-image: url('/asserts/golden_vip_icon2.svg');
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 9px;
    }
  }

  .overview-Cardleft.Cardleft-vip {
    background: linear-gradient(165deg, #695947 3%, #2e2119 87%);
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    color: #ffffff;

    h6.arco-typography {
      color: #ffffff;
    }

    .arco-typography {
      color: rgba(255, 255, 255, 0.58);
    }

    .arco-divider-vertical {
      border-left: 1px solid #7a7a7a;
    }

    .arco-statistic-value-int {
      color: #e9c277;
    }

    .appimgleft-vip {
      position: absolute;
      right: 0;
      top: 0;
      width: 378px;
      height: 100%;
      background-image: url('/asserts/appimgleft-vip.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 30px;
      background-position-y: -30px;
    }

    .hex {
      margin: 0.5em auto;
      width: 16vw;
      height: 16vw;
      opacity: 0.14;
    }

    /* ===== 方法 1 ===== */
    /* 可工作在所有支持 2D transforms的浏览器上 */

    .hex {
      position: absolute;
      left: -17%;
      bottom: -20%;
      overflow: hidden;
      -webkit-transform: rotate(-30deg) skewX(30deg) scaleY(0.86603);
      transform: rotate(-30deg) skewX(30deg) scaleY(0.86603);
    }

    .hex.top {
      top: -50%;
      left: 30%;
    }

    .hex:after {
      position: absolute;
      left: calc(50% - 6.9282vw);
      /* undo transforms in reverse order */

      width: 13.85641vw;
      height: 16vw;
      -webkit-transform: scaleY(1.1547) skewX(-30deg) rotate(30deg);
      transform: scaleY(1.1547) skewX(-30deg) rotate(30deg);
      background: -webkit-linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
      background: linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
      content: "";
    }

    .hex.top:after {
      position: absolute;
      left: calc(50% - 6.9282vw);
      /* undo transforms in reverse order */

      width: 13.85641vw;
      height: 16vw;
      -webkit-transform: scaleY(1.1547) skewX(-30deg) rotate(30deg);
      transform: scaleY(1.1547) skewX(-30deg) rotate(30deg);
      background: -webkit-linear-gradient(325deg, rgba(253, 248, 197, 0.00) 20%, #fbda6c 72%);
      background: linear-gradient(325deg, rgba(253, 248, 197, 0.00) 20%, #fbda6c 72%);
      content: "";
    }

    // /* ===== 方法 2 ===== */
    // /* 使用svg多边形，支持所有浏览器 */

    // .hex:nth-of-type(2) svg {
    //   width: 100%;
    //   height: 100%;
    // }

    // .hex:nth-of-type(2) use {
    //   fill: url(#bg);
    // }

    // /* ===== 方法 3 ===== */
    // /* 可工作在所有支持clip-path方法的浏览器中 */
    // /* IE/Edge不支持*/

    // .hex:nth-of-type(3) {
    //   background: -webkit-linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
    //   background: linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
    //   -webkit-clip-path: url(#cp);
    //   clip-path: url(#cp);
    // }

    // /* ===== 方法 4 ===== */
    // /* 可工作在支持clip-path基本形状的浏览器中 */
    // /* 目前只有基于WebKit的浏览器支持 */

    // .hex:nth-of-type(4) {
    //   background: -webkit-linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
    //   background: linear-gradient(222deg, rgba(253, 248, 197, 0.00) 14%, #fbda6c 79%);
    //   -webkit-clip-path: polygon(50% 0%, 93.30127% 25%, 93.30127% 75%, 50% 100%, 6.69873% 75%, 6.69873% 25%);
    //   clip-path: polygon(50% 0%, 93.30127% 25%, 93.30127% 75%, 50% 100%, 6.69873% 75%, 6.69873% 25%);
    // }
  }



  .titleline {
    border-left: 0.5rem solid #406EFF !important;
    margin-left: 0 !important;
  }

  .arco-table-body {
    min-height: 20rem !important;
  }

  .arco-progress-line-wrapper,
  .arco-progress-steps-wrapper {
    // flex-direction: column-reverse;
  }

  .arco-progress-large .arco-progress-line-text {
    margin-left: 0;
    margin-bottom: 0.5rem;
    color: #406EFF;
  }



  .cardpadding .arco-card-body {
    padding-left: 3.2rem;
  }
  .appimgleft {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 35.2rem;
    height: 21.7rem;
    background-image: url('/asserts/appimg1.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .splititem {
    position: relative;
    text-align: center;

    .arco-statistic-value-int {
      color: #406eff;
    }
  }

  .splititem:hover::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 10px;

    cursor: pointer;
  }
  .heat_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('/asserts/heat_bule_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
  }
  .expense_icon{
    display: inline-block;
    width: 23px;
    height: 23px;
    background-image: url('/asserts/expense.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 6px;
  }
  .resource_icon{
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url('/asserts/resource.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 6px;
  }
  .expense1_icon{
    display: inline-block;
    width: 36px;
    height: 36px;
    background-image: url('/asserts/express1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
  }
  .expense2_icon{
    display: inline-block;
    width: 36px;
    height: 36px;
    background-image: url('/asserts/express2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
  }
  .resource1_icon{
    display: inline-block;
    width: 36px;
    height: 36px;
    background-image: url('/asserts/resource1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
  }
  .resource2_icon{
    display: inline-block;
    width: 36px;
    height: 36px;
    background-image: url('/asserts/resource2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
  }
  .resource3_icon{
    display: inline-block;
    width: 36px;
    height: 36px;
    background-image: url('/asserts/document_img.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
  }
  .heat_icon2 {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('/asserts/heat_bule_icon2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
  }
  .heat_top_icon1{
    display: inline-block;
    width:26px;
    height: 33px;
    background-image: url('/asserts/top1.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .heat_top_icon2{
    display: inline-block;
    width: 26px;
    height: 33px;
    background-image: url('/asserts/top2.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .heat_top_icon3{
    display: inline-block;
    width: 26px;
    height: 33px;
    background-image: url('/asserts/top3.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .authority {
    padding: 8px 12px;
    background: linear-gradient(90deg, #7fafff, #2f76ff 73%);
    border-radius: 15px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background: linear-gradient(90deg, #609cff 73%, #1665ff);
    }

    &:active {
      background: linear-gradient(270deg, #4d89ff 84%, #1f73ff);
    }

    .arco-icon {
      color: #ffc231;
      margin-left: 2px;
      font-size: 14px;
    }
  }

 

  .Recommended-services {
    width: 100%;
    overflow: scroll;
  }

  .Recommended-services.arco-row {
    flex-flow: nowrap;
  }

  .Recommended-services .arco-card {
    border-radius: 2.2rem;
    box-shadow: 0rem 0.6rem 5.8rem 0rem rgba(196, 203, 214, 0.10);

    .services-header {
      width: 100%;
      // min-height: 244px;
      padding-bottom: 10px;
      background: #f7fcff;
      border-radius: 22px;
      text-align: center;
      margin-bottom: 3px;
      padding-bottom: 3px;
    }

    .arco-card-body {
      padding: 0;
    }
  }

  .custom-radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .custom-radio-group .arco-radio {
    margin-right: 0;
    margin-top: 25px;
    margin-bottom: 0;
  }

  .custom-radio-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 44px;
    border: 1px solid #e7ebf5;
    border-radius: 14px;
    color: #20242F;
    text-align: center;
    box-sizing: border-box;
  }

  .arco-radio-text {
    margin-left: 0;
  }

  .arco-radio-mask-wrapper {
    display: none;
  }

  .custom-radio-card .arco-icon {
    margin-right: 1rem;
  }

  .custom-radio-card-title {
    color: #20242F;
    font-size: 16px;
  }

  .custom-radio-card:hover,
  .custom-radio-card-checked,
  .custom-radio-card:hover .custom-radio-card-mask,
  .custom-radio-card-checked .custom-radio-card-mask {
    border-color: #4080ff;
    background-color: #4080ff;
  }

  .custom-radio-card:hover .custom-radio-card-title,
  .custom-radio-card-checked .custom-radio-card-title {
    color: #ffffff;
    font-size: 16px;
  }

  .arco-radio-disabled .custom-radio-card {
    pointer-events: none;
    background: #fafafa;
    border-color: #e5e6eb;
    color: #a5a5a5;
  }

  .arco-radio-disabled .custom-radio-card-title {
    color: #a5a5a5;
  }

  .tableCard {
    border-radius: 22px;
    box-shadow: 0rem 0.6rem 5.8rem 0rem rgba(196, 203, 214, 0.10);

    .arco-card-body {
      padding-top: 0px;
    }

    .arco-spin {
      padding: 12px !important;
    }
  }

  .outlineTable {
    width: 87px;
    height: 32px;
    border-radius: 8px !important;
  }

  .arco-table-header {
    overflow-y: hidden !important;
  }

  // 体验应用
  .OmdelcationList-wrap {
    .arco-list-item {
      padding: 0 !important;
    }

    .ApplicationList-item.grid-item {
      display: block !important;
      margin: 0 14px;
      position: relative;
      // width: 370px;
      height: 284px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
      padding: 14px 30px;
      box-sizing: border-box;
      text-align: left;
      padding: 16px;
      transition: all 0.4s ease-out;
      margin-bottom: 27px;
      cursor: pointer;

      .divider-name {
        border-left: 5px solid #9D9D9D;
        border-radius: 2px;
        margin-left: 0px;
        margin-right: 12px;

      }

      .Typography-desc {
        font-size: 12px;
        padding-left: 17px;
      }

      h6.arco-typography {
        font-size: 14px;
      }

      .arco-typography {
        margin-bottom: 0;
        margin-top: 0px;
      }

      .arco-tag-icon {
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }

      .arco-input {
        height: 30px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        color: #ffffff;

        &:focus {
          border-color: #ffffff;
        }
      }

      .editable-Paragraph-Input.arco-textarea {
        resize: none;
        font-size: 12px;
        height: 42px;
        border-radius: 8px;
        color: #9d9d9d;

        &:focus {
          border-color: #e6ebf5;
        }
      }

      .arco-icon-hover {
        display: none;
      }

      .ischeckbox {
        .arco-icon-hover {
          display: block;
        }
      }

      .arco-checkbox {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        .arco-checkbox-text {
          height: 100%;
          margin: 0;
        }

        .arco-checkbox-mask {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 2px solid #406eff;
        }

        .arco-checkbox-mask-wrapper {
          position: absolute;
          top: 6px;
          left: 6px;
        }
      }

      .arco-checkbox-checked .arco-checkbox-mask-icon {
        transform: scale(1.3)
      }

      .arco-checkbox-checked .arco-checkbox-mask {
        background-color: #406eff;
      }

      .arco-checkbox-disabled .arco-checkbox-mask {
        border-color: transparent;
      }


      .ApplicationList-item:hover {
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
        transform: translateY(-5px);
        transition: all 0.4s ease-out;
      }

      .arco-checkbox-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .grid-header {
        width: 100%;
      }

      .grid-top {
        width: 100%;
        height: 131px;
        border-radius: 14px;
        padding: 15px;
        margin-bottom: 13px;
        background-repeat: no-repeat;
        background-size: auto 100%;
        box-sizing: border-box;
      }

      .grid-bottom {
        width: 100%;

        .arco-divider-horizontal {
          margin: 15px 0;
        }

        .open-now {
          width: 98px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #406eff;
          border-radius: 7px;
          font-size: 12px;

          .arco-icon {
            display: none;
            margin-left: 5px;
          }

          &:hover {
            background: #406eff;
            border: 1px solid #406eff;
            color: #ffffff;

            .arco-icon {
              display: inline-block;
            }
          }

          &:disabled {
            background: #fafafa;
            color: #aeaeae;
            border: 1px solid #fafafa;
          }

        }
      }

      .state-icon {
        text-align: right;
        font-size: 12px;

        .arco-tag {
          border-radius: 14px;
        }
      }

      .grid-top-title {
        width: 100%;
        margin-bottom: 18px;
        color: #ffffff;
        font-size: 14px;

        h5.arco-typography {
          font-size: 22px;
          margin-top: 14px;
          color: #ffffff
        }

        .arco-typography {
          color: #ffffff
        }

        img {
          width: 46px;
        }

        .arco-typography-operation-edit {
          font-size: 16px;
          color: #ffffff;

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
          }
        }

        .arco-space-item:last-of-type {
          width: 100%;

          a {
            display: none;
          }
        }
      }

      .swiper-Title {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 22px;
        color: #ffffff;
      }

      .group-label {
        display: block;
        font-size: 14px;
        color: #ffffff;
      }

      .swiper-fix {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #ffffff;

        .delcircle {
          width: 28px;
          height: 28px;
          background: rgba(0, 0, 0, 0.50);
          color: #ffffff;
        }

        .arco-tag {
          height: 28px;
        }
      }

      .swiper-fix>span:last-of-type {
        font-size: 12px;
        color: #ffffff;

        .arco-icon.IconClockCircle {
          margin-right: 6px;
        }
      }

      .ellipasedom {
        width: 180px;
      }

      .editable-Paragraph {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 12px;
        color: #9d9d9d;

        .arco-typography-operation-edit {
          color: #9d9d9d;
        }

        a {
          display: none;
        }
      }
    }

    .ApplicationList-item:hover {
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30);
      transform: translateY(-5px);
      transition: all 0.4s ease-out;
    }
  }
}

.vip_level1{
  .overview .overview-modular .cardmodular-right{
  min-height: 340px;
  padding-bottom: 25px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.3);
  // background-image: linear-gradient(124deg, #FBFDFF 6%, #EDF6FF 51%, #D1E3FF 100%);
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  overflow: hidden;
  // .cardmodular-backimg{
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left:0;
  //   background-image: url('/asserts/personage.png');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: right;

  // }
  .modular-desc{
    color: #9D9D9D;
  }
  h4.arco-typography{
    color: #ffffff;
  }
  .svg-module .svg-module-title{
    color: #0A1629;
  }
  .progress-desc{
    color: #0A1629;
  }
  .view-permissions{
    color: #4270FF;
  }
  .modular-renew{
    display: none;
  }
  .modular-upgrade{
    margin-left: 8px;
  }
 }
 .overview .overview-modular .cardmodular-right.cardmodular-right-hasenterprise{
  min-height: 280px;
}
}
.vip_level2{
  .overview .overview-modular .cardmodular-right{
    min-height: 340px;
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .modular-desc{
    color: #405A7F;
  }
  h4.arco-typography{
    color:  #ffffff;
  }
  .svg-module .svg-module-title{
    color:  #002E7F;
  }
  .progress-desc{
    color:  #002E7F;
  }
  .view-permissions{
    color: #4270FF;
  }
 }
 .overview .overview-modular .cardmodular-right.cardmodular-right-hasenterprise{
  min-height: 280px;
}
}
.vip_level3{
  .overview .overview-modular .cardmodular-right{
    min-height: 340px;
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .modular-desc{
    color: #405A7F;
  }
  h4.arco-typography{
    color: #ffffff;
  }
  .splitlist{
    background: #FFFFFF;
  }
  .svg-module .svg-module-title{
    color: #002E7F;
  }
  .progress-desc{
    color: #002E7F;
  }
  .modular-upgrade{
    display: none;
    color: #4270FF;
  }
 }
 .overview .overview-modular .cardmodular-right.cardmodular-right-hasenterprise{
  min-height: 280px;
}
}
.arco-carousel-item-current{
border-radius: 20px;
background: linear-gradient(90deg, #408CFF 50%, #3B7CFF 50%);
box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
}
.overview .overview-modular .overview-modular-left .arco-carousel img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.identityVip .overview .overview-modular{
  .arco-carousel-item-current{
    background: linear-gradient(90deg, #408CFF 50%, #3B7CFF 50%);
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    }
  .overview-modular-left{
    .overview-modular-list{
      .modular-headline{
        .arco-icon{
          color: #EAB97D;
        }
      }
    }
  }
  .modular-title,.charts-title{
    .modular-title-more .arco-link{
      color: #897160;
    }
  }
  .overview-modular-right .cardmodular-right{
    .modular-renew{
      background: #FFFFFF;
      color: #AD7623;
      border: 1px solid #AD7623;
      &:hover{
        background: #FFEED6;
      }
      &:active{
        background: #F6DAB0;
      }
    }
    .modular-upgrade{
      background: linear-gradient(270deg, #E3BB8A 0%, #F7DCB6 100%);
      color: #472B00;
      &:hover{
        background: linear-gradient(270deg, #EEC491 0%, #F9E0BD 100%);
      }
      &:active{
        background: linear-gradient(270deg, #D9A76B 0%, #E9C490 100%);
      }
    }
    .view-permissions{
      position: absolute;
      right: 17px;
      bottom: 17px;
      color: #897160;
      font-size: 14px;
      cursor: pointer;
    }
  }
  // .heat_icon {
  //   background-image: url('/asserts/heat_icon.svg');
  // }
  .heat_icon2 {
    background-image: url('/asserts/heat_icon2.svg');
  }
   // 进度条
   .svg-module-list{
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.identityVip.vip_level1{
  .overview .overview-modular .cardmodular-right{
    min-height: 280px;
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .modular-desc{
    color: #897160;
  }
  h4.arco-typography{
    color:  #ffffff;
  }
  .splitlist{
    background: #FFFAED;
    color: #897160;
    .splititem{
      color: #34302D;
    }
  }
  .svg-module .svg-module-title{
    color:  #34302D;
  }
  .progress-desc{
    color:  #34302D;
  }
  .view-permissions{
    color: #897160;
  }
  .modular-renew{
    display: none;
  }
  .modular-upgrade{
    margin-left: 8px;
  }
 }
}
.identityVip.vip_level2{
  .overview .overview-modular .cardmodular-right{
    min-height: 280px;
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .modular-desc{
    color: #897160;
  }
  h4.arco-typography{
    color:  #ffffff;
  }
  .splitlist{
    background: #F9E5AE;
  }
  .svg-module .svg-module-title{
    color:  #472B00;
  }
  .progress-desc{
    color:  #472B00;
  }
  .view-permissions{
    color: #897160;
  }
 }
 
}
.identityVip.vip_level3{
  .overview .overview-modular .cardmodular-right{
    min-height: 280px;
  background-image: url('/asserts/personage.png');
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .modular-desc{
    color: #EECDA2;
  }
  h4.arco-typography{
    color: #FFFFFF;
  }
  .splitlist{
    background: #FFFFFF;
  }
  .svg-module .svg-module-title{
    color: #FFE099;
  }
  .progress-desc{
    color: #FFE099;
  }
  .view-permissions{
    display: none;
  }
  .modular-upgrade{
    display: none;
  }
 }
 
}
  // 进度条
  .svg-module {
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    .module-content {
      width: 100%;
      color: #34302D;
      box-sizing: border-box;
    }
    .svg-module-title{
      font-size: 14px;
      color: #472B00;
      margin-top: 10px;
    }
  }
  //创建模态框
  .createListModal{
    .create-desiction{
      font-size: 16px;
      color: #20242F;
    }
    .create-list{
      display:flex;
      justify-content: space-around;
      margin-top: 25px;
      .create-list-item{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #F0EBE8;
        padding: 25px 25px 15px;
        overflow: auto;
        img{
          width:100px;
          height: 100px;
          border-radius: 12px;
        }
        .create-name{
          font-size: 14px;
          color: #1D2129;
          font-weight: 500;
          margin-top:10px;
        }
        &:hover{
          cursor: pointer;
          border-color: #406EFF;
        }
      }
      .create-list-item-active{
        border-color: #406EFF;
      }
      .create-list-item-disable::before{
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        width: 52px;
        height: 52px;
        background-image: url('/asserts/icon-enterprise.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .overview .overview-modular .overview-modular-right .splititem-card{
    color: #4E5969;
    border-radius: 12px;
    background: #ffffff;
    // border: 1px solid rgba(238, 235, 230,1);
}
.overview-modular-list .DatePicker-RangePicker .RangePicker-box{
  right: -50%;
}
  .no-pageBox{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
    span{
      margin-top: 15px;
      font-size: 12px;
      line-height: 14px;
      color: #4E5969;
    }
  }

  @media screen and (max-width: 1450px) {
    .overview .overview-modular{
      .overview-modular-left{
        width: calc((100vw - 256px) * 0.65);
        min-width: calc((100vw - 256px) * 0.65);
      }
      .overview-modular-right{
        width:calc((100vw - 256px) * 0.35);
        .overview-CardR{
          width: auto;
        }
      }
      .overview-modular-left .overview-modular-list .overview-speace .GroupTable .arco-radio-button{ 
        width: 90px;
        font-size: 13px;
      }
    }
    .overview .overview-modular .overview-modular-left .overview-modular-list .swiper-list .swiper-wrap .ApplicationList-item.createType-item .createList-item .create-list-item {
    .arco-checkbox{
      height: 98px;
    }
    .apply-content .apply-img{
      width: 48px;
      height: 58px;
    }
  }

  }

  @media screen and (max-width: 1300px){
    .overview .overview-modular{
      .overview-modular-left .overview-modular-list .overview-speace .GroupTable .arco-radio-button{ 
        width: 70px;
        font-size: 12px;
      }
    }
  }