.privilege {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 36px;
    padding-bottom: 13px;
    color: #0c0c0f;
    text-align: left;
    box-sizing: border-box;
    z-index: 9;

    .privilege-card {
        width: 100%;
        min-height: calc(100% - 64px);
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        margin-top: 20px;
        padding: 32px;

        .arco-col {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;

            .arco-row {
                width: 100%;
                height: 100%;
            }
        }



        .Banner_privilege {
            width: 100%;
            height: 153px;
            font-size: 14px;
            color: #8c663a;
            background-image: url('/asserts/Banner_privilege.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: center;
            background-color: linear-gradient(115deg, #fffbe7 27%, #f8eabc 85%);
            border-radius: 20px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

            .Banner_privilege_title {
                color: #663804;
                font-size: 28px;
            }
        }

        .privilege-table {
            position: relative;
            width: 100%;
            margin-top: 31px;
            .privilege-table-labe {
                font-size: 20px;
                color: #8d959f;
                margin-top: 27px;
                margin-bottom: 16px;
            }
        }
        .table-td.rowCenter>.table-th{
                border-top: 0;
            }
        
        .privilege-grid {
            width: 100%;
            height: var(--height);
            min-height: 52px;
            margin: 0 !important;
            background: transparent;
            font-size: 16px;
            color: #0c0c0f;
            text-align: center;
            &>div{
                width: var(--width);
                height: 100%;
                text-align: center;
            }
            &.vip-list{
                border-top: 1px solid #E6EBF5;
                box-sizing: border-box;
            }
            .vip-list>div{
                height: 100%;
                border-left: 1px solid #E6EBF5;
                box-sizing: border-box;
                
            }
            
            .personal-vip {
                width: var(--width);
                height: 100%;
                font-size: 16px;
                color: #2a59b3;
                text-align: center;
            
                .bule-tag,.arco-icon {
                    display: none;
                }
                .icon {
                    display: inline-block;
                    width: 23px;
                    height: 19px;
                    background-image: url('/asserts/blue_vip_icon.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-top: 5px;
                    margin-right: 4px;
                }
            }

            .group-vip {
                width: var(--width);
                height: 100%;
                font-size: 16px;
                color: #93651c;
                text-align: center;
                
                .golden-tag,.arco-icon {
                    display: none;
                }

                .icon {
                    display: inline-block;
                    width: 23px;
                    height: 19px;
                    background-image: url('/asserts/golden_vips_icon.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-top: 3px;
                    margin-right: 4px;
                }
            }

            .personal-vip.bule-bg {
                background: rgba(228, 238, 255, 1);
                overflow: hidden;
            }

            .group-vip.golden-bg {
                background: #FFF5CC;
                overflow: hidden;
            }
            .bule-bg.vip-level{
                
                padding: 4px;
                box-sizing: border-box;
                .vip-btn{
                    width: 100%;
                    height: 100%;
                    background: rgba(220, 233, 255, 0.4);
                    border-radius: 5px;
                    div{
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            top: 8px;
                            right: -15px;
                            width: 10px;
                            height: 8px;
                            background-image: url('/asserts/bule_tagvips_icon.svg');
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
            .golden-bg.vip-level{
                padding: 4px;
                box-sizing: border-box;
                .vip-btn{
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 243, 195, 0.4);
                    border-radius: 5px;
                    div{
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            top: 8px;
                            right: -15px;
                            width: 10px;
                            height: 8px;
                            background-image: url('/asserts/golden_tagvips_icon.svg');
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
            .buy-text{
                width: 77px;
            }
            .buy-btn{
                display: none;
            }
            .golden-buy{
                .buy-btn{
                    display: block;
                    width: 50px;
                    padding: 5px 0;
                    border-radius: 7px;
                    margin-left: 11px;
                    cursor: pointer;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #9E7201;
                    // background: linear-gradient(76deg, #FEEBBC 7%, #FEEBBC 7%, #F6C75F 100%, #F6C75F 100%);
                }
            }
            .bule-buy{
                .buy-btn{
                    display: block;
                    width: 50px;
                    padding: 5px 0;
                    border-radius: 7px;
                    margin-left: 11px;
                    cursor: pointer;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    // background: #4889FF;
                }
            }
            .bule-bg {
                position: relative;
                background: rgba(246, 249, 255, 0.57);
                overflow: hidden;

                .bule-tag {
                    position: absolute;
                    left: -34px;
                    top: 8px;
                    display: block;
                    padding: 2px;
                    width: 100px;
                    background: #4889ff;
                    color: #ffffff;
                    font-size: 12px;
                    transform: rotate(-45deg);
                }
                .arco-icon{
                    display: block;
                }
                // .buy-btn{
                //     display: none;
                // }
            }
           
            .golden-bg {
                position: relative;
                background: rgba(255, 252, 239, 0.57);
                overflow: hidden;
                // .buy-btn{
                //     display: none;
                // }
                .golden-tag {
                    position: absolute;
                    left: -34px;
                    top: 8px;
                    display: block;
                    width: 100px;
                    background: #d19c30;
                    color: #ffffff;
                    font-size: 12px;
                    transform: rotate(-45deg);
                }
                .arco-icon{
                    display: block;
                    color: #865A14;
                }
            }

        }

    }

    .current-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;

        border-radius: 5px;
        z-index: 1;
        transition: all 0.3s ease-out;

        &.group {
            background: #fffef9;
            border: 1px solid #d9a740;
        }

        &.personal {
            background: #f7faff;
            border: 1px solid #4889ff;
        }
    }
}