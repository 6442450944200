.invoicein-formation {
    position: relative;
    width: 100%;
    height: 100%;
    .Payment-order.orderinfo {
        position: relative;
        width: 100%;
        min-height: calc(100% - 80px);
        margin-top: 15px;
        padding:46px 41px;
        border-radius: 22px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        box-sizing: border-box;
        .arco-input {
            width: 100%;
        }
        .arco-radio-group .arco-radio {
            margin-bottom: 0;
        }
        .arco-btn {
            width: 98px;
            height: 48px;
            border-radius: 14px;
        }
    
        .submit-htmlType {
            position: absolute;
            right: 23px;
            top: 23px;
            text-align: right;
            z-index: 9;
        }
    
        .custom-edit-button {
            width: 105px;
            height: 42px;
            border-radius: 10px;
        }
    
        .form-values {
            color: #0a1629;
            font-size: 14px;
        }
    
        .arco-radio-mask {
            width: 22px;
            height: 22px;
        }
    
        .arco-radio-checked .arco-radio-mask {
            border: 2px solid #406eff;
            background-color: transparent;
        }
    
        .arco-radio-text {
            margin-left: 8px !important;
        }
    
        .arco-radio-mask::after {
            width: 18px;
            height: 18px;
        }
    
        .arco-radio-checked .arco-radio-mask::after {
            background: #4a76ff;
            transform: scale(0.7);
        }
    
        .arco-input {
            width: 425px;
            height: 48px;
            background: #ffffff;
            border-color: #d8e0f0;
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        }
    
        .arco-textarea {
            width: 425px;
            height: 138px;
            background: #ffffff;
            border-color: #d8e0f0;
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
            resize: none;
        }
    
        .arco-input:focus,
        .arco-input:focus:hover {
            border-color: #406EFF;
        }
    
        .arco-textarea:focus,
        .arco-textarea:focus:hover {
            border-color: #406EFF;
        }
    
        .arco-form-item-status-error .arco-input:not(.arco-input-disabled),
        .arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
        .arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled),
        .arco-form-item-status-error .arco-input:not(.arco-input-disabled):hover,
        .arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
        .arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled):hover {
            border-color: #d8e0f0;
            background-color: transparent;
        }
    
        .arco-form-item-status-error .arco-input:focus,
        .arco-form-item-status-error .arco-input:focus:hover {
            border-color: #406EFF;
        }
    
        .arco-form-item-status-error .arco-textarea:focus,
        .arco-form-item-status-error .arco-textarea:focus:hover {
            border-color: #406EFF;
        }
    
        .arco-form-message {
            opacity: 0;
            color: transparent;
        }
    
        &.invoice-mail {
            .invoice-info {
                position: relative;
                width: 100%;
                padding: 22px;
                background: #f9fbff;
                border-radius: 14px;
                box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
                font-size: 14px;
    
                .invoice-name {
                    font-size: 16px;
                    margin-right: 16px;
                }
    
                .invoice-address {
                    margin-top: 12px;
                }
    
                .invoice-edit {
                    position: absolute;
                    right: 20px;
                    top: 14px;
                }
            }
    
            // .arco-steps-mode-dot .arco-steps-item-tail {
            //     background-color: transparent !important;
            // }
    
            // .arco-steps-item-tail {
            //     background-image: linear-gradient(to right, #CCCCCC 35%, rgba(204, 204, 204, 0) 0%);
            //     /* 35%设置虚线点x轴上的长度 */
            //     background-position: bottom;
            //     /* top配置上边框位置的虚线 */
            //     background-size: 10px 1px;
            //     /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
            //     background-repeat: repeat-x;
            // }
    
            .arco-steps-mode-dot .arco-steps-item:not(:last-child) .arco-steps-item-tail {
                top: 5.5px;
            }
    
            .arco-steps-mode-dot .arco-steps-item-icon {
                width: 12px;
                height: 12px;
            }
    
            .arco-steps-mode-dot .arco-steps-item-active .arco-steps-item-icon {
                width: 12px;
                height: 12px;
            }
    
            .arco-steps-mode-dot.arco-steps-horizontal .arco-steps-item-active .arco-steps-item-icon {
                background-color: #ffffff;
                border: 1px solid #406eff;
            }
    
            .orderID {
                font-size: 12px;
                color: #4e5969;
                margin-top: 58px;
    
                .orderID-express {
                    font-size: 18px;
                    margin-left: 8px;
                    margin-right: 13px;
                    color: #0a1629;
                }
    
                div.arco-typography,
                p.arco-typography {
                    font-size: 18px;
                    margin-bottom: 0;
                    color: #0a1629;
                }
            }
    
        }
    }
}

