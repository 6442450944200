.arco-modal.selectModalAdd{
  width: 1099px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121,145,173,0.20); 

    .arco-modal-header{
      
      height: 80px;
      padding: 0px 36px;
    }
    .arco-modal-title{
      text-align: left;
      font-size: 22px;
    }
    .selectModal-title{
      font-size: 20px;
      line-height: 20px;
        img{
            width: 20px;
            height: auto;
        }
    }
    .arco-modal-content{
        max-height: 75.3rem;
        padding-top: 0px;
        overflow: auto;
    }
    .arco-modal-close-icon{
      width: 44px;
      height: 44px;
      background: #f5f8ff;
      border-radius: 14px;
      text-align: center;
      line-height: 44px;
    }
    .arco-icon-hover::before{
      width: 44px;
      height: 44px;
      border-radius: 14px;
    }
// 单选组件样式
.arco-radio-group .arco-radio{
  margin-right: 29px;
}
.arco-radio-group .arco-radio:nth-child(5n+5){
  margin-right: 0;
}
.arco-spin{
    padding: 16px !important;
    padding-right: 0 !important;
    width: 100%;
}
.arco-radio-text{
    margin-left: 0 !important;
}
.arco-radio{
    padding-left: 0 !important;
}
.custom-radio-card {
    position: relative;
    padding: 13px 8px;
    border: 1px solid var(--color-border-2);
    border-radius: 4px;
    width: 182px;
    height: 78px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    .custom-radio-tag-disabled{
      position: absolute;
      top: 0;
      right: -1px;
      width: 51px;
      height: 19px;
      background-image: url('/asserts/developing.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  
.custom-radio-card-mask {
    height: 21px;
    width: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--color-border-2);
    box-sizing: border-box;
  }
  
  .custom-radio-card-mask-dot {
    width: 11px;
    height: 11px;
    border-radius: 100%;
  }
  
  .custom-radio-card-title {
    width: 135px;
    color: var(--color-text-1);
    font-size: 16px;
    margin-bottom: 5px !important;
    margin-top: 0px !important;
    margin-left: 0;
  }
  .arco-typography-secondary{
    width: 135px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 400;
  }
  .custom-Group .arco-radio-disabled .custom-radio-card{
    pointer-events: none;
    background: #fafafa;
    border-color: #e5e6eb;
    color: #a5a5a5;
    .arco-typography{
        color: #a5a5a5;
    }
  }
  .custom-radio-card:hover .custom-radio-card-title,
  .custom-radio-card:hover .arco-typography-secondary,
  .custom-radio-card-checked .custom-radio-card-title,
  .custom-radio-card-checked .arco-typography-secondary {
    color: #4A76FF;
  }
  .custom-radio-card:hover,
  .custom-radio-card-checked,
  .custom-radio-card:hover .custom-radio-card-mask,
  .custom-radio-card-checked .custom-radio-card-mask {
    border-color: #406EFF;
  }
  .custom-radio-card-checked {
    background-color: var(--color-primary-light-1);
  }
  
  .custom-radio-card:hover .custom-radio-card-title,
  .custom-radio-card-checked .custom-radio-card-title {
    color: #4A76FF;
  }
  
  .custom-radio-card-checked .custom-radio-card-mask-dot {
    background-color: #4A76FF;
  }
  .custom-Group .arco-icon-hover{
      display: none !important;
  }
  .arco-modal-footer{
    .arco-btn-secondary{
      width: 98px;
      height: 48px;
      background: #e7eeff;
      border-radius: 14px;
      color: #406eff;
    }
    .arco-btn-primary{
      width: 98px;
      height: 48px;
      background: #406eff;
      border-radius: 14px;
      box-shadow: 0px 6px 12px 0px rgba(63,140,255,0.26); 
    }
    
  }
  .arco-modal-footer > .arco-btn{
    margin-left: 18px;
  }
}