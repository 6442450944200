.enginefactory{
    padding:0 36px;
    box-sizing: border-box;
    .Work-order-card{
        border: 0 !important;   
    }
    .arco-card-size-default .arco-card-body{
        padding-top: 0;
    }
    .arco-table-th-item{
        padding: 20px 0;
    }
    .titleline{
        border-left: 5px solid #406EFF !important;
        margin-left: 0 !important;
    }
}