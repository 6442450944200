.entityExtraction {
  opacity: 0;
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

  }

  .semanticprototype-Layout {
    width: 100%;

    .arco-layout-sider-light.Sider-left {
      width: 373px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      margin-right: 20px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .Sider-left-w {
      width: 100%;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 10px;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-textarea-wrapper {
          width: 100%;
          height: 100%;
        }

        .arco-textarea {
          height: 100%;
          resize: none;
          background: #ffffff;
          border-radius: 14px;
          color: #0b172a;
          padding: 12px;
        }

        .arco-textarea-word-limit {
          background: transparent;
        }
      }
    }

    .generation-bottom {
      width: 100%;
      text-align: right;
      margin-top: 13px;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 22px;
    padding: 24px;
    color: #0B172A;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    // overflow: auto;

    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }


    .result_title {
      font-size: 16px;
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }



  .custom-Badge {
    width: 100%;
    text-align: left;

    .arco-badge-status-text {
      color: #586475;
      font-size: 14px;
    }

    .arco-badge-status-dot {
      position: relative;
      width: 8px;
      height: 8px;
    }

    .arco-badge:hover {
      cursor: pointer;

      .arco-badge-status-text {
        color: #0b172a;
        font-weight: bold;
      }

      .hover-Badge {
        opacity: 0.5;
        transition: all 0.3s linear;
      }
    }

    .hover-Badge {
      position: absolute;
      top: 5px;
      left: -2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.3s linear;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      width: 100%;

      .Continuation-list {
        width: 100%;
        border-radius: 8px;
        background: #FBFBFB;
        text-align: left;
        padding: 40px;
        margin-top: 16px;
        overflow: auto;

        .Continuation-item {
          font-size: 16px;
          line-height: 32px;
          span {
            position: relative;
            cursor: pointer;
            transition: all 0.3s linear;

            &:hover {
              .Popover {
                transform: translateX(-50%) scale(1);
                opacity: 1;
                transition: all 0.3s ease-out;
                z-index: 1;

                &:hover {
                  z-index: 2;
                }
              }

              transition: all 0.3s linear;
            }
          }

          .Popover {
            position: absolute;
            top: -40px;
            left: 50%;
            width: auto;
            padding: 3px 11px;
            border-radius: 4px;
            background: #ffffff;
            border: 1px solid #d7dfef;
            box-shadow: 0px 1px 2px 0px rgba(196, 203, 214, 0.30);
            color: #586475;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            white-space: nowrap;
            align-items: center;
            // display: none;
            display: inline-flex;
            opacity: 0;
            transform: translateX(-50%) scale(0);
            transition: all 0.3s linear;
            z-index: -1;

            .em {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-top: -2px;
              margin-right: 7px;
            }
          }

          .Popover:before {
            /*伪元素必须添加content*/
            content: "";
            width: 0;
            height: 0;
            /*IE6下，height:0;不顶用，可使用font-size:0; + overflow:hidden;修复此问题*/
            font-size: 0;
            overflow: hidden;
            display: block;
            border-width: 6px;
            border-color: #d7dfef transparent transparent transparent;
            /*为了兼容IE6，所有设置为透明（transparent）的边，需要设置为dashed；有颜色的边设置为solid*/
            border-style: solid dashed dashed dashed;
            border-bottom-left-radius: 2px;
            position: absolute;
            /*绝对定位不占位置*/
            bottom: -12px;
            /* 向下移动 矩形的高度 + 矩形的上下内边距 + 下边框粗细*/
            left: 50%;
            transform: translateX(-50%);
            /*相对于矩形宽度的位置*/
            z-index: -1;
          }

          .Popover::after {
            content: "";
            width: 0;
            height: 0;
            /*IE6下，height:0;不顶用，可使用font-size:0; + overflow:hidden;修复此问题*/
            font-size: 0;
            overflow: hidden;
            display: block;
            border-width: 6px;
            border-color: #ffffff transparent transparent transparent;
            /*为了兼容IE6，所有设置为透明（transparent）的边，需要设置为dashed；有颜色的边设置为solid*/
            border-style: solid dashed dashed dashed;
            border-bottom-left-radius: 2px;
            position: absolute;
            bottom: -11px;
            /* 向下移动 矩形的高度 + 矩形的上下内边距 + 下边框粗细*/
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            /*相对于矩形宽度的位置*/
          }
        }
      }
    }

    .Continuation-result {
      .arco-typography {
        margin-top: 0;
        margin-bottom: 0;
      }

      .result-remarks {
        position: absolute;
        right: 25px;
        bottom: 22px;
        color: #8f959f;
        font-size: 12px;
        text-align: right;
      }
    }

    .arco-btn.IconSync-btn {
      width: 166px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      margin-top: 33px;
      border: 1px solid #d8e0f0;
      border-radius: 10px;
    }

    .Continuation-loding {
      margin-top: 232px;
    }

    .Spin-loding {
      margin-bottom: 20px;
    }
  }

  .Continuation-Empty {
    margin-top: 180px;
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}