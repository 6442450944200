$base-color: #0C0C0F;

.affix-headers .arco-page-header {
  margin-bottom: 0px;
  padding-bottom: 0px
}

.affix-headers {
  position: relative;
}

.arco-affix~.affix-headers {
  overflow: hidden;
}

.arco-affix::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(219, 231, 241, 0.40);
  box-shadow: 0px 6px 59px 0px rgba(196, 203, 214, 0.10);
  backdrop-filter: blur(6px);
  padding-bottom: 16px;
  z-index: -1;
}

.arco-affix .arco-page-header-head-main-with-back {

  span,
  div {
    display: none;
  }
}

.asic-demo {
  .arco-page-header-title {
    font-size: 16px;
    color: #3f8cff;
  }

  .arco-page-header-back-icon {
    font-size: 16px;
    color: #3f8cff;
  }

  .arco-page-header-sub-title {
    font-size: 18px;
    font-family: "AlibabaPuHuiTi-Regular";
    font-weight: 400;
    text-align: left;
    color: $base-color;
  }

  .end-title {
    font-size: 14px;
    font-family: "AlibabaPuHuiTi-Regular";
    color: $base-color;
  }

  .headeright {
    .arco-space-item {
      display: flex;
      align-items: center;
      margin-right: 3px !important;
    }
  }

}

.username-ellipsis.arco-typography {
  display: inline-block;
  padding-right: 5px;
  max-width: 76px;
  margin-bottom: 0;
}

.arco-btn.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 178px;

  &:hover {
    .username-ellipsis.arco-typography {
      color: #406eff;
    }
  }

  &:active {
    .username-ellipsis.arco-typography {
      color: #0E42D2;
    }
  }
}

.header-menu .arco-dropdown-menu-item,
.arco-dropdown-menu-pop-header {
  padding: 0;
}

.header-menu {
  width: 100%;
  background: #ffffff;
  border-radius: 14px !important;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
  border: 0 !important;
  margin-top: 10px;
  padding: 13px 10px !important;

  .arco-link.arco-link-hoverless {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 36px;
    padding: 0 12px;
  }
  .arco-icon{
    margin-right: 18px;
  }
}

.arco-menu-inline-header {
  text-align: initial;
}

.dropdown-demo>.arco-btn {
  padding: 0 8px;
  font-weight: normal;
}

.dropdown-demo .arco-dropdown-popup-visible .arco-icon-down {
  transform: rotate(180deg);
}

.arco-dropdown-menu-item:hover,
.arco-dropdown-menu-pop-header:hover {
  background: #eff4f8;
  border-radius: 10px;
}

#layoutHeaders .arco-page-header-title {
  font-size: 16px;
  color: #3f8cff;
}

#layoutHeaders .arco-page-header-head-extra{
  overflow: inherit;
}

#layoutHeaders .arco-page-header-back {
  font-size: 16px;
  color: #3f8cff;
}

.demo-trigger-popup {
  // margin: 40px;
  // margin-right: 60px;

  .Upgrade-btn {
    width: 108px;
    height: 34px;
    background: linear-gradient(70deg, #feebbc 13%, #f6c75f 99%);
    border-radius: 7px;
    color: #9e7201;
    cursor: pointer;
    border: none;
    margin-top: 20px;
  }

  .see-vip {
    width: 272px;
    height: 131px;
    background-image: url('/asserts/see_vip.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    // background: linear-gradient(297deg, #fff3ca 24%, #fffdf1 91%);
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    padding: 17px 15px;
    font-size: 12px;
    color: #875d00;
  }
}