.product-experience {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

  }

  .semanticprototype-Layout {
    width: 100%;

    .arco-layout-sider-light.Sider-left {
      width: 409px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      margin-right: 20px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .Sider-left-w {
      width: 100%;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 10px;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-textarea-wrapper {
          width: 100%;
          height: 100%;
        }

        .arco-textarea {
          height: 100%;
          resize: none;
          background: #ffffff;
          border-radius: 14px;
          color: #0b172a;
          padding: 12px;
        }

        .arco-textarea-word-limit {
          background: transparent;
        }
      }
    }

    .generation-bottom {
      width: 100%;
      text-align: right;
      margin-top: 13px;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 14px;
    padding: 17px 25px;
    padding-top: 24px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    font-size: 14px;
    text-align: left;


    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      font-size: 16px;

      .arco-typography {
        margin-top: 0;
        margin-bottom: 0;
      }

      .result-remarks {
        color: #8d93a1;
        font-size: 12px;
      }
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      text-align: right;
      margin-top: 30px;

      .Continuation-list {
        width: 100%;
        text-align: left;
        overflow: auto;

        .Continuation-item {
          width: 100%;
          background: #fbfbfb;
          border-radius: 8px;
          margin-bottom: 28px;
          padding: 16px;
          color: #0B172A;
          border: 1px solid #fbfbfb;
          cursor: pointer;

          .Preamble {
            color: #406EFF;
          }

          &:hover {
            background: #f2f7ff;
            border: 1px solid #406eff;
          }
        }

        .Continuation-item:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 166px;
      height: 48px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-top: 33px;
      border-radius: 10px;
    }

    .Continuation-loding {
      margin-top: 232px;
    }

    .Spin-loding {
      margin-bottom: 20px;
    }
  }

  .Continuation-Empty {
    margin-top: 219px;
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}