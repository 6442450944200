.interfacetest {
    .GroupTable-fixe {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 31px;

        .interfacetest-fixetitle {
            color: #0b172a;
            font-size: 14px;
            
        }

        .interfacetest-api {
            min-width: 381px;
            padding: 12px 28px;
            text-align: center;
            background: #E7ECF7;
            border-radius: 14px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            margin-left: 2px;
            box-sizing: border-box;
            .arco-typography{
                display: inline-flex;
                margin-bottom: 0;
            }
        }

        .arco-btn-icon-only {
            width: 45px;
            height: 45px;
            background: #ffffff;
            border-radius: 14px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            color: #4E5969;
        }

        .Header-init-btn {
            width: 118px;
            height: 45px;
            //   background: #406eff;
            border-radius: 10px;
            box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        }

        .primary-white {
            width: 100px;
            height: 45px;
            background: #ffffff;
            border-radius: 14px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            color: #0b172a;
        }
        .GroupTable {
            width: auto;
            height: 45px;
            background: #f2f6ff;
            border-radius: 24px;
            overflow: hidden;

            .arco-radio-button {
                width: 111px;
                height: 38px;
                line-height: 38px;
                color: #1d2129;
                font-size: 14px;
                text-align: center;
            }

            .arco-radio-button:hover {
                border-radius: 24px;
            }

            .arco-radio-button::after {
                background: #f2f6ff;
            }

            .arco-radio-button:not(:first-of-type)::before {
                background: transparent;
                color: transparent;
            }

            .arco-radio-button.arco-radio-checked {
                width: 111px;
                height: 38px;
                background: #406eff;
                border-radius: 20px;
                color: #ffffff;
                font-size: 14px;
                line-height: 36px;
                text-align: center;
            }
        }
    }

    .titleline {
        border-left: 5px solid #406EFF !important;
        margin-left: 0 !important;
    }

    .Work-Content {
        width: 100%;
        height: 100%;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        box-sizing: border-box;
        &.set-margins{
            padding: 28px;
            background: #ffffff;
        }
     
    }
    .Tabsbody-ul{
        width: 100%;
        height: 100%;
    }
    .Tabsbody {
        width: 100%;
        height: 100%;
        text-align: left;
    }

    .Sider-left {
        width: 509px !important;
        background: #ffffff;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        margin-left: 28px;
        border-radius: 22px;
        
        .outline {
            width: 87px;
            height: 32px;
            border-radius: 8px;
        }
    }

    .debugg-sider {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: left;
        color: #0c0c0f;
        font-size: 14px;
        padding:19px 6px;
        // padding: 25px 34px;
        // margin-bottom: 100px;
        box-sizing: border-box;
        .debugg-sider-input{
            position: relative;
            width: 100%;
            padding: 6px 28px;
            height: calc(100% - 100px);
            overflow-y:auto ;
        }
        .arco-form-item.FormItem-debugg-title {
            margin-bottom: 0;
            margin-top: 32px;
        }
        .arco-form-item.FormItem-debugg-title:first-of-type{
            margin-top: 3px;
        }
        .arco-form-item {
            margin-bottom: 0;
        }
        .arco-select{
            width: auto;
        }
        .arco-select .arco-select-view {
            // width: 441px;
            width: 386px;
            height: 48px;
            line-height: 46px;
            background: #ffffff;
            border-color:  #d8e0f0;
            border-radius: 14px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        }

        .arco-input {
            // width: 441px;
            width: 386px;
            height: 48px;
            background: #ffffff;
            border-color:  #d8e0f0;
            border-radius: 14px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        }
        .arco-textarea{
            // width: 441px;
            width: 386px;
            min-height: 100px;
            background: #ffffff;
            border-color:  #d8e0f0;
            border-radius: 14px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        }
        .readOnly.arco-input {
            width: 386px;
            height: 48px;
            background: #f9fbff;
            color: #8d93a1;
            border-radius: 14px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
        }
        .arco-input-number .arco-input-inner-wrapper{
            // width: 441px;
            width: 386px;
            height: 48px;
            background: #ffffff;
            border-color: #d8e0f0;
            border-radius: 14px;
            box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
            box-sizing: border-box;
            
            .arco-input{
                width: 100%;
                height: 100%;
                border:0;
                box-shadow:none;
            }
        }
        .arco-input-inner-wrapper:focus,
        .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,.arco-select.arco-select-focused .arco-select-view {
            border-color: #406EFF;
        } 
        .arco-input:focus,
        .arco-input:focus:hover {
            border-color: #406EFF;
        }
    
        .arco-textarea:focus,
        .arco-textarea:focus:hover {
            border-color: #406EFF;
        }
        .field_upload{
            padding-top: 50px;
            box-sizing: border-box;
        }
        .Form-btnicon {
            width: 45px;
            height: 45px;
            background: #ffffff;
            border: 1px solid #eceff8;
            border-radius: 14px;
            font-size: 18px;
            color: #4E5969;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        }
        .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover{
            color: #406eff;
            background: #ffffff;
            border-color: #eceff8;
        }
        .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active{
            color: #0e42d2;
            background: #ffffff;
            border-color: #eceff8;
        }
    }
    .request_button{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #ffffff;
        text-align: right;
        padding: 0px 17px;
        padding-bottom: 29px;
        border-radius: 22px;
        box-sizing: border-box;
    }
    .Initiate-request {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        margin-top: 0px;
        margin-right: 16px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }
    .debugg-title {
        font-size: 16px;
    }

    .arco-form-layout-vertical>.arco-form-label-item {
        color: #4e5969;
        margin-top: 17px;
        margin-bottom: 11px;
    }

    .debugg-sider-input {
        width: 100%;
    }

    .tabli-none {
        margin-top: 65px;
    }
    .tabli {
        width: 100%;
        height: calc(50% - 17px);
        text-align: left;
        font-size: 16px;
        padding: 28px 31px;
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        margin-bottom: 33px;
        box-sizing: border-box;

        .arco-empty .arco-empty-wrapper img {
            width: 129px;
            height: 113px;
        }

        .tabText {
            width: 100%;
            height: calc(100% - 27px);
            border-radius: 10px;
            box-sizing: border-box;
            border: 1px solid #D8E0F0;
            margin-top: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #0C0C0F;
            padding: 14px 8px;
            box-sizing: border-box;
            .arco-spin{
                width: 100%;
                text-align: center;
                margin-top: 70px;
            }
            .tabText-overflow{
                width: 100%;
                height: 100%;
                padding: 2px 11px;
                overflow-y: auto;
            }
            .arco-textarea {
                width: 100%;
                height: 100%;
                background: transparent;
            }
        }
    }
    .tabli:last-of-type{
        margin-bottom: 0;
    }

    .description {
        width: 100%;
        height: auto;
        padding-bottom: 29px;
        box-sizing: border-box;

        .arco-descriptions {
            margin-bottom: 40px;
        }

        .arco-descriptions:last-of-type {
            margin-bottom: 0;
        }

        .arco-descriptions-title {
            font-size: 16px;
            color: #0a1629;
            margin-bottom: 18px;
        }

        .arco-descriptions-item-label-inline {
            color: #0c0c0f;
        }

        .arco-descriptions-body {
            width: 100%;
            height: auto;
            border: 1px solid #d8e0f0;
            border-radius: 10px;
            padding: 19px;
            font-size: 14px;
        }
    }

    .content-Radio {
        width: 100%;
        height: 550px;
        background: #ffffff;
        border: 1px solid #e7e8ec;
        border-radius: 2px;

        .arco-textarea {
            width: 100%;
            height: 100%;
            background: transparent;
        }
    }
     /*整个滚动条*/
     .tabText-overflow::-webkit-scrollbar {
        width: 7px;
        height: 4px;
        background-color: transparent;
    }
    /*定义滚动条轨道*/
    .tabText-overflow::-webkit-scrollbar-track {
        background-color: transparent;
    }
    /*定义滑块*/
    .tabText-overflow::-webkit-scrollbar-thumb {
        border: 4px solid #d3dbeb;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #d3dbeb;
    }
    .edit-title .Header-title{
        &::before{
            background-color: transparent;
        }
    }
    .edit-title .Header-init .Header-uptitle{
        margin-top: 10px;
        &:hover{
            cursor: pointer;
            .arco-typography-operation-edit{
                opacity: 1;
            }
        }
        .arco-typography-operation-edit{
            opacity: 0;
        }
    }
    .edit-title .Header-upParagraph .Header-uptitle{
        &:hover{
            cursor: pointer;
            .arco-typography-operation-edit{
                opacity: 1;
            }
        }
        .arco-typography-operation-edit{
            opacity: 0;
        }
    }
}
.interfacetest .Continuation-loding{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
     .arco-empty-wrapper img{
        width: 68px !important;
        height: 68px !important;
    }
}

@media screen and (max-height: 850px) {
    .interfacetest .tabli-none{
        margin-top: 25px;
    }
}