/* 全局公用样式 */
$base-color: #0C0C0F;
$base-btn: #406EFF;
@import url(//frontend.static.yoojober.cn/fonts/AlibabaPuHuiTi-Medium/stylesheet.css);
@import url(//frontend.static.yoojober.cn/fonts/AlibabaPuHuiTi-Regular/stylesheet.css);

html,
body,
#root,
.main-content {
  width: 100%;
  height: 100%;
  color: $base-color;
  background: #f9fbff;
  min-width: 1200px;
  font-family: "AlibabaPuHuiTi-Regular";
  font-size: 10px;
}

.monthly-tooltip .arco-tooltip-content {
  font-size: 14px;
  line-height: 24px;
  color: #1D2129;
  border-radius: 8px;
  border: 1px solid #E5E6EB;
  box-shadow: 0px -3px 12px 0px rgba(196, 203, 214, 0.3);
}

.monthly-tooltip .arco-trigger-arrow {
  border: 1px solid #E5E6EB;
  z-index: 1;
}
.arco-table-th-item.arco-table-col-has-filter{
  padding-left: 28px;
}
.arco-modal.custom-modal {
  border-radius: 24px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.2);

  .arco-modal-header {
    height: 80px;
    padding: 0px 28px;
  }

  .arco-modal-title {
    justify-content: flex-start;
    text-align: left;
    font-size: 22px;
  }

  .arco-modal-content {
    max-height: 75.3rem;
    overflow: auto;

    .Header-init .Header-title::before {
      width: 4px;
      border-radius: 20px;
    }
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    font-size: 18px;
    border-radius: 14px;
    text-align: center;
    line-height: 44px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .arco-icon-hover .arco-icon {
    vertical-align: -2px;
  }

  .arco-icon-hover::before {
    width: 44px;
    height: 44px;
    border-radius: 14px;
  }

  .arco-btn-secondary {
    min-width: 98px;
    height: 48px;
    background: #e7eeff;
    border-radius: 14px;
    color: #406eff;
  }

  .arco-btn-primary {
    min-width: 98px;
    height: 48px;
    background: #406eff;
    border-radius: 14px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }

  .custom-footer {
    text-align: right;
  }

  .custom-footer .custom-btn-secondary {
    color: #406EFF;
    background: #E7EEFF;
    margin-right: 13px;

    &:hover {
      background: #EEF3FF;
      color: #406EFF;
    }

    &:active {
      color: #2759E0;
      background: #E6ECFA;
    }
  }
}

.arco-modal-footer,
.arco-modal-header {
  border: none !important;
}

.arco-form-label-item .arco-form-item-symbol svg {
  transform: scale(0.6) !important;
}

.versionsSelect .arco-select-view,
.typeSelect .arco-select-view {
  width: 100% !important;
}

.add-IconLeft,
.add-IconRight {
  position: absolute;
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: #e7eeff;
  border-radius: 33px;
  text-align: center;
  color: #406eff;
  cursor: pointer;

  &:hover {
    background: #eef3ff;
  }

  &:active {
    background: #e6ecfa;
    color: #2759E0;
  }
}

.add-IconLeft {
  left: -47px;
}

.add-IconRight {
  right: -47px;
}

.primary_model_name .primary_model_name_fix {
  display: flex !important;
  align-items: center;

  div.arco-typography,
  p.arco-typography {
    width: 138px;
    margin-bottom: 0;
  }
}

.Group-fadeInUp {
  -webkit-animation-name: Group_fadeInit;
  animation-name: Group_fadeInit;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes Group_fadeInit {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Group_fadeInit {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.payment-explain {
  width: 100%;
  height: auto;
  border-radius: 14px;
  font-size: 12px;
  background: #EFF6FF;
  color: #5785BC;
  padding: 16px;
  line-height: 24px;
  box-sizing: border-box;
  text-align: left;

  .payment-explain-lable {
    color: #414551;
    margin-bottom: 6px;

    .arco-icon {
      color: #FFB83E;
      margin-right: 8px;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}

// 体验页AI能力提示
.result_title {
  .arco-typography {
    margin-top: 0;
    margin-bottom: 0;
  }

  .result-remarks {
    color: #8f959f;
    font-size: 12px;
  }
}

// 会员
.skew-x {
  display: inline-block;
  padding: 0px 10px;
  background: linear-gradient(90deg, #b67f2d, #8e5600 91%);
  border-radius: 5px;
  width: auto;
  height: auto;
  text-align: center;
  transform: skewX(-34deg);
  margin-left: 28px;

  &.bule {
    background: #002e7f;
  }
}

.skew-x::before {
  position: absolute;
  content: '';
  width: 28px;
  height: 24px;
  background-image: url('/asserts/golden_vips_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: skewX(34deg);
  left: -18px;
  top: -4px;
}

.skew-x.bule::before {
  position: absolute;
  content: '';
  width: 28px;
  height: 24px;
  background-image: url('/asserts/blue_vip_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: skewX(34deg);
  left: -18px;
  top: -4px;
}

.skew-x>div {
  color: #ffffff;
  transform: skewX(34deg);
  font-size: 8px;
  line-height: 17px;
}

.Tag-svp.arco-tag {
  height: 34px;
  background: linear-gradient(70deg, #feebbc 13%, #f6c75f 99%);
  color: #9e7201;
  border-radius: 7px;
  border: 0;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Medium;
  cursor: pointer;
}

.Tag-svp.Upgrade-bule.arco-tag {
  background: #4889ff;
  color: #ffffff;
}

.Tag-svp.Upgrade-bule {
  background: #4889ff;

  &:hover {
    background: #71a4ff;
  }

  &:active {
    background: #2b76ff;
  }
}

.Upgrade-svp {
  background: linear-gradient(111deg, #F8D9AD 18%, #EAB87C 89%);

  &:hover {
    background: linear-gradient(70deg, #fff2d3 13%, #fed987 99%);
  }

  &:active {
    background: linear-gradient(250deg, #f0c25c 1%, #feebbc 87%);
  }
}

.Upgrade-svp.arco-btn-primary:not(.arco-btn-disabled) {
  border: 0;
}

.Popconfirm-Upgrade .arco-popconfirm-btn {
  display: none;
}

.Popconfirm-Upgrade .arco-popconfirm-title {
  margin-bottom: 0;
}

.Popconfirm-Upgrade .arco-popconfirm-content {
  padding: 12px 12px;
  background: #fffbef;
  font-size: 12px;
  border: 1px solid #dfd3b5;
  box-shadow: 0px 6px 30px 0px rgba(196, 203, 214, 0.30);

  .Popconfirm-Upgrade-font {
    margin-right: 12px;
  }

  .Popconfirm-Upgrade-title {
    color: #936500;
  }

  .Popconfirm-Upgrade-text {
    color: #936500;
  }

  .golden_vip_table {
    display: inline-block;
    width: 34px;
    height: 28px;
    background-image: url('/asserts/golden_vip_icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
  }

  .Upgrade-btn {
    width: 80px;
    height: 34px;
    background: linear-gradient(70deg, #feebbc 13%, #f6c75f 99%);
    border-radius: 7px;
    border: 0;
    color: #9e7201;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 6px 12px 0px rgba(176, 154, 100, 0.26);
  }

  .Upgrade-svp {
    background: linear-gradient(70deg, #feebbc 13%, #f6c75f 99%);

    &:hover {
      background: linear-gradient(111deg, #F9E0BD 18%, #EEC492 89%);
    }

    &:active {
      background: linear-gradient(111deg, #EAC590 20%, #D9A76C 89%);
    }
  }
}

.Popconfirm-Upgrade .arco-popconfirm-arrow.arco-trigger-arrow {
  background-color: #fffbef;
  border: 1px solid #dfd3b5;
}


// 消息弹窗字体大小
.arco-notification-content {
  margin-top: 2px;
}

// 全局选择hover气泡
.select-Popover .arco-popover-content,
.arco-popconfirm-content {
  font-size: 14px;
  border: 1px solid #e5e6eb;
  box-shadow: 0px 6px 30px 0px rgba(196, 203, 214, 0.30);
}

// 自定义必填项
.formtitle {
  position: relative;
  padding-left: 18px;
  box-sizing: border-box;
}

.custom-Required {
  .formtitle::before {
    position: absolute;
    content: '*';
    left: 0;
    color: #e0280f;
    font-size: 18px;
  }
}

.forminput .forminput-item {
  margin-left: 18px;
}

.custom-upload-trigger-link {
  padding: 2px;
}

.custom-upload-trigger-link:hover {
  border-radius: 5px;
  background-color: rgba(242, 243, 245, 0.5);
}

.custom-upload-trigger-link:active {
  background-color: rgba(242, 243, 245, 1);
}

//自定义按钮

.arco-link.custom-White-button:hover {
  color: #406eff;
  background: #ffffff;
}

.arco-link.custom-White-button:active {
  color: #0e42d2;
  background: #ffffff;
}

.arco-link.custom-White-button {
  .arco-link-icon {
    color: #4E5969;
  }
}

.arco-link.custom-White-button,
.arco-btn.custom-White-button {
  color: #0c0c0f;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

  &:hover {
    color: #406eff;

    .arco-link-icon {
      color: #406eff;
    }

    .arco-typography {
      color: #406eff;
    }
  }

  &:active {
    color: #0e42d2;

    .arco-link-icon {
      color: #0e42d2;
    }

    .arco-typography {
      color: #0e42d2;
    }
  }

  &:disabled {
    pointer-events: none;
    color: #9da5b1;

    .arco-typography {
      color: #9da5b1;
    }
  }
}

.share_button_wnk {
  position: relative;
  z-index: 3;
}

.share_button_wnk:hover:after {
  content: '分享体验所用资源量，将从对应引擎资源中扣除';
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 0 #b0b5bd;
  right: 0;
  bottom: -50px;
  // transform: translateX(-50%);
  color: #535d6c !important;
}

.custom-White-button.arco-btn-secondary:not(.arco-btn-disabled) {
  font-size: 14px;
  color: #4e5969;
  background: #ffffff;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

  span {
    color: #0b172a;
  }
}

.custom-White-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #406eff;
  background: #ffffff;

  span {
    color: #406eff;
  }
}

.custom-White-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #0e42d2;
  background: #ffffff;

  span {
    color: #0e42d2;
  }
}

.custom-White-button.arco-btn-secondary.arco-btn-disabled {
  font-size: 14px;
  color: #9DA5B1;
  background: #f2f3f5;

  span {
    color: #9DA5B1;
  }
}

.custom-White-border-button.arco-btn-secondary:not(.arco-btn-disabled) {
  color: #0c0c0f;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
}

.custom-White-border-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #406eff;
  background: #ffffff;
  border: 1px solid #406eff;
}

.custom-White-border-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #406eff;
  background: #ebf2ff;
  border: 1px solid #406eff;
}

.custom-White-border-button.arco-btn-secondary.arco-btn-disabled {
  color: #9DA5B1;
  background: #f2f3f5;
  border: 1px solid #9da5b1;
}

.custom-Black-button {
  color: #ffffff;
  font-size: 12px;
  background: #233862;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);

  &:hover {
    background: #39507f;
  }

  &:active {
    background: #142b58;
  }
}

.custom-Black-button.arco-btn-secondary:not(.arco-btn-disabled) {
  color: #ffffff;
  font-size: 12px;
  background: #233862;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);

  &:hover {
    color: #ffffff;
    background: #39507f;
  }

  &:active {
    color: #ffffff;
    background: #142b58;
  }
}

.custom-Black-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #ffffff;
  background: #39507f;
}

.custom-Black-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #ffffff;
  background: #142b58;
}

.custom-vip-services.arco-btn-secondary:not(.arco-btn-disabled) {
  color: #FFEED8;
  font-size: 12px;
  background: #34302D;
  box-shadow: 0px 6px 12px 0px #B98C56;

  &:hover {
    background: #665549;
  }

  &:active {
    background: #1A1613;
  }
}

.custom-vip-services.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #FFEED8;
  background: #665549;
}

.custom-vip-services.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #FFEED8;
  background: #1A1613;
}

.arco-btn-secondary:not(.arco-btn-disabled).custom-cancel-button {
  color: #406eff;
  font-size: 16px;
  background: #e7eeff;

  &:hover {
    background: #eef3ff;
  }

  &:active {
    color: #2759e0;
    background: #e6ecfa;
  }
}

.custom-cancel-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #406eff;
  background: #eef3ff;
}

.custom-cancel-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #2759e0;
  background: #e6ecfa;
}

.arco-btn-secondary:not(.arco-btn-disabled).custom-confirm-button {
  color: #ffffff;
  font-size: 16px;
  background: #406eff;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);

  &:hover {
    background: #4080ff;
  }

  &:active {
    background: #0e42d2;
  }
}

.custom-confirm-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #ffffff;
  background: #4080ff;
}

.custom-confirm-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #ffffff;
  background: #0e42d2;
}

.arco-modal-footer .arco-btn.arco-btn-secondary:not(.arco-btn-disabled) {
  color: #406eff;
  font-size: 16px;
  background: #e7eeff;

  &:hover {
    background: #eef3ff;
  }

  &:active {
    color: #2759e0;
    background: #e6ecfa;
  }
}

.arco-modal-footer .arco-btn.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #406eff;
  background: #eef3ff;
}

.arco-modal-footer .arco-btn.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #2759e0;
  background: #e6ecfa;
}

.arco-modal-footer .arco-btn.arco-btn-primary:not(.arco-btn-disabled) {
  color: #ffffff;
  font-size: 16px;
  background: #406eff;
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);

  &:hover {
    background: #4080ff;
  }

  &:active {
    background: #0e42d2;
  }
}

.arco-modal-footer .arco-btn.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #ffffff;
  background: #4080ff;
}

.arco-modal-footer .arco-btn.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #ffffff;
  background: #0e42d2;
}

.custom-step-button {
  color: #3f8cff;
  font-size: 16px;
  background: transparent;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

  &:hover {
    color: #4080ff;
    background: #eef3ff;
  }

  &:active {
    color: #2759e0;
    background: #e6ecfa;
  }
}

.custom-step-button.arco-btn-secondary:not(.arco-btn-disabled) {
  color: #3f8cff;
  font-size: 16px;
  background: transparent;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}

.custom-step-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  color: #4080ff;
  background: #eef3ff;
}

.custom-step-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):active {
  color: #2759e0;
  background: #e6ecfa;
}

.arco-btn-secondary:not(.arco-btn-disabled).custom-select {
  background: #ffffff;
  color: #0c0c0f;
  font-size: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}

.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading).custom-select:hover {
  color: #406eff;
  background: #ffffff;
}

.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading).custom-select:active {
  color: #0e42d2;
  background: #ffffff;
}

.custom-checkAll {
  width: 101px;
  height: 45px;
  background: #f2f6ff;
  border-radius: 24px;
  color: #1d2129;
  font-size: 14px;

  .arco-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    padding-left: 14px;
  }

  .arco-checkbox-mask {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #406eff;
  }

  .arco-checkbox-checked .arco-checkbox-mask-icon {
    transform: scale(1.2);
  }
}

.billing-list {
  width: 100%;
  height: auto;

  .table-hr {
    position: absolute;
    left: var(--left);
    width: 1px;
    height: 100%;
    background: #E6EBF5;
  }

  .billing-table {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #0C0C0F;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #E6EBF5;
  }

  .table-th {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .setheight {
    height: var(--height);
    text-align: left;
    padding: 10px;
    padding-left: 33px;
    box-sizing: border-box;
  }

  .table-td-full {
    position: absolute;
    width: var(--width);
    background-color: var(--background);
    height: 100%;
    left: 0;
    top: 0;
  }

  .table-td {
    width: var(--width);
    height: 100%;
    background-color: var(--background);
    box-sizing: border-box;
  }

  .table-td:first-of-type {
    border-left: 0;
  }

  .table-bodys {
    position: relative;
    width: 100%;
    height: 100%;

    .table-th {
      min-height: 57px;
      border-top: 1px solid #E6EBF5;
      box-sizing: border-box;
    }

    .table-td {
      .table-th:first-of-type {
        border-top: 0;
      }
    }
  }

  .table-head {
    height: 93px;
    color: #4C71B5;
    background: #F1FAFF;
    border: 1px solid rgba(223, 244, 255, 0.24);
    box-sizing: border-box;

    .table-td {
      border-left: 1px solid #E6EBF5;
      box-sizing: border-box;
    }

    .table-td:first-of-type {
      border-left: 0;
    }
  }
}

.table-spin-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-table .custom-td {
  font-size: 14px !important;
}

.arco-table-body {
  min-height: 235px !important;
}

.arco-table {
  background: #ffffff;
}

.arco-table .arco-table-td {
  border-color: #E6EBF5 !important;
}

.arco-table .arco-table-cell {
  width: 100%;
}

.arco-table thead>.arco-table-tr>.arco-table-th {
  border-bottom: 1px solid #E6EBF5 !important;
  box-sizing: border-box;
}

.arco-table .arco-table-th {
  background: #ffffff !important;
  color: #0C0C0F !important;
}

.arco-table .arco-checkbox-mask {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #406eff;
  box-sizing: border-box;
}

.arco-table .arco-checkbox-checked .arco-checkbox-mask-icon {
  transform: scale(1.2);
}

.main-content {
  text-align: left;
}

.Empty-absolute {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
}

.arco-alert.custom-alert {
  position: relative;
  padding: 0;

  .arco-alert-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .arco-alert-close-btn {
    font-size: 15px;

    &:hover {
      color: #ffffff;
    }
  }

  .alertimg {
    width: 100%;
  }
}

.header-edit {
  .Header-uptitle {
    cursor: pointer;

    &:hover {
      .arco-typography-operation-edit {
        opacity: 1;
      }
    }
  }

  .arco-typography-operation-edit {
    opacity: 0;
  }
}

.Card-main {
  // padding: 0px 14px;
  box-sizing: border-box;
}

.GroupTable-fixe {

  .GroupTable {
    .arco-radio-button {
      border-radius: 20px;
    }

    .arco-radio-button-inner {
      border-radius: 20px;
    }

    .arco-radio-button-inner {
      line-height: 38px;
    }
  }
}

.GroupTable-fixe-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Header-upParagraph {
  height: 25px;
  margin-top: 7px;
  padding-left: 14px;
  color: #4e5969;
  font-size: 14px;

  .Header-uptitle {
    margin-bottom: 0;
  }

  .arco-typography-operation-edit {
    font-size: 14px;
  }

  .arco-typography-edit-content {
    position: relative;
    top: -3px;
    left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 25px;
    width: 500px;

    .arco-textarea {
      height: 25px !important;
      resize: none;
      border-radius: 14px;
    }
  }
}

.Header-init {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  color: $base-color;

  .Header-uptitle {
    width: 100%;
    font-family: "AlibabaPuHuiTi-Medium";
    margin-bottom: 0;
    height: 25px;

    .arco-typography-operation-edit {
      font-size: 14px;
    }
  }

  .arco-typography-edit-content {
    position: relative;
    left: 0;
    top: -3px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 25px;
    width: 500px;

    .arco-textarea {
      height: 20px !important;
      resize: none;
      border-radius: 14px;
    }
  }

  .Header-title {
    position: relative;
    color: $base-color;
    font-size: 18px;
    padding-left: 13px;
    font-family: "AlibabaPuHuiTi-Medium";
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .Header-title::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 16px;
    background: #406eff;
    border-radius: 2px;
  }

  .Header-init-rigth {
    color: #4e5969;
    font-size: 14px;
  }
}

.arco-modal.deleteModal {
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-header {
    border-bottom: 0;
    padding: 0 28px;
    height: 80px;
  }

  .arco-modal-title {
    font-size: 22px;
    text-align: left;
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #f5f8ff;
    border-radius: 14px;
    font-size: 22px;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .arco-modal-content {
    font-size: 20px;
    text-align: center;
    padding: 66px 36px;
    margin-top: 0px;
  }

  .arco-modal-footer {
    border-top: 0;
    padding: 36px;

    .arco-btn {
      min-width: 98px;
      height: 48px;
      border-radius: 14px;
      font-size: 16px;
      box-sizing: border-box;
    }

    .arco-btn-secondary {
      background: #e7eeff;
      color: #406eff;
      border-radius: 14px;
      box-shadow: 0px 6px 12px 0px rgba(174, 174, 174, 0.26);

      &:hover {
        background: #eef3ff;
        color: #406eff;
      }

      &:active {
        background: #e6ecfa;
      }
    }
  }
}

.arco-statistic-content .arco-statistic-value-int,
.arco-statistic-value-prefix,
.arco-statistic-value-decimal {
  font-family: "AlibabaPuHuiTi-Medium";
  font-size: 28px;
}

.resources .arco-statistic-value-suffix,
.promotion-surplus .arco-statistic-value-suffix {
  font-family: "AlibabaPuHuiTi-Medium";
  margin-left: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.AlibabaPuHuiTi-Medium,
.arco-table-th,
.arco-table-th-item-title,
.Model-selsect-item-tag {
  font-family: "AlibabaPuHuiTi-Medium";
}

.text-left {
  text-align: left;
}

.loadingbody {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingbody.arco-spin-loading {
  position: absolute;
}

.rowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rowCenterStart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.rowSpaceAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.rowAroundAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}

.rowStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rowStartWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.rowFlexEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.rowFlexEndStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.rowFlexEndEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.rowFlexStartEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.rowFlexStartStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columnStartStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.columnStartCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.columnCenterStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.columnCenterAround {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}

.columnEnd {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.columnEndStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.columnSpaceAround {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.columnSpaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.columnSpaceStart {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

// 单选组件样式
.arco-radio-group .arco-radio {
  margin-bottom: 20px;
}

.arco-spin {
  padding: 20px !important;
}

.arco-table .arco-spin {
  padding-top: 0 !important;
}

.arco-radio-text {
  margin-left: 0 !important;
}

.arco-radio {
  padding-left: 0 !important;
}

// 单选类型1
.custom-radio-card {
  padding: 13px;
  border: 1px solid var(--color-border-2);
  border-radius: 4px;
  min-width: 166px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
}

.custom-radio-card-mask {
  height: 22px;
  width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--color-border-2);
  box-sizing: border-box;
}

.custom-radio-card-mask-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.custom-radio-card-title {
  color: var(--color-text-1);
  font-size: 16px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 4px;
}

.custom-Group .arco-radio-disabled .custom-radio-card {
  pointer-events: none;
  background: #fafafa;
  border-color: #e5e6eb;
  color: #a5a5a5;

  .arco-typography {
    color: #a5a5a5;
  }
}

.custom-radio-card:hover,
.custom-radio-card-checked,
.custom-radio-card:hover .custom-radio-card-mask,
.custom-radio-card-checked .custom-radio-card-mask {
  border-color: #406EFF;
}

.custom-radio-card-checked {
  background-color: var(--color-primary-light-1);
}

.custom-radio-card:hover .custom-radio-card-title,
.custom-radio-card-checked .custom-radio-card-title {
  color: #4A76FF;
}

.custom-radio-card-checked .custom-radio-card-mask-dot {
  background-color: #4A76FF;
}

// 单选类型2
.custom-radio {
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 10px;

  .arco-icon-hover {
    display: none;
  }
}

.custom-radio-mask {
  height: 22px;
  width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--color-border-2);
  box-sizing: border-box;
}

.custom-radio-mask-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.custom-radio-title {
  color: #0A1629;
  font-size: 14px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 4px;
}

.custom-Group .arco-radio-disabled .custom-radio {
  pointer-events: none;
  background: #fafafa;
  border-color: #e5e6eb;
  color: #a5a5a5;

  .arco-typography {
    color: #a5a5a5;
  }
}

.custom-radio:hover,
.custom-radio-checked,
.custom-radio:hover .custom-radio-mask,
.custom-radio-checked .custom-radio-mask {
  border-color: #406EFF;
}

.custom-radio-card-checked {
  background-color: var(--color-primary-light-1);
}

.custom-radio:hover .custom-radio-title,
.custom-radio-checked .custom-radio-title {
  color: #4A76FF;
}

.custom-radio-checked .custom-radio-mask-dot {
  background-color: #4A76FF;
}

.custom-Group .arco-icon-hover {
  display: none !important;
}
.arco-table-filters-item {
  .arco-radio {
    padding-left: 12px !important;
  }
  .arco-radio-text{
    margin-left: 8px !important;
  }
}
@media screen and (max-width:1430px) {

  .arco-modal.selectModalAdd {
    height: auto;
  }

  .arco-modal.selectModalAdd .arco-modal-content {
    max-height: 53rem;
  }

  .arco-table .arco-table-td {
    font-size: 12px;
  }

  .arco-table {
    background: #ffffff;

    .arco-btn.outlineTable,
    .arco-btn.outline {
      width: 80px;
      height: 28px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width:1440px) {
  .arco-modal.selectModalAdd {
    height: auto;
  }

  .arco-modal.selectModalAdd .arco-modal-content {
    max-height: 53rem;
  }

  .arco-table .arco-table-td {
    font-size: 12px;
  }

  .arco-table {
    background: #ffffff;

    .arco-btn.outlineTable,
    .arco-btn.outline {
      width: 80px;
      height: 28px;
      font-size: 12px;
    }
  }
}

@media screen and (min-width:1440px) and (max-width:1600px) {
  .arco-modal.selectModalAdd .arco-modal-content {
    max-height: 530px;
  }

  .arco-table .arco-table-td {
    font-size: 12px;
  }

  .arco-table {
    background: #ffffff;

    .arco-btn.outlineTable,
    .arco-btn.outline {
      width: 80px;
      height: 28px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width:1740px) {
  .overview .overview-modular .overview-modular-left .arco-carousel {
    height: 160px !important;
  }

  .overview .overview-modular .overview-modular-left .arco-carousel img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width:1536px) and (max-width:1600px) {}

@media screen and (min-width:1600px) and (max-width:1700px) {}

@media screen and (min-width:1700px) and (max-width:1900px) {}

@media screen and (min-width:1900px) and (max-width:1920px) {}

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin font($font-size, $color: #333, $text-align: left) {
  font-size: $font-size;
  color: $color;
  text-align: $text-align;
}

@mixin wh($w, $h) {
  width: $w;
  height: $h;
}