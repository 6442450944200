.payment-formation {
    width: 100%;
    height: 100%;
    color: #0c0c0f;
    text-align: left;
    box-sizing: border-box;

    .payment-balance {
        width: 100%;
        height: 235px;
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        margin-top: 18px;
        padding: 21px 41px;
        font-size: 14px;
        margin-bottom: 25px;

        .payment-label {
            svg {
                margin-right: 8px;
            }
        }
        .payment-end{
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 2px solid rgba(196, 203, 214, 0.30);
        }
        .payment-prcieDetail{
            // width: 100%;
            padding: 20px 0 5px;
            font-size: 24px;
            line-height: 19px;
            color: #1D2129;
            // display: flex;
            span{
                color: #406EFF;
                font-size: 24px;
                font-weight: 600;
            }
            .total-money-symbol{
                color: #406EFF;
                font-size: 18px;
            }
            .money-symbol{
                font-size: 18px;
                font-weight: 500;
                color: #1D2129;
            }
         
        }
        .payment-prcieDetail-text {
            width: fit-content;
            margin-top: 20px;
            border-radius: 10px;
            line-height: 36px;
            padding: 0 11px;
            color: #7598C4;
            font-size: 16px;
            background: linear-gradient(273deg, #DEECFF 0%, #F2F8FF 99%);
        }
        .arco-statistic-content .arco-statistic-value{color: #406EFF;}
        .arco-statistic-content .arco-statistic-value-int {
            font-size: 44px;
        }
        .monthly-settlement{
            margin-right: 19px;
            span{
                margin-left:4px;
                margin-right: 6px;
            }
            .arco-icon{
                font-size: 18px;
                color: #406EFF;
                margin-left: 19px;
                margin-top: 4px;
                cursor: pointer;
                vertical-align: sub;
            }
        }
        .btn-Recharge {
            width: 117px;
            height: 42px;
            background: #406eff;
            border-radius: 8px;
            margin-left: 31px;
        }

        .Link-btn {
            margin-left: 29px;
            .arco-link{
                color: #1D2129;
                font-size: 16px;
            }
            .arco-link-disabled{
                color: #ccc;
            }
            .arco-space-item {
                margin-right: 0px !important;
            }
        }

        .arco-statistic-value-prefix,.arco-statistic-value-suffix {
            font-size: 28px;
        }
    }
    .custom-White-button{
        width: 118px;
        height: 45px;
        border-radius: 14px;
    }
    .arco-icon{
        stroke-linecap: round; 
        stroke-linejoin: round;
    }
    .loadingList{
        padding:30px 10px;
        padding-bottom: 0px;
        margin-bottom: 20px;
        background-color: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        box-sizing: border-box;
        .btn-Recharge{
            width: 117px;
            height: 42px;
            background: #406eff;
            border-radius: 8px;
            margin-left: 31px;
            margin: 0 auto;
        }
    }
    .arco-list-wrapper{
        width: 100%;
        min-height: 179px;
    }
    .arco-list-content{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .arco-list-item{
            // width: 147px;
            width: 287px;
            height: 165px;
            margin: 0 15px;
            margin-bottom: 10px;
            padding:0 !important;
        }
        .arco-list-header, .arco-list-item:not(:last-child){
            border:0;
        }
        .arco-list-scroll-loading{
            width: 100%;
            margin: 0;
            height: 0;
        }
    }
    .Emptydata{
        .arco-typography{
        color: #4E5969;
        font-size: 14px;
        margin-top: 17px;
     }
    }
    .engine-resources{
        width: 100%;
        height: 165px;
        .resources{
            position: relative;
            width: 100%;
            min-height: 144px;
            padding-top: 18px;
            padding-bottom: 0;
            border-radius: 22px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #00D097;
            color: #1D2129;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
            cursor: pointer;
            &:hover{
                background-image: var(--gradientcolor);
                .upgrade{
                    opacity: 1;
                    height:41px;
                    transition: height 0.3s linear,opacity 0.3s 0.1s linear;
                }
            }
            .arco-statistic-content .arco-statistic-value,.arco-statistic-content .arco-statistic-value-int, .arco-statistic-value-prefix, .arco-statistic-value-decimal{
                font-size: 22px;
            }
            .arco-statistic-content .arco-statistic-value-suffix{font-size: 12px;}
            .resources-box{
                padding: 0 16px;
                box-sizing: border-box;
            }
            .resources-title{
                font-size: 18px;
                margin-bottom: 14px;
            }
            .arco-statistic-extra{
                margin-top: 0;
                color: #4E5969;
                font-size: 12px;
            }
            .resources-type{
                font-size: 12px;
                color: #5887D0;
                .resources-icon{
                    height: 50px;
                    img{
                        height: 100%;
                    }
                }
            }
            .resources-group{
                color: var(--color);
            }
            .upgrade{
                opacity: 0;
                width: 100%;
                text-align: center;
                color: #4E5969;
                font-size: 12px;
                height: 0px;
                line-height: 41px;
                transition: opacity 0.1s linear,height 0.4s linear;
                .arco-icon{
                    margin-left: 12px;
                }
                span{
                    padding: 2px;
                    border-radius: 2px;
                }
                span:hover{
                    color: var(--color);
                }
                span:active{
                    background: rgba(196, 203, 214, 0.10);
                }
            }
        }
    }
    .arco-list-item:has(.chappt-resources){
        // height: 200px;
    }
    .engine-resources.chappt-resources{
            // height: 200px;
        .bluemodel{
            position: absolute;
            top: 0;
            width: 100%;
            background-color: #406EFF;
            height: 35px;
            line-height: 35px;
            border: 1px solid #406EFF;
            border-radius: 20px 20px 0 0;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
        }
        .resources-box{
            display: flex;
            height: 100px;
            padding-top: 30px;
            justify-content: space-between;
        }
        .upgrade{
            background: rgba(240, 244, 255,0.25)
        }
       
    }
    .payment-explain{
        width: 100%;
        height: auto;
        border-radius: 14px;
        font-size: 12px;
        background: #EFF6FF;
        color: #5785BC;
        padding: 16px;
        line-height: 24px;
        box-sizing: border-box;
        margin-bottom: 26px;
        margin-top: 5px;
        .payment-explain-lable{
            color: #414551;
            margin-bottom: 6px;
            .arco-icon{
                color: #FFB83E;
                margin-right: 8px;
                stroke-linecap: round; 
                stroke-linejoin: round;
            }
        }
    }
}
.custom-modal.renew-modal{
    width: 544px;
    .custom-box{
        text-align: center;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0px;
        color: #0A1629;
        h4{font-size: 20px;margin-bottom: 39px;}
        span{
            font-size: 16px;
        }
    }
    .arco-modal-footer > .arco-btn{
        width: 102px;
    }
}
.payment-modal.arco-modal{
    width: 680px;
    .payment-explain{
        font-size: 16px;
        .payment-modal-detail ul{
            padding-left: 22px;
            li{
                padding: 5px 0;
            }
        }
    }
}

.commonList {
       .arco-list-wrapper,.arco-list{
        overflow: inherit;
       }
        .arco-list-content {
            display: flex;
            gap: 20px;
            border-radius: 22px;
            margin: 0 20px;
            padding: 0px 30px 10px;
            overflow: inherit;
            justify-content: flex-start;
            .arco-list-item{
                width: auto;
                height: auto;
                min-width: 32rem;
                overflow: inherit;
            }
            .arco-list-item-main{
                overflow: inherit;
            }
            .services-package-box {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-width: 280px;
                position: relative;
                background-color: #FFFFFF;
                border-radius: 22px;
                overflow: hidden;
                transition: transform .4s linear,-webkit-transform .4s linear;
                &:hover{
                    transform: translateY(-15px);
                }
            }
            .TEXT-box{
                border: 1px solid #76BCE9;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
                .services-package-btn{
                    background-color: #76BCE9;
                    &:hover{
                        background-color: #60A5D1;
                    }
                }
                .tag{
                    background-color: #76BCE9;  
                }
                &:hover{
                    background: linear-gradient(180deg, #D3EDFD 0%, #FFFFFF 100%);   
                }
            }
            .FILE-box{
                border: 1px solid #B299E4;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
                .services-package-btn{
                    background-color: #B299E4;
                    &:hover{
                        background-color: #9F85D1;
                    }
                }
                .tag{
                    background-color: #B299E4;  
                }
                &:hover{
                    background: linear-gradient(180deg, #F1E9FF 0%, #FFFFFF 100%);  
                }
            }
            .IMAGE-box{
                border: 1px solid #F2A56C;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
                .services-package-btn{
                    background-color: #F2A56C;
                    &:hover{
                        background-color: #DE935D;
                    }
                }
                .tag{
                    background-color: #F2A56C;  
                }
                &:hover{
                    background: linear-gradient(180deg, #FFE7D6 7%, #FFFFFF 100%);   
                }
            }
            .tag-mode-container{
                font-size: 12px;
                height: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                z-index: 99;
                .tag{
                    color: #fff;
                    font-size: 12px;
                    height: 82px;
                    overflow: hidden;
                    -webkit-transform: translate(-80px,-32px) rotate(45deg);
                    transform: translate(-80px,-32px) rotate(45deg);
                    width: 140px;
                    span{
                        left: 55px;
                        position: absolute;
                        top:47px;
                        transform: rotate(-40deg);
                    }
                }
            }
            .services-package-item{
                display: flex;
                align-items: center;
                text-align: left;
                margin-left: 30px;
                margin-top: 35px;
                img{
                    width: 73px;
                    height: 73px;
                }
                .services-package-ul{
                    margin-left: 30px;
                    .services-package-name{
                        color: #3D3D3D;
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 22px;
                        margin-bottom: 10px;
                    }
                    .services-package-desicrt{
                        color: #767676;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 22px;
                    }
                }
            }
            .services-package-btn{
                margin: 25px 23px 23px 23px;
                height: 48px;
                line-height: 48px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                border-radius: 14px;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
                &:hover{
                    cursor: pointer;
                    color: #ffffff !important;
                }
            }

        }
    .commonList-empty{
        padding-bottom: 35px;
        display: flex;
        flex-direction: column;
    }
}
