.agreement {
  padding: 20px 20px 100px 20px;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  font-size: 14px;
  .agreement-box {
    width: 80%;
    margin: auto;
  }
  
  .editor-content-view p,
  .editor-content-view li {
    white-space: pre-wrap; /* 保留空格 */
  }
  
  .editor-content-view blockquote {
    border-left: 8px solid #d0e5f2;
    padding: 10px 10px;
    margin: 10px 0;
    background-color: #f1f1f1;
  }
  
  .editor-content-view code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
    border-radius: 3px;
  }
  .editor-content-view pre>code {
    display: block;
    padding: 10px;
  }
  
  .editor-content-view table {
    border-collapse: collapse;
    margin: 15px 0;
  }
  .editor-content-view td,
  .editor-content-view th {
    border: 1px solid #ccc;
    min-width: 50px;
    height: 20px;
  }
  .editor-content-view th {
    background-color: #f1f1f1;
  }
  
  .editor-content-view ul,
  .editor-content-view ol {
    padding-left: 20px;
  }
  
  .editor-content-view input[type="checkbox"] {
    margin-right: 5px;
  }
}
