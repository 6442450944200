.arco-menu-item {
  text-align: initial;
}

.arco-menu-light .arco-menu-inner .arco-menu-item .arco-icon,
.arco-menu-light .arco-menu-inner .arco-menu-group-title .arco-icon,
.arco-menu-light .arco-menu-inner .arco-menu-pop-header .arco-icon,
.arco-menu-light .arco-menu-inner .arco-menu-inline-header .arco-icon {
  color: #505B6B;
}

.arco-layout-sider-children {
  box-shadow: '0px 6px 58px 0px rgba(196,203,214,0.10)';
  border-radius: 0 22px 22px 0;
}

.arco-menu-item.arco-menu-item-indented .arco-menu-item-inner {
  color: #4e5969;
}

.arco-menu-item.arco-menu-selected.arco-menu-item-indented .arco-menu-item-inner a {
  color: #406EFF;
}

.arco-menu-light .arco-menu-item.arco-menu-selected a {
  color: #406EFF;
}
.arco-menu-light .arco-menu-item.arco-menu-selected{
  background-color: #F3F6FF !important;
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected:hover{
  background-color: #F3F6FF !important;
}
.arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected:hover,
.arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected:hover {
  background-color: #F3F6FF !important;
}
.arco-menu-light .arco-menu-item:hover,
.arco-menu-light .arco-menu-group-title:hover,
.arco-menu-light .arco-menu-pop-header:hover,
.arco-menu-light .arco-menu-inline-header:hover {
  background-color: #F3F6FF !important;
}
.identityVip{
  .arco-menu-item {
    text-align: initial;
  }

  .arco-menu-light .arco-menu-inner .arco-menu-item .arco-icon,
  .arco-menu-light .arco-menu-inner .arco-menu-group-title .arco-icon,
  .arco-menu-light .arco-menu-inner .arco-menu-pop-header .arco-icon,
  .arco-menu-light .arco-menu-inner .arco-menu-inline-header .arco-icon {
    color: #7C5A2F;
  }
  
  .arco-layout-sider-children {
    box-shadow: '0px 6px 58px 0px rgba(196,203,214,0.10)';
    border-radius: 0 22px 22px 0;
  }
  
  .arco-menu-item.arco-menu-item-indented .arco-menu-item-inner {
    color: #34302D;
  }
  .arco-menu-item.arco-menu-selected.arco-menu-item-indented .arco-menu-item-inner a {
    color: #7C5A2F;
    font-size: 14px;
    font-weight: bold;
  }
 
  .arco-menu-light .arco-menu-item.arco-menu-selected a ,.arco-menu-light .arco-menu-inline-header.arco-menu-selected>span{
    color: #7C5A2F;
    font-size: 14px;
    font-weight: bold;
  }
  .arco-menu-light .arco-menu-item.arco-menu-selected .arco-icon, .arco-menu-light .arco-menu-group-title.arco-menu-selected .arco-icon, .arco-menu-light .arco-menu-pop-header.arco-menu-selected .arco-icon, .arco-menu-light .arco-menu-inline-header.arco-menu-selected .arco-icon{
    color: #EABC83;
  }
  .arco-menu-light .arco-menu-item.arco-menu-selected{
    background-color: #FFF4E5 !important;
  }
  .arco-menu-light .arco-menu-inline-header.arco-menu-selected:hover{
    background-color: #FFF4E5 !important;
  }
  .arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected:hover {
    background-color: #FFF4E5 !important;
  }
  .arco-menu-light .arco-menu-item:hover,
  .arco-menu-light .arco-menu-group-title:hover,
  .arco-menu-light .arco-menu-pop-header:hover,
  .arco-menu-light .arco-menu-inline-header:hover {
    background-color: #FFF4E5 !important;
  }
}