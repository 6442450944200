.colorcard{
    width: 100%;
    height: auto;
    margin-top: 18px;
   
    .colorcard-header{
        &.blue{
            background: rgba(35,102,255,0.09);
            .colorcard-header-title{
                color: #165DFF;
            }
        }
        &.green{
            background: rgba(0,180,42,0.09);
            .colorcard-header-title{
                color: #00B42A;
            }
        }
        &.orange{
            background: rgba(255,125,0,0.09);
            .colorcard-header-title{
                color: #FF7D00;
            }
        }
    }
    .custom-card {
        width: 100%;
        margin-top: 20px;
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        padding: 11px 16px;
      }
    
      .custom-table {
        width: 100%;
        display: flex;
        justify-items: center;
        text-align: center;
        color: #0c0c0f;
    
        .custom-th,
        .custom-td {
          width: 200px;
        }
    
        .custom-th {
          font-size: 14px;
          padding: 8px;
        }
    
        .custom-td {
          font-size: 16px;
          padding: 8px;
        }
      }
    
    .colorcard-header-title{
        width: 100%;
        margin-bottom: 9px;
        font-size: 16px;
    }
    .colorcard-extra{
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .colorcard-body{
        width: 100%;
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196,203,214,0.10); 
        padding: 22px;
        color: #0c0c0f;
        margin-top: 32px;
        // height: 560px;
        overflow-y: auto;
        font-size: 14px;
        box-sizing: border-box;
        .colorcard-title{
            
            text-align: center;
            color: #0c0c0f;
            .colorcard-title-fix{
                position: relative;
                .checkboxEnd{
                    position: absolute;
                    right: 0;
                }
            }
        }
        .colorcard-li{
            width: 100%;
            border-radius: 2px;
            // padding: 25px 22px;
            box-sizing: border-box;
            margin-bottom: 34px;
            .colorcard-li-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #86909c;
                .colorcard-li-header-title{
                    flex: 1;
                }
                .colorcard-li-header-title,.colorcard-li-header-time{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .colorcard-li-body-cover{
                position: relative;
                padding: 20px 24px;
                padding-bottom: 26px;
                background: #f2f6ff;
                border-radius: 10px;
                color: #0c0c0f;
                margin-top: 8px;
                img{
                    width: 101px;
                    height: 100px;
                }
                .arco-btn{
                    border-radius: 20px;
                    background: transparent;
                }
            }
        }
        .mystry .colorcard-li-body-cover{
            background: #ffffff;
            border: 1px solid #e6ebf5;
            border-radius: 10px;
        }
    }
    .arco-empty .arco-empty-wrapper img{
        width: 192px;
        height: 95px;
    }
    .Empty-none{
        margin-top: 0;
    }
    .arco-avatar{
        width: 34px;
        height: 34px;
        margin-right: 12px;
    }
    .setreplies{
        position: absolute;
        right: 3px;
        bottom: 3px;
    }
}