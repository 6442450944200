.promotion{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: 20px;
    color: #1D2129;
    box-sizing: border-box;
    
    .addpadding{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .promotion-lable{
        width: 100%;
        min-height: 55px;
        border-radius: 14px;
        padding:5px 10px;
        background: var(--backgroundImage);
        box-sizing: border-box;
        margin-top: 18px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        box-sizing: border-box;
        img{
            width: auto;
            height: 54px;
            margin-right: 16px;
        }
        .promotion-title{
            font-size: 18px;
        }
        .promotion-surplus{
            font-size: 12px;
            color: #4E5969;
            margin-left: 28px;
            .surplus-lable{
                margin-right: 8px;
            }
        }
        .arco-statistic{
            line-height: 15px;
        }
        .arco-statistic-content .arco-statistic-value-int, .arco-statistic-value-prefix, .arco-statistic-value-decimal,.arco-statistic-content .arco-statistic-value{
            font-size: 24px;
        }
    }
    .promotion-list{
        width: 100%;
        .arco-list-item{
            padding: 13px 14px !important;
        }
        .arco-col{
            min-width: 524px;
        }
        .promotion-list-item{
            position: relative;
            padding: 23px;
            height: 135px;
            border-radius: 14px;
            background: #FFFFFF;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
            box-sizing: border-box;
            background-image: var(--backgroundImage);
            border: 1px solid transparent;
            transition: all 0.2s linear;
            &:hover{
                border-color: #406eff;
                transition: all 0.2s linear;
            }
            .promotion-item-icon{
                width: 82px;
                height: 82px;
                border-radius: 14px;
                background-color: #FFFFFF;
            }
            .promotion-item-sum{
                text-align: left;
                margin-left: 17px;
                div:nth-of-type(1){
                    font-size: 12px;
                    line-height: 22px;
                    color: #4E5969;
                }
                div:nth-of-type(2){
                    font-size: 12px;
                    line-height: 22px;
                    color: #0C0C0F;
                    margin-bottom: 5px;
                    margin-top: 6px;
                    .arco-statistic-content .arco-statistic-value-int,.arco-statistic-value-prefix,.arco-statistic-value-decimal {
                        color: #0C0C0F;
                        font-size: 24px;
                      }
                      .arco-statistic-value-suffix{font-size: 24px;margin-left: 3px;}
                      .arco-statistic{
                        line-height: 24px;
                    }
                }
                div:nth-of-type(3){
                    .arco-statistic-content .arco-statistic-value-int,.arco-statistic-value-prefix,.arco-statistic-value-decimal {
                        color: #406EFF;
                        font-size: 16px;
                      }
                      .arco-statistic-value-decimal{font-size: 16px;}
                      .arco-statistic{
                        line-height: 16px;
                    }
                }
            }
            .promotion-InputNumber{
                position: absolute;
                right: 19px;
                bottom: 9px;
                .arco-input-number{
                    border: 1px solid #77808C;
                    border-radius: 8px;
                    overflow: hidden;
                    color: #77808C;
                }
                .arco-input-group > :first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                .arco-input-number-step-button,.arco-input-inner-wrapper{
                    background-color: #FFFFFF;
                    
                }
                .arco-input-number-mode-button .arco-input-group-addafter .arco-input-number-step-button{
                    border-left-color: #77808C;
                }
                .arco-input-number-mode-button .arco-input-group-addbefore .arco-input-number-step-button{
                    border-right-color: #77808C;
                }
            }
        }
    }
    .payment-explain{
        margin-bottom: 33px;
        margin-top: 21px;
    }
    
    .promotion-bottom{
        font-size: 16px;
        .promotion-End{
            position: relative;
            right:41px;
            // bottom: 79px;
            margin: 40px 0;
            margin-bottom: 79px;
        }
        .arco-statistic-content .arco-statistic-value-int,.arco-statistic-value-prefix,.arco-statistic-value-decimal {
            color: #406EFF;
            font-size: 32px;
          }
          .arco-statistic-value-decimal{font-size: 22px;}
          .arco-statistic{
            line-height: 24px;
        }
          .settlement{
            width: 152px;
            height: 43px;
            border-radius: 7px;
            font-size: 14px;
            margin-left: 40px;
          }
          .arco-icon-wechat{
            color: #00C800;
            font-size: 24px;
            
        }
        .arco-icon-alipay-circle{
            font-size: 24px;
            color: #06B4FD;
            margin-right: 18px;
            margin-left: 12px;
        }
    }
   
    .promotion-payment{
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 17px;
    }

}
