$base-color: #0C0C0F;

.P-smarteditor {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0 30px;

  div.arco-typography,
  p.arco-typography {
    margin-bottom: 0px;
  }

  .Header-title {
    position: relative;
    color: $base-color;
    font-size: 16px;
    padding-left: 14px;
  }

  .Header-title::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 65%;
    background: #406eff;
    border-radius: 1px;
  }

  .Header-smarteditor {
    width: 100%;
    margin-bottom: 18px;
    text-align: left;

    .copyable-box {
      width: 285px;
      height: 45px;
      background: #e7ecf7;
      padding: 0 14px;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .copyable-input {
      width: 227px;
      height: 45px;
      background: transparent;
      padding: 0;
      cursor: pointer;
      border: 0;
      font-size: 14px;
      color: #186eff;
      text-decoration: underline;
    }

    .copyable-Button {
      color: #4E5969;
      margin-left: 14px;
      cursor: pointer;

      &:hover {
        color: #186eff;
      }

      &:active {
        color: #406EFF;
      }
    }
  }

  .meng {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    z-index: 99;
  }

  #iframeContent {
    position: relative;
    overflow: auto;
  }

  .Content-smarteditor {
    position: relative;
    height: 100%;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

    margin-right: 16px;
    text-align: left;
    padding: 0 31px;
    box-sizing: border-box;

    .title-text {
      padding: 20px 0;
      box-sizing: border-box;
      font-size: 16px;
      padding-bottom: 0;
    }
  }

  .ac-menu-wrapper {
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .rightSider {
    height: 100%;
    color: $base-color;
    font-size: 16px;
    text-align: left;
    padding: 14px;

    .arco-select-view {
      width: 300px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }

    .arco-input-inner-wrapper {
      width: 300px;
      height: 48px;
      background: #ffffff;
      border-color: #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }

    .arco-input-inner-wrapper-focus {

      border-color: rgb(22, 93, 255);

    }

    .Sider-Content {
      padding: 3px 16px;
      padding-right: 0;
    }

    .Sider-item {
      margin-bottom: 36px;
    }

    .Sider-title {
      margin-bottom: 13px;
      font-size: 14px;
    }

    .Sider-title-min {
      font-size: 12px;
      margin-bottom: 7px;
    }

    .Sider-Content-min {
      position: relative;
      margin-left: 18px;
    }

    .logoupload {
      width: 100px;
      height: 100px;
      margin-bottom: 6px;

      .arco-upload-trigger-picture {
        height: 100px;
        border: 1px solid #d8e0f0;
        border-radius: 6px;
        background-color: transparent;

        &:hover {
          border-color: rgb(22, 93, 255);
        }
      }

      .arco-upload-list-item-picture {
        width: auto;
        height: 100px;
        line-height: 100px;
        border: 1px solid #e5e6eb;
      }

      .arco-upload-list-item-picture-mask {
        line-height: 100px;
      }
    }

    .Sider-upload-tip {
      font-size: 12px;
      text-align: left;
      color: #86909c;
      margin-bottom: 18px;
    }

    .arco-select-size-default.arco-select-single .arco-select-view.arco-select-view {
      height: 41px;
      line-height: 39px;
    }

    .Dropdownbtn {
      color: #4e5969;
    }

    .service-allCheckArr {
      width: 300px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
      margin-bottom: 16px;
      padding: 0 13px;
      box-sizing: border-box;

      .arco-checkbox {
        width: 100%;
        height: 100%;
      }

      .arco-checkbox-mask-wrapper {
        top: 0;
      }

      .arco-checkbox {
        display: flex;
        align-items: center;
      }

      .arco-checkbox-text {
        font-size: 12px;
        width: 280px;
        padding: 0px 6px;
      }

      .arco-image {
        vertical-align: top;
      }

      .Uploadservice {
        .arco-upload-trigger {
          height: 100%;
        }
      }

      .arco-dropdown-menu {
        padding: 7px;
        background: #ffffff;
        border: 1px solid #d7dfef;
        border-radius: 14px;
        box-shadow: 0px 1px 2px 0px rgba(196, 203, 214, 0.30);

        .arco-icon {
          margin-left: 0;
        }
      }

      .arco-dropdown-menu-item,
      .arco-dropdown-menu-pop-header {
        border-radius: 8px;
        padding: 0 5px;
      }
    }

  }

  .css-1yn2e29-InputColor {
    width: 232px;
    height: 41px;
    background: #f2f3f5;
    border: 1px solid #e5e6eb;
    border-radius: 4px;
    padding: 8px;
  }

  .css-1vpurlb-InputColor {
    border-radius: 2px;
  }

  .css-1j0mc3w-ColorPicker {
    color: #4E5969;

    input {
      background: #f2f3f5;
      border: 1px solid #E5E6EB;
      border-radius: 2px;
      color: #4E5969;
    }
  }
}

.tihuanbtn {
  width: auto;
  height: 14px;
  padding: 0 !important;
  color: #4e5969 !important;
  background: transparent;
  line-height: 28px;

  &:active {
    background: transparent !important;
  }
}