/* 清零样式 */

* {
  margin: 0;
  padding: 0;
  /*防止点击闪烁*/
  -webkit-tap-highlight-color: transparent;
  /*缩放网页，文字大小不变*/
  -webkit-text-size-adjust: none;
  // font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  font-family: "AlibabaPuHuiTi-Regular";
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-weight: normal;
  // scrollbar-color: #d3dbeb rgba(0, 0, 0, 0.1);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  // scrollbar-width: thin;
  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  -ms-overflow-style: none;
}

/*整个滚动条*/
::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  background-color: transparent;
}

/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: transparent;
}
/*定义滑块*/
::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  background-color: transparent;

}
*:hover::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #E5E6EB;
}
.story-scroll {
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    border: 4px solid #d6d6d6;
    border-radius: 4px;
    background-color: #d6d6d6;
  }
}
/* 设置iconfont标签font-family */
[class^="icon-"],
[class*=" icon-"] {
  font-family: "iconfont";
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*去除虚线*/
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  text-decoration: none;
  /*ios禁止页面在新窗口打开*/
  -webkit-touch-callout: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}


/*修复img默认位置和IE边框*/
img {
  vertical-align: top;
  border: none;
}


/*重置部分表单圆角和内阴影*/
input[type="text"],
input[type="file"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="url"],
textarea {
  -webkit-appearance: none;
}

body {
  background-color: #f5f5f5;
}

.common-con-top {
  margin-top: 70px;
}

.arco-btn {
  border-radius: 8px;
}

.arco-btn-primary {
  box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
}

.arco-btn-secondary {
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}

.arco-divider-horizontal {
  border-bottom: 1px solid #e6ebf5 !important;
}

.arco-modal-mask {
  background-color: rgba(33, 85, 163, 0.16) !important;
}

.arco-card {
  border-radius: 22px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}