.knowledgereasoning{
    .evaluate-input{
        position: relative;
        width: 100%;
        min-height: 216px;
        border-radius: 10px;
        background: #FFFFFF;
        padding: 11px 17px;
        font-size: 14px;
        box-sizing: border-box;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        .arco-divider-horizontal{
            margin: 10px 0;
        }
        .evaluate-text{
            position: relative;
            width: 100%;
            height: 130px;
            .arco-textarea{
                width: 100%;
                height: 100%;
                resize: none;
                background-color: transparent;
                border-radius: 10px;
            }  
        }
        .evaluate-tags{
          padding-right: 100px;
          height: 45px;
          overflow: auto;
          box-sizing: border-box;
            text-align: left;
            .arco-tag{
                border-color: transparent;
                color: var(--color);
                background-color: var(--bgcolor);
                .arco-tag-close-btn{
                   width: 0;
                   opacity: 0;
                   margin-left:0px;
                   transition: margin-left 0.4s linear;
                }
                
            }
            .arco-tag-hide{
                display: inline-flex;
            }
            .arco-tag:hover{
                border-color: var(--color);
                
                .arco-tag-close-btn{
                    width: auto;
                    opacity: 1;
                    margin-left:4px;
                    transition: all 0.4s linear;
                }
            }
        }
        .Start-generation{
            position: absolute;
            right: 22px;
            bottom: 10px;
            border-radius: 10px;
        }
        margin-bottom: 23px;
    }
    .Start-generation{
        border-radius: 10px;
        height: 45px;
        width: 82px;
    }
    .Marketing-input{
        width: 100%;
        margin-bottom: 20px;
        .Marketing-Select{
            position: relative;
            width: 220px;
            .versionsSelect {
                .arco-select-view {
                  position: relative;
                  padding-left: 60px;
                  .arco-select-view-value {
                    text-align: left;
                  }
                }
                .arco-select-view::before {
                  position: absolute;
                  left: 11px;
                  top: 50%;
                  transform: translateY(-50%);
                  content: '';
                  color: #4e5969;
                }
                &.brand{
                    .arco-select-view::before {
                        content: '\54c1\724c';
                      }
                  }
                .arco-select-view::after {
                  position: absolute;
                  height: 16px;
                  left: 47px;
                  top: 50%;
                  transform: translateY(-50%);
                  content: '';
                  border-left: 1px solid #cdd3df;
                }
              }
              .arco-select-size-default.arco-select-single .arco-select-view {
                width: 100%;
                height: 45px;
                background: #ffffff;
                border-radius: 14px;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
                line-height: 43px;
                text-align: center;
              }
              .arco-input-inner-wrapper{
                width: 220px;
                height: 45px;
                border-radius: 14px;
                background: #FFFFFF;
              }
              .arco-input-group-prefix{
                position: relative;
              }
              .arco-input-group-prefix::before {
                position: absolute;
                content: '';
                height: 16px;
                right: -9px;
                top: 50%;
                transform: translateY(-50%);
                border-left: 1px solid #cdd3df;
              }
              .arco-input-inner-wrapper-has-prefix > .arco-input-clear-wrapper .arco-input, .arco-input-inner-wrapper-has-prefix > .arco-input{
                padding-left: 22px;
              }
        }
    }
  .evaluate-layout-content {
    height: calc(100% - 255px);
    display: flex !important;
    flex-wrap: wrap;
    .continuation-body {
        position: relative;
        width: 100%;
        margin-top: 17px;
        overflow: auto;
    
        .lh {
          cursor: pointer;
          color: #406eff;
          text-decoration: none !important;
        }
        
      }    
    .experience-result {
      position: relative;
      width: calc(100% - 595px);
      background: #ffffff;
      padding: 0;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      text-align: left;
      border-radius: 22px;
      &.kg-result {
        width: 570px;
        margin-right: 22px;

        position: relative;

        .span {
          font-size: 16px;
          position: absolute;
          font-weight: 400;
          line-height: 20px;
          left: 0;
          top: -30px;
        }

        svg {
          text {
            font-size: 10px;
            pointer-events: none;
          }

          .edgeText {
            font-size: 12px;
          }
        }

        .kg-instruct {
          display: none;
          position: absolute;
          bottom: 31px;
          right: 23px;
          z-index: 2;
          padding: 0;
          border: 0.5px solid #cdcdcd;
          border-radius: 4px;
          backdrop-filter: blur(8px);
          max-height: 90%;
          overflow: auto;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 25px;
            // cursor: initial;
            padding: 0 10px;

            transition: background-color 0.5s, opacity 0.5s;

            &:first-of-type {
              border-radius: 4px 4px 0 0;
            }

            &:last-of-type {
              border-radius: 0 0 4px 4px;
            }

            i {
              display: inline-block;
              width: 11px;
              height: 11px;
              border-radius: 50%;
              border: 1px solid transparent;
              margin-right: 8px;
            }

            svg {
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-right: 5px;
              animation: load360 0.8s linear infinite;
            }

            b {
              display: inline-block;
              color: #0c0c0f;
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }

      &.kg-jd-list {
        .span {
          font-size: 16px;
          position: relative;
          font-weight: 400;
          line-height: 20px;
          left: 0;
          top: -30px;
        }

        .result-list {
          overflow-y: auto;
          text-align: left;
          background-color: #fff;

          li {
            list-style: none;
            width: 45.5%;
            height: 40rem;
            min-height: 300px;
            display: inline-block;
            overflow-y: auto;
            padding: 15px;
            margin: 0 2% 34px 3%;
            border: 1px solid transparent;
            border-radius: 4px;
            color: #0c0c0f;
            line-height: 20px;
            font-size: 14px;
            text-align: left;
            background: #fbfbfb;

            &::-webkit-scrollbar {
              width: 5px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-clip: border-box;
              background-color: #dee4ed;
            }

            &:nth-child(n) {
              margin-right: 0;
            }

            &:hover {
              background-color: #f2f7ff;
            }

            h3 {
              display: flex;
              justify-content: space-between;

              .job-name {
                position: relative;
                font-size: 16px;
                padding-right: 26px;

                a {
                  display: inline-block;
                }

                i {
                  position: absolute;
                  right: 0;
                  display: inline-block;
                  background-image: url("../../asserts/icon_look.svg");
                  background-size: cover;
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                }
              }

              .job-salary {
                font-size: 14px;
                text-align: right;
              }
            }

            h4 {
              display: flex;
              justify-content: space-between;
              line-height: 30px;

              .job-company {}

              .job-city {
                text-align: right;
              }
            }

            h5 {
              font-size: 14px;
            }

            .desc {
              padding: 10px 0;

              a {
                text-decoration: underline;
                margin: 0 2px;
              }
            }

            hr {
              width: 95%;
              height: 1px;
              background-color: #c9c9c9;
              border: none;
              margin: 10px auto;
            }

            .more {
              display: none;

              .tags-a {
                margin: 1rem auto;

                a {
                  display: inline-block;
                  border: 1px solid #0c0c0f;
                  background-color: #fff;
                  border-radius: 4px;
                  line-height: 30px;
                  padding: 0 10px;
                  text-align: center;
                  min-width: 62px;
                  margin-right: 5px;
                  margin-bottom: 5px;
                }
              }
            }

            &.active {
              border-color: #406eff;
              background-color: #f2f7ff;

              h3 .job-name i {
                display: none;
              }
            }
          }
        }
      }

      .arco-result-icon-tip {
        width: 90px;
        height: 90px;
        font-size: 30px;
        line-height: 95px;
      }

      .result_title {
        background-color: transparent;
        margin: 15px 0 0 21px;
        padding-right: 19px;
      }

      .arco-result .arco-result-title {
        margin-bottom: 10px;
      }

      .Empty-error.arco-empty .arco-empty-wrapper img {
        height: 113px;
      }

      .Empty-none.arco-empty .arco-empty-wrapper img {
        height: 113px;
      }
    }
    .result_title {
        background-color: transparent;
        margin: 15px 0 0 21px;
        padding-right: 19px;
        font-size: 16px;
    }
      .Continuation-result {
        padding: 0 30px;
        padding-bottom: 108px;
        box-sizing: border-box;
        .Continuation-list {
          width: 100%;
          text-align: left;
  
          .Continuation-item {
            width: 100%;
            color: #0c0c0f;
            height: auto;
            background: #fbfbfb;
            border: 1px solid #fbfbfb;
            border-radius: 8px;
            padding: 19px 25px;
            font-size: 14px;
            white-space: pre-line;
            margin-bottom: 20px;
  
            &:hover {
              background: #f2f7ff;
              border: 1px solid #406eff;
            }
  
            .item-title {
              font-size: 16px;
              color: #0a1629;
            }
  
            .Preamble {
              color: #406EFF;
            }
          }
        }
      
      }
      .arco-btn.IconSync-btn {
        position: absolute;
        right: 24px;
        bottom: 28px;
        width: 166px;
        height: 48px;
        background: #ffffff;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        color: #0b172a;
        margin-top: 33px;
        border: 1px solid #d8e0f0;
        border-radius: 10px;
      }
  }
}