// 创建
.dataset-add {
  .cardform {
    position: relative;
    margin-top: 20px;
    overflow:auto;
    .arco-card-body {
      padding: 41px 31px;
    }

    .startParagraph {
      text-align: center;
      font-size: 24px;
      color: #0a1629;
      margin-top: 4.8rem;
      margin-bottom: 6.4rem;
    }
  }

  .forminput {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #4E5969;
    margin-bottom: 60px;
    overflow: auto;

    .arco-space {
      text-align: left;
    }

    .formtitle {
      margin-bottom: 11px;
      padding-left: 0px;
    }

    .arco-textarea,
    .inputtext {
      width: 498px;
      height: 48px;
      padding: 8px 11px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }

    .arco-select.select-item .arco-select-view {
      width: 498px;
      height: 48px;
      padding: 8px 11px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }
  }

  .addData {
    margin-top: 0;
    .arco-space-item{
      margin-bottom: 0px !important;
    }
    .TipsData {
      width: 465px;
      background: #f6faff;
      border-radius: 14px;
      padding: 13px;
      font-size: 14px;
    }

    .TipsData-link {
      padding-left: 20px;
      margin-top: 8px;
      .arco-link {
      font-size: 14px;
      text-decoration:underline;
    }
    }

    
    .fie_img{
      margin-top: 11px;
    }
  }

  .arco-divider-horizontal {
    margin: 41px 0;
  }

  .arco-icon {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .dataset-btns {
    width: 100%;
    text-align: center;

    .dataset-btn {
      width: 145px;
      height: 48px;
      border-radius: 14px;
      &.colse {
        margin-right: 26px
      }
    }

  }
}


.arco-card {
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}