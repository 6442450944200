.paymentAmount{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 22px;
  background: #FFFFFF;
  text-align: center;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  .payment_watermark{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events:none;
  }
  .paymentAmount-box{
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  .paymentAmount-desc{
    color: #0A1629;
    font-size: 20px;
    line-height: 28px;
  .paymentAmount-id{
    margin-right: 37px;
  }
  .paymentAmount-money{
    color: #406EFF;
  }
  }
  .paymentAmount-QR-box{
    // position: relative;
    width: 231px;
    padding: 12px 15px;
    border-radius: 14px;
    background: #406EFF;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
    margin: auto; 
    margin-top: 43px;
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  // .paymentAmount-QR-watermark{
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url('/asserts/watermark_img.png');
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   transform: translate(-50%,-50%);
  // }
  .paymentAmount-title{
    text-align: left;
    margin-top: 14px;
  }
  .paymentAmount-QR-time{
    font-size: 12px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .paymentAmount-QR{
    position: relative;
    width: 203px;
    height: 198px;
    margin: auto;
    box-sizing: border-box;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .arco-icon-wechat{
    color: #00C800;
    font-size: 30px;
    
}
  .arco-icon-alipay-circle{
      font-size: 30px;
      color: #06B4FD;
      margin-left: 18px;
  }
  .paymentAmount-lable{
    font-size: 12px;
    line-height: 24px;
    color: #767676;
    margin-top: 21px;
    margin-bottom: 43px;
  }
  .paymentAmount-cancel{
    width: 152px;
    height: 43px;
    border-radius: 7px;
    background: #406EFF;
  }
  .arco-result-icon{
    font-size: 60px;
    margin-bottom: 45px;
  } 
  .arco-icon{
    stroke-linecap: round; 
    stroke-linejoin: round;
}
  .arco-result-icon-tip{
    width: 140px;
    height: 140px;
    line-height:154px;
  }
  .arco-result-title{
    font-size: 22px;
    line-height: 31px;
    color: #0C0C0F;
    margin-bottom: 16px;
  }
  .arco-result-subtitle{
    font-size: 16px;
    line-height: 22px;
    color: #4F5969;
  }
  .arco-result-extra{
    margin-top: 123px;
    .arco-btn{
      width: 152px;
      height: 43px;
      border-radius: 7px;
    }
  }
}
}
.arco-modal.custom-modal.canceloder{
  .arco-modal-content{
      text-align: right;
      .custom-box{
          text-align: center;
          font-size: 20px;
          line-height: 28px;
          color: #0A1629;
          div:first-of-type{
              margin-bottom: 13px;
          }
      }
      
  }
  .arco-modal-footer{
    display: flex;
    flex-direction:row-reverse;
    .arco-btn.arco-btn-primary{
      color: #406eff;
      font-size: 16px;
      background: #e7eeff;
      box-shadow:none;

      &:hover {
        color: #406eff;
        background: #eef3ff;
      }

      &:active {
        color: #2759e0;
        background: #e6ecfa;
      }
    }
    .arco-btn.arco-btn-secondary{
      color: #ffffff;
      font-size: 16px;
      background: #406eff;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    
      &:hover {
        color: #ffffff;
        background: #4080ff;
      }
    
      &:active {
        color: #ffffff;
        background: #0e42d2;
      }
    }
  }
}