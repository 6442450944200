.account-recharge{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    color: #1D2129;
    box-sizing: border-box;
    .arco-spin-loading{
        position: absolute;
    }
    .recharge-box{
        position: relative;
        width: 100%;
        min-height: calc(100% - 100px);
        margin-top: 17px;
        border-radius: 22px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        padding: 30px 35px 55px;
        box-sizing: border-box;
        .custom-radio.arco-radio-disabled .custom-radio-title{
            color: #C9CDD4;
        }
    }
    .addpadding{
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .promotion-lable{
        width: 364px;
        height: 55px;
        border-radius: 14px;
        padding:0 21px;
        background: var(--backgroundImage);
        box-sizing: border-box;
        border: 1px solid #00D097;
        margin-top: 18px;
        
        box-sizing: border-box;
        .promotion-title{
            font-size: 20px;
        }
        .promotion-surplus{
            font-size: 12px;
            color: #4E5969;
            .surplus-lable{
                margin-right: 7px;
            }
        }
        .arco-statistic{
            line-height: 20px;
        }
    }
    .payment-explain{
        margin-bottom: 33px;
        margin-top: 21px;
    }
    .from-table{
        color: #4E5969;
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        .from-item{
            margin-bottom: 35px;
            .from-item-lable{
                width: 80px;
                margin-right: 5px;
            }
            .from-item-value{
                color: #0A1629;
            }
            .from-item-nuit{
                margin-left: 3px;
            }
            .arco-input{
                color: #406EFF;
                font-size: 16px;
                font-family: "AlibabaPuHuiTi-Medium";
            }
            .arco-input::-webkit-input-placeholder {
                font-family: "AlibabaPuHuiTi-Regular";
              }
        }
        
    }
    .order-hr{
        height: 1px;
        background-color: #E6EBF5;
        margin: 25px 0;
    }
    .confirm-recharge{
        position: absolute;
        right: 49px;
        bottom: 39px;
        width: 152px;
        height: 43px;
        border-radius: 7px;
    }
    
    .custom-input .arco-input-inner-wrapper{
        width: 215px;
        height: 48px;
        border-radius: 14px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-color: #D8E0F0;
        box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }
    .custom-input .arco-input-inner-wrapper:focus,
        .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
            border-color: #406EFF;
            background-color: transparent;
        }
    .arco-radio-group .arco-radio{
        margin-bottom: 0px;
        margin-right: 29px;
    }
    .recharge-tip{
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #DF4E4E;
    }
     .serviceQRcode {
        width: 193px;
        height: 192px;
        background-image: url('/asserts/serviceQRcode.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 25px;
    }
    .promotion-payment{
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 17px;
    }
    @media screen and (max-height: 800px) {
        .confirm-recharge {
            bottom: 15px;
        }
    }
}
