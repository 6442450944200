.callDetails {
    .GroupTable-fixe {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 31px;
    
        .versionsSelect {
          margin-right: 18px;
    
          .arco-select-view {
            position: relative;
            padding-left: 60px;
    
            .arco-select-view-value {
              text-align: left;
            }
          }
    
          .arco-select-view::before {
            position: absolute;
            left: 11px;
            top: 50%;
            transform: translateY(-50%);
            content: '\5e94\7528';
            color: #4e5969;
          }
    
          .arco-select-view::after {
            position: absolute;
            height: 16px;
            left: 47px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            border-left: 1px solid #cdd3df;
          }
        }
    
        .arco-select-size-default.arco-select-single .arco-select-view {
          width: 100%;
          height: 45px;
          background: #ffffff;
          border-radius: 14px;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
          line-height: 45px;
          text-align: center;
        }
        .arco-btn-icon-only {
          width: 45px;
          height: 45px;
          background: #ffffff;
          border-radius: 14px;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
          color: #4E5969;
        }
    
        .Header-init-btn {
          width: 118px;
          height: 45px;
          // background: #406eff;
          border-radius: 10px;
          box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        }
    
        .primary-white {
          width: 100px;
          height: 45px;
          background: #ffffff;
          border-radius: 14px;
          box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
          color: #0b172a;
        }
    
        .GroupTable-Search {
          .arco-input-inner-wrapper {
            height: 46px;
            background: #ffffff;
            border-radius: 14px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
          }
        }
    
        .GroupTable {
          width: auto;
          height: 45px;
          background: #f2f6ff;
          border-radius: 24px;
          overflow: hidden;
    
          .arco-radio-button {
            width: 111px;
            height: 38px;
            line-height: 38px;
            color: #1d2129;
            font-size: 14px;
            text-align: center;
          }
    
          .arco-radio-button:hover {
            border-radius: 24px;
          }
    
          .arco-radio-button::after {
            background: #f2f6ff;
          }
    
          .arco-radio-button:not(:first-of-type)::before {
            background: transparent;
            color: transparent;
          }
    
          .arco-radio-button.arco-radio-checked {
            width: 111px;
            height: 38px;
            background: #406eff;
            border-radius: 20px;
            color: #ffffff;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
          }
        }
        
      }
      .DatePicker-RangePicker {
        position: relative;
        .RangePicker-box {
          position: absolute;
          width: 560px;
          margin-top: 10px;
          z-index: 99;
    
          .arco-btn {
            border-radius: 4px;
          }
        }
      }
      .arco-picker-container,
      .arco-picker-range-container {
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
        border: 0;
      }
      .table-card{
        border-radius: 22px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        overflow: hidden;
        .table-tabs{
            width: 100%;
            height: 48px;
            background: #F2F6FF;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0px;
            color: #0C0C0F;
            padding: 13px 24px;
            padding-bottom: 0;
            box-sizing: border-box;
            .table-lable{
                word-break:keep-all;       /* 不换行 */
                white-space:nowrap;       /* 不换行 */
                margin-right: 24px;
                cursor: pointer;
                
                .tabs-title{
                    padding: 0 1px;
                    border-radius: 5px;
                }
                .table-hr{
                    width: 100%;
                    height: 3px;
                    border-radius: 5px;
                    margin-top: 7px;
                }
                &:hover{
                    .tabs-title{
                       color: #3F8CFF;
                    }
                }
            &.active{
                color: #416FFF;
                .table-hr{
                    background: #416FFF;
                    transition: all 0.3s linear;
                }
            }
        }
        }
      }
      .arco-table-th-item{
          padding: 27px 26px;
      }
    .arco-pagination-list{
      display: none;
    }
  }