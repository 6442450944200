
.echnicalsupport-details{.arco-table-cell{
    width: 100%;
}
.arco-table-body{
    min-height: 44rem !important;
  }
  .arco-table-no-data{
    width: 100% !important;
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }}
  .arco-modal.replyworder{
    width: 559px;
    height: 600px;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 6px 58px 0px rgba(121,145,173,0.20); 
    .arco-form{
      margin-top: 20px;
    }
    .arco-modal-content{
      padding:33px 40px;
    }
    .arco-modal-footer,.arco-modal-header{
      border: none !important;
    }
    .arco-modal-footer,.arco-modal-content{
        padding-top: 0 !important;
    }
    .arco-modal-footer .arco-btn{
      width: 98px;
      height: 48px;
      border-radius: 14px;
    }
    .arco-modal-header{
      padding: 0 28px;
      height: 80px;
    }
    .arco-modal-title{
      text-align: left;
      font-size: 22px;
    }
    .arco-modal-close-icon {
      width: 44px;
      height: 44px;
      background: #f5f8ff;
      font-size: 18px;
      color: #0a1629;
      border-radius: 14px;
      line-height: 46px;
      text-align: center;
    }
    .arco-icon-hover::before {
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }
    .arco-textarea{
      height: 159px;
      background-color: #ffffff;
      border-color:#d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184,200,224,0.22); 
      resize: none;
    }
  
    .arco-select .arco-select-view{
      height: 48px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184,200,224,0.22); 
    }
    .arco-input{
      height: 48px;
      background-color: #ffffff;
      border-color:#d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184,200,224,0.22); 
    }
    .arco-input:focus,
    .arco-input.arco-input-focus {
      border-color: #406EFF;
    }
    .arco-textarea:focus,
    .arco-textarea.arco-textarea-focus {
      border-color: #406EFF;
    }
    .arco-select.arco-select-focused .arco-select-view {
      border-color: #406EFF;
    }
    .arco-form-item{
      margin-left: 12px;
    }
    .Required.arco-form-item{
      margin-left: 0px;
    }
    .Required .arco-form-item-wrapper{
      margin-left: 12px;
    }
    .arco-upload-list-item-picture-mask{
      line-height: 28px;
      background: rgba(0,0,0,0);
      .arco-upload-list-item-picture-operation{
        position: relative;
        top: 68%;
        height: 32%;
        background: rgba(0,0,0,0.7);
      }
    }
    .arco-upload-list-preview-icon{
      display: none;
    }
    .arco-upload-list-remove-icon{
      margin-left: 0 !important;
    }
  }