.question {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }
  }

  .Continuation-input {
    position: relative;
    border: 1px solid #e6ebf5;
    border-radius: 14px;

    .arco-textarea {
      width: 100%;
      height: 153px;
      line-height: 20px;
      resize: none;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      padding: 19px 16px;
    }

    .loding-text {
      position: absolute;
      top: -52px;
      color: #414551;
      font-size: 14px;
      padding: 8px 22px;
      background: #e3f3ff;
      text-align: center;
      border-radius: 8px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .dot-ani {
      display: inline-block;
      height: 12px;
      line-height: 12px;
      overflow: hidden;
    }

    .dot-ani::after {
      display: inline-table;
      white-space: pre;
      content: "\A.\A..\A...\A....\A.....\A......";
      animation: spin 2s steps(7) infinite;
    }
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 22px;
    padding: 28px;
    padding-top: 24px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    margin-top: 24px;

    .question-title {
      .arco-typography {
        margin-top: 0;
        margin-bottom: 0;
      }

      .question-remarks {
        color: #8f959f;
        font-size: 12px;
      }
    }

    .arco-divider {
      margin: 22px 0;
    }

    .AC-frame {
      position: relative;
      width: 100%;
      // height: 550px;
      padding: 15px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 12px;

      .arco-avatar {
        background: transparent;
      }

      .Preamble {
        color: #0c0c0f;
        font-size: 16px;
      }

      .user-left,
      .Xiaoyou {
        .head-portrait {
          width: 60px;
          height: auto;
          // border-radius: 50%;
        }

        .information-name {
          font-size: 20px;
          color: #0c0c0f;
        }

        .information-time {
          font-size: 14px;
          color: #7b7b7b;
          margin-left: 8px;
        }

        .custom-Steam-drum {
          position: relative;
        }

        .Steam-drum {
          position: relative;
          width: auto;
          max-width: 852px;
          height: auto;
          background: #e3f3ff;
          margin-top: 16px;
          padding: 14px 22px;
          font-size: 16px;
          border-radius: 4px;
          text-align: justify;
          color: #0c0c0f;
        }

      }

      .AC-frame-item {
        margin-top: 84px;
        animation: identifier 0.2s ease-out;
      }

      .AC-frame-item:first-of-type {
        margin-top: 0;
      }

      .Xiaoyou {
        .information-content {
          margin-left: 20px;
        }

        .Steam-drum:before {
          /*伪元素必须添加content*/
          content: "";
          width: 0;
          height: 0;
          /*IE6下，height:0;不顶用，可使用font-size:0; + overflow:hidden;修复此问题*/
          font-size: 0;
          overflow: hidden;
          display: block;
          border-width: 10px;
          border-right-width: 30px;
          border-color: transparent #e3f3ff transparent transparent;
          /*为了兼容IE6，所有设置为透明（transparent）的边，需要设置为dashed；有颜色的边设置为solid*/
          border-style: solid dashed dashed dashed;
          border-bottom-left-radius: 2px;
          position: absolute;
          /*绝对定位不占位置*/
          top: 9px;
          /* 向下移动 矩形的高度 + 矩形的上下内边距 + 下边框粗细*/
          left: -30px;
          /*相对于矩形宽度的位置*/
        }
      }

      .user-left {
        .information-content {
          margin-right: 40px;
        }

        .custom-Steam-drum {
          text-align: right;
          z-index: 1;
        }

        .Steam-drum {
          background: #ffffff;
          border: 1px solid #c9c9c9;
          margin-top: 0px;
        }

        .Steam-drum:before {
          /*伪元素必须添加content*/
          content: "";
          width: 0;
          height: 0;
          /*IE6下，height:0;不顶用，可使用font-size:0; + overflow:hidden;修复此问题*/
          font-size: 0;
          overflow: hidden;
          display: block;
          border-width: 10px;
          border-left-width: 30px;
          border-color: transparent transparent transparent #C9C9C9;
          /*为了兼容IE6，所有设置为透明（transparent）的边，需要设置为dashed；有颜色的边设置为solid*/
          border-style: solid dashed dashed dashed;
          border-bottom-left-radius: 2px;
          position: absolute;
          /*绝对定位不占位置*/
          top: 9px;
          /* 向下移动 矩形的高度 + 矩形的上下内边距 + 下边框粗细*/
          right: -33px;
          /*相对于矩形宽度的位置*/
          z-index: -1;
        }

        .Steam-drum::after {
          content: "";
          width: 0;
          height: 0;
          /*IE6下，height:0;不顶用，可使用font-size:0; + overflow:hidden;修复此问题*/
          font-size: 0;
          overflow: hidden;
          display: block;
          border-width: 10px;
          border-left-width: 30px;
          border-color: transparent transparent transparent #ffffff;
          /*为了兼容IE6，所有设置为透明（transparent）的边，需要设置为dashed；有颜色的边设置为solid*/
          border-style: solid dashed dashed dashed;
          border-bottom-left-radius: 2px;
          position: absolute;
          /*绝对定位不占位置*/
          top: 9px;
          /* 向下移动 矩形的高度 + 矩形的上下内边距 + 下边框粗细*/
          right: -30px;
          z-index: 1;
          /*相对于矩形宽度的位置*/
        }
      }
    }


  }

  @keyframes identifier {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes spin {
    to {
      -webkit-transform: translateY(-84px);
      transform: translateY(-84px)
    }
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}