.appdataset {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 31px;

    .versionsSelect {
      margin-right: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }
    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      // background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .primary-white {
      width: 100px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
    }

    .GroupTable-Search {
      .arco-input-inner-wrapper {
        height: 46px;
        background: #ffffff;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      }
    }

    .GroupTable {
      width: auto;
      height: 45px;
      background: #f2f6ff;
      border-radius: 24px;
      overflow: hidden;

      .arco-radio-button {
        width: 111px;
        height: 38px;
        line-height: 38px;
        color: #1d2129;
        font-size: 14px;
        text-align: center;
      }

      .arco-radio-button:hover {
        border-radius: 24px;
      }

      .arco-radio-button::after {
        background: #f2f6ff;
      }

      .arco-radio-button:not(:first-of-type)::before {
        background: transparent;
        color: transparent;
      }

      .arco-radio-button.arco-radio-checked {
        width: 111px;
        height: 38px;
        background: #406eff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }
    }
    
  }

  .DatePicker-RangePicker {
    position: relative;

    .RangePicker-box {
      position: absolute;
      width: 560px;
      margin-top: 10px;
      z-index: 99;

      .arco-btn {
        border-radius: 4px;
      }
    }

  }

  .arco-picker-container,
  .arco-picker-range-container {
    border-radius: 14px;
    box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
    border: 0;
  }

  .appdataset-card {
    width: 100%;
    height: 800px;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    padding: 10px 9px;
    box-sizing: border-box;

    .appdataset-card-title {
      padding: 0 46px;
      box-sizing: border-box;
    }

    .appdataset-Echarts {
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;

      .linecharts {
        width: 100%;
        height: 550px;
      }
    }

    .line-lable {
      margin-top: 27px;
      font-size: 14px;
      color: #0c0c0f;

      .blue,
      .cyan {
        width: 33px;
        height: 11px;
        border-radius: 20px;
      }

      .blue {
        background: #406eff;
      }

      .cyan {
        background: #44ccbf;
      }
    }

    .line-lable>.arco-space-item {
      margin-right: 39px !important;
    }
  }
}