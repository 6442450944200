// 创建
.addmodel {
  position: relative;
  width: 100%;
  opacity: 0;

  .arco-layout-content {
    height: 100%;
  }

  .cardform {
    position: relative;
    height: 100%;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

    .arco-card-body {
      padding: 41px 31px;
    }

    .startParagraph {
      text-align: center;
      font-size: 24px;
      color: #0a1629;
      padding-top: 48px;
      padding-bottom: 64px;
      margin-bottom: 0;
    }
  }

  .forminput {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #4E5969;
    margin-bottom: 15px;
    padding: 0 100px;
    overflow: auto;

    .arco-space {
      text-align: left;
    }

    .formtitle {
      margin-bottom: 11px;
    }

    .arco-textarea,
    .inputtext {
      padding: 8px 11px;
      background: #ffffff;
      border: 1px solid #d8e0f0;
      border-radius: 14px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    }

    .arco-input-inner-wrapper:focus,
    .arco-input-inner-wrapper.arco-input-inner-wrapper-focus,
    .arco-textarea:focus,
    .arco-textarea.arco-textarea-focus {
      border-color: #406EFF;
    }
  }

  .select-foot {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 31px;
    padding-top: 0;
    text-align: left;
  }

  .selsect-fix-label {
    margin-bottom: 12px;
  }

  .selsect-fix-margin {
    margin-top: 26px;
  }

  .Model-selsect-fix {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // padding-left: 18px;
    text-align: left;

    .Model-selsect-item {
      width: 166px;
      padding: 13px 0;
      font-size: 16px;
      color: #20242f;
      border: 1px solid #d8e0f0;
      border-radius: 10px;
      box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
      text-align: center;
      box-sizing: border-box;
      margin-top: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    .Model-type {
      margin-right: 40px;
    }

    .Model-selsect-item:nth-child(3n+3) {
      margin-right: 0px;
    }

    .Model-selsect-item-hover {
      background: #406eff;
      color: #ffffff;
    }

    .Model-selsect-item-tag {
      position: relative;
    }

    .Model-selsect-item-tag::before {
      position: absolute;
      content: '\5b98\65b9\6a21\578b';
      width: 64px;
      height: 22px;
      line-height: 20px;
      background: linear-gradient(90deg, #fff0c1, #f6d493);
      border-radius: 4px;
      color: #ba8928;
      font-size: 12px;
      text-align: center;
      top: -12px;
      right: -24px;
    }


  }

  .listloadMore {
    position: relative;
    // max-height: 360px;
    // overflow: auto;
  }


  .addData {
    margin-top: 0px;
    margin-bottom: 32px;

    .arco-space-item {
      margin-bottom: 0px !important;
    }

    .arco-upload {
      padding-left: 18px;
      margin-bottom: 16px;
    }

    .upload-Link {
      padding-left: 18px;
      margin-bottom: 13px;

      .arco-link {
        font-size: 16px;
        padding-left: 0;
      }
    }

    .TipsData {
      width: 465px;
      background: #f6faff;
      border-radius: 14px;
      padding: 13px;
      font-size: 14px;
      margin-left: 18px;
    }

    .TipsData-link {
      padding-left: 20px;
      margin-top: 8px;

      .arco-link {
        font-size: 14px;
        text-decoration: underline;
      }
    }


    .fie_img {
      margin-top: 11px;
    }
  }

  .arco-divider-horizontal {
    margin: 30px 0;
    margin-top: 0;
  }

  .arco-icon {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .Prev {
    font-size: 16px;
    width: 132px;
    height: 48px;
    color: #406eff;
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .Next {
    width: 145px;
    height: 48px;
    font-size: 16px;
    background: #406eff;
    border-radius: 14px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }

  .cardstep {
    width: 370px !important;
    height: 100%;
    padding: 0 45px;
    padding-right: 23px;
    color: #ffffff;
    margin-left: 33px;

    .mode-Typography {
      text-align: center;
      // margin-top: 164px;

      .text-gradient {
        background-image: linear-gradient(90deg, #2099ff, #33e6ff 48%, #d573ff);
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 40px;
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    .arco-card-body {
      padding: 0;
    }

    .arco-typography {
      font-size: 16px;
      color: #ffffff;
    }

    .Step-model {
      .arco-steps-item-tail {
        margin-left: 36px;
      }
    }

    h1.arco-typography {
      margin-bottom: 5px;
      margin-top: 0;
      font-size: 34px;
      color: #ffffff;
    }

    h5.arco-typography {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 18px;
      color: #ffffff;
    }

    .Space-large {
      margin-bottom: 60px;
    }

    .arco-steps-item-finish .arco-steps-item-title {
      font-size: 18px;
      color: #ffffff;
    }

    .arco-steps-item-active .arco-steps-item-title {
      color: #ffffff !important;
    }

    .arco-steps-item-process .arco-steps-item-title,
    .arco-steps-item-wait .arco-steps-item-title {
      color: rgba(255, 255, 255, 0.30);
      font-size: 18px;
    }

    .arco-steps-vertical .arco-steps-item:not(:last-child) .arco-steps-item-tail {
      left: 12px;
    }

    .arco-steps-icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }

    .arco-steps-item:not(:last-child).arco-steps-item-finish .arco-steps-item-tail::after {
      background: #ffffff;
    }

    .arco-steps-item-finish .arco-steps-icon {
      background: #ffffff;
    }

    .arco-steps-item-process .arco-steps-icon {
      color: transparent;
      background: rgba(255, 255, 255, 0.30);
      border: 2px solid #ffffff;
    }

    .arco-steps-item-wait .arco-steps-icon {
      opacity: 0.5;
      color: transparent;
      background: transparent;
      border: 2px solid #ffffff;
    }

    .arco-steps-vertical .arco-steps-item:not(:last-child) {
      min-height: 66px;
    }

    .architecture_top_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 217px;
      background: url('/asserts/architecture_top_img.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: -77px;
    }

    .Health_coverages {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 185px;
      height: 158px;
      background: url('/asserts/architecture_img.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: 42px;
      background-position-x: 47px;
    }
  }
  .arco-radio-group .arco-radio {
    margin-bottom: 0;
    margin:0 15px;
    margin-bottom: 20px;
  }
  .custom-radio-card-title{
    width: 104px;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .select-foot-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .arco-select-size-default.arco-select-single .arco-select-view{
    width: 166px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    border-radius: 10px;
  }
  .arco-select .arco-select-view{
    border: 1px solid #D8E0F0;
    background: #FFFFFF;
  }
}

.arco-layout.addmodel-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.arco-card {
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
}