.progress {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .progress-desc {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 24px;
    color: #472B00;
  }
  .progress-bar {
    transition: stroke-dashoffset 1s ease;
    // animation: rotate 1s linear both;
  }
  @keyframes rotate {
    from {
        stroke-dashoffset: var('stroke-dashoffset');
    }
    to {
        stroke-dashoffset: 0;
    }
}