.personal {
  padding: 0 32px;

  .skew-x {
    cursor: pointer;
  }

  .personal-card-title {
    font-size: 16px;
    text-align: left;
    color: #0a1629;
    margin-bottom: 20px;
  }

  .personal-card-table {
    width: 100%;
    text-align: center;
    .billing-table{
      border-top: 0;
    }
  }

  .Account-authority {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    padding: 16px 25px;
    margin-top: 20px;
  }

  .Account-tag {
    display: inline-block;
    display: flex;
    align-items: center;
    padding: 3px 7px;
    background: #fffbef;
    border: 1px solid #dfd3b5;
    border-radius: 4px;
    font-size: 12px;
    color: #936500;
    margin-left: 13px;
    cursor: pointer;

    span {
      display: inline-block;
      width: 16px;
      height: 13px;
      background-image: url('/asserts/golden_vip_icon.svg');
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 8px;
    }

    &:hover {
      background: #fff4d5;
    }

    &:active {
      background: #feecb9;
    }
  }

  .Account-operation {
    color: #406EFF;
    cursor: pointer;
    padding: 2px 8px;
    font-size: 14px;
    border-radius: 2px;
    // transition: all 0.3s linear;

    &:hover {
      background-color: #f2f3f5;
    }

    &:active {
      background-color: #e5e6eb;
    }
  }

  .personal-order-card {
    position: relative;
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 16px 25px;
    border-radius: 22px;
    margin-top: 16px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .personal-card-header-extra {
    position: absolute;
    right: 23px;
    top: 23px;
    text-align: right;

    .arco-btn {
      width: 105px;
      height: 42px;
      border-radius: 10px;
      font-size: 16px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .custom-cancel-button {
      margin-right: 4px;
    }
  }

  .arco-input {
    height: 48px;
    background-color: #ffffff;
    border-color: #d8e0f0;
    border-radius: 14px;
    // box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  }

  .grid-gutter-demo {
    // width: calc(100% - 116px);
  }

  .personal-fix {
    flex-flow: nowrap;
    margin-top: 15px;
  }

  .arco-avatar {
    overflow: hidden;
  }

  .personal-avatar .arco-avatar-trigger-icon-mask {
    border-radius: 0;
    font-size: 12px;
    height: 35%;
    top: 65%;
    background: rgba(0, 0, 0, 0.70);
  }

  .personal-avatar .arco-upload-list-item {
    margin-top: 0;
    margin-right: 0;
    margin-left: 13px;
  }

  .grid-gutter-demo .arco-col {
    height: 48px;
    color: #0c0c0f;
    font-size: 14px !important;
    margin-bottom: 13px;
  }

  .grid-gutter-demo .arco-descriptions-row-label {
    text-align: left;
    color: #4e5969 !important;
    white-space: nowrap;

  }

  .arco-descriptions-item-value {
    width: 224px;
    padding: 0 !important;
  }

  .flex-auto .arco-descriptions-item-value {
    width: 100%;
    padding: 0 !important;
  }

  .grid-gutter-demo .arco-link {
    padding-left: 13px;
    padding-right: 13px;
  }

  .flex-auto .arco-descriptions-item-value .text-value-edit,
  .flex-auto .arco-descriptions-item-value .arco-input {
    width: 224px;
  }

  .text-value-edit {
    display: inline-block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: #f9fbff;
    border-radius: 14px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  }

  .text-value,
  .copyable-Paragraph {
    margin-bottom: 0 !important;
    padding-left: 13px;
    padding-right: 13px;
    white-space: nowrap;
  }

  .grid-gutter-demo .arco-col>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;

    .arco-typography-operation-copy {
      color: #406EFF;
    }
  }

  .arco-card-header {
    padding: 4px;
    padding-bottom: 16px;
    padding-top: 7px;
    margin-bottom: 16px;

    .arco-card-header-extra .arco-btn {
      font-size: 14px;
      color: #4e5969;
    }
  }

}

.arco-modal.Modal-ma {
  width: 472px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
  text-align: center;

  .arco-modal-footer,
  .arco-modal-header {
    border: none !important;
  }

  .arco-modal-footer,
  .arco-modal-content {
    padding-top: 0 !important;
  }

  .arco-modal-content {
    font-size: 20px;
  }

  .unboundstyle {
    width: 100%;
    padding: 50px 0;
    text-align: center;
    font-size: 20px;
  }

  .Modal-wxma {
    position: relative;
    width: 263px;
    height: 263px;
    text-align: center;
    margin: auto;

    .logowx {
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border: 5px solid #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .order_code_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    cursor: pointer;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.60);
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999;

    .freshicon {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  .arco-modal-header {
    padding: 0 28px;
    height: 80px;
  }

  .arco-modal-title {
    font-size: 22px;
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    font-size: 18px;
    color: #0a1629;
    border-radius: 14px;
    line-height: 46px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .arco-modal-footer .arco-btn {
    width: 98px;
    height: 48px;
    border-radius: 14px;
  }
}

.arco-modal.dissolution {
  height: auto;

  .arco-modal-content {
    height: auto;
    font-size: 20px;
    color: #0a1629;
    padding-bottom: 48px
  }
}

.dissolution .serviceQRcode {
  width: 247px;
  height: 244px;
  margin: auto;
  background-image: url('/asserts/serviceQRcode.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 35px;
}