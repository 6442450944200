.resources {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 31px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      // background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .primary-white {
      width: 100px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
    }
    .GroupTable-Searchbox{
      button{
        width: 118px;
        height: 45px;
        font-size: 14px;
        border-radius: 10px;
        border: transparent;
        cursor: pointer;
      }
      .custom-primary{
        background: #406EFF;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
        color: #FFFFFF;
        
        &:hover{
          background: #4080FF;
        }
        &:active{
          background: #0E42D2;
        }
      }
      .custom-White-button{
        width: 100px;
        height: 45px;
        border-radius: 14px;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        color: #0B172A;
        &:hover {
          color: #406eff;
          .arco-link-icon{
            color: #406eff;
          }
          .arco-typography {
            color: #406eff;
          }
        }

        &:active {
          color: #0e42d2;
          .arco-link-icon{
            color: #0e42d2;
          }
          .arco-typography {
            color: #0e42d2;
          }
        }

        &:disabled {
          pointer-events: none;
          color: #9da5b1;

          .arco-typography {
            color: #9da5b1;
          }
        }
      }
    }
    .GroupTable-Search {
      .arco-input-inner-wrapper {
        height: 46px;
        background: #ffffff;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      }
    }

    .GroupTable {
      width: auto;
      height: 45px;
      background: #f2f6ff;
      border-radius: 24px;
      overflow: hidden;

      .arco-radio-button {
        width: 111px;
        height: 38px;
        line-height: 38px;
        color: #1d2129;
        font-size: 14px;
        text-align: center;
      }

      .arco-radio-button:hover {
        border-radius: 24px;
      }

      .arco-radio-button::after {
        background: #f2f6ff;
      }

      .arco-radio-button:not(:first-of-type)::before {
        background: transparent;
        color: transparent;
      }

      .arco-radio-button.arco-radio-checked {
        width: 111px;
        height: 38px;
        background: #406eff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }
    }
  }

  .blue_vip_table {
    display: inline-block;
    width: 19px;
    height: 15px;
    background-image: url('/asserts/blue_vip_icon2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-left: 8px;
  }

  .arco-table-filters:hover,
  .arco-table-filters-open {
    background-color: transparent;
  }

  .outlineTable {
    border-radius: 8px;
  }
}