.membermanagement {
  position: relative;
  width: 100%;
  height: 100%;

  .SelectIdentity {
    height: 42px;
    line-height: 42px;
  }

  .arco-select-size-default.arco-select-single .arco-select-view {
    width: 135px;
    height: 42px;
    line-height: 42px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 14px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
    margin: auto;
  }

  .arco-divider-vertical {
    border-left: 5px solid #406EFF !important;
    margin-left: 0 !important;
  }

  .outline {
    width: 87px;
    height: 32px;
    border-radius: 8px;
  }

  .arco-empty .arco-empty-wrapper img {
    width: 129px;
    height: 113px;
  }

  .Empty-none {
    margin-top: 118px
  }

  .Emptydata-btns {
    text-align: center;
    margin-top: 24px;
  }

  .Emptydata-btn {
    width: 150px;
    height: 48px;
    background: #406eff;
    border-radius: 14px;
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
  }

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }
  }

  .GroupTable-Search {

    .arco-input-inner-wrapper {
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }


  .arco-progress-large .arco-progress-line-text {
    margin-left: 13px;
    color: #0c0c0f;
  }

  .table-button {
    button {
      padding: 0 2px;
    }
  }

  .arco-table-custom-filter {
    padding: 10px;
    background-color: var(--color-bg-5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  .multi {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.arco-modal.Invite-members,
.arco-modal.dissolution {
  width: 559px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-header {
    height: 80px;
    padding-left: 32px;
  }

  .arco-modal-header .arco-modal-title {
    font-size: 22px;
    color: #0a1629;
    text-align: left;
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #f5f8ff;
    border-radius: 14px;
    font-size: 22px;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .arco-input-inner-wrapper {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d8e0f0;
    border-radius: 14px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  }

  .arco-modal-content {
    height: auto;
    padding: 0px 48px;
    padding-right: 39px;
    padding-bottom: 48px;
    font-size: 14px;
    color: #4e5969;
    overflow: hidden;

    .item-label {
      margin-bottom: 11px;
    }

    .result-list {
      margin-top: 22px;
      transform: translateY(144px);
      height: 0;

      .item-result {
        width: 100%;
        height: 60px;
        background: #f4faff;
        border-radius: 14px;
        padding: 0 15px;

        &:hover {
          background: #d9ecff;
          cursor: pointer;
        }

        &:active {
          background: #cce0f5;
        }

      }

      .arco-avatar {
        background-color: #ffffff;
        overflow: hidden;
      }

      .arco-avatar-text {
        width: 100%;
        height: 100%;
        transform: scale(1) translateX(-50%) !important;
      }

      .result-avatar-image {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }

      .arco-link:hover {
        background-color: rgba(225, 225, 225, 0.2);
        color: #406EFF;
      }

      .arco-link:active {
        background-color: rgba(225, 225, 225, 0.3);
        color: #2759E0;
      }
    }

    .resultMsg {
      width: 100%;
      text-align: center;
      color: #8d93a1;
      font-size: 14px;
    }
  }

  .arco-input-inner-wrapper:focus,
  .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
    border-color: #406EFF;
  }

  .arco-modal-footer {
    padding-right: 39px;

    .arco-btn {
      width: 98px;
      height: 48px;
      border-radius: 14px;
    }

    .arco-btn.arco-btn-primary:not(.arco-btn-disabled) {
      width: 117px;
    }
  }
}

.deleteModal.Quit-team .arco-modal-footer {
  padding-top: 0 !important;
}

.arco-modal.dissolution {
  height: auto;

  .arco-modal-content {
    height: auto;
    font-size: 20px;
    color: #0a1629;
    padding-bottom: 48px
  }
}

.dissolution .serviceQRcode {
  width: 247px;
  height: 244px;
  margin: auto;
  background-image: url('/asserts/serviceQRcode.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 35px;
}

.move_up {
  -webkit-animation-name: move_up;
  animation-name: move_up;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.fadeInUp {
  -webkit-animation-name: move_fadeOut;
  animation-name: move_fadeOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move_up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes move_up {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: auto;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes move_fadeOut {
  from {
    opacity: 1;
    height: auto;
  }

  to {
    opacity: 0;
    height: 0;
  }
}

@keyframes move_fadeOut {
  from {
    opacity: 1;
    height: auto;
  }

  to {
    opacity: 0;
    height: 0;
  }
}