.engine-services {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    .addpadding{
        padding:0 20px;
        box-sizing: border-box;
        &.mag{
            margin-bottom: 13px;
        }
    }
    .engine-banner{
        position: relative;
        width: 100%;
        height: 153px;
        border-radius: 22px;
        opacity: 1;
        background: #43CAFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        margin-top: 15px;
        user-select: none;
        overflow: hidden;
        .engine-banner-text{
            position: relative;
            z-index: 1;
            h2{
                font-size: 28px;
                text-shadow: 0px 4px 10px 0px rgba(35, 178, 225, 0.4);
                line-height: 32px;
            }
            p{
                font-size: 14px;
                line-height: 24px;
                margin-top: 5px;
            }
        }
        .engine-banner-box{
            position: absolute;
            left: 298px;
            top: 144px;
            width: 352px;
            height: 144px;
            border-radius: 30%;
            opacity: 1;
            background: #FEFFC9;
            filter: blur(113px);
        }
        .engine-banner-box:nth-of-type(2){
            left: 967px;
            top: -117px;
            background: #AAE4FF;
        }
        .banner_img1{
            position: absolute;
            left: -190px;
            top: -120px;
            width: 186px;
            height: 186px;
            background-image: url('/asserts/engine_banner_img1.svg');
        }
        .banner_img2{
            position: absolute;
            right: -190px;
            top: -70px;
            width: 276px;
            height: 276px;
            background-image: url('/asserts/engine_banner_img2.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }
 
   .charging{
       margin-left: 14px;
   }
    .arco-icon{
        stroke-linecap: round; 
        stroke-linejoin: round;
    }
    .Emptydata{
        .arco-typography{
        color: #4E5969;
        font-size: 14px;
        margin-top: 17px;
     }
    }
    .services-package{
        display: flex;
        text-align: left;
        margin-top: 18px;
        width: 100%;
        min-height: 190px;
        border-radius: 22px;
        opacity: 1;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        user-select: none;
        .services-title{
            width: 198px;
            height: 144px;
            font-size: 22px;
            padding-left: 29px;
            color: #1D2129;
            // line-height: 55px;
            padding-top: 20px;
            border-radius: 14px;
            margin-top: 18px;
            background-image: var(--backgroundImage);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top center;
            box-sizing: border-box;
        }
        // .arco-list-wrapper{
        //     padding-left: 20px;
        // }
        // .arco-list-row{
        //     display: flex;
        //     .arco-list-row-col{
        //         width: 247px;
        //         flex: none;
        //     }
        // }
        // .arco-list-item{
        //     padding: 13px 0px !important;
        // }
        .services-package-ul{
            position: relative;
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            padding-bottom: 8px;
            padding-left: 23px;
            box-sizing: border-box;
            .arco-list-header, .arco-list-item:not(:last-child){
                border:0
            }
            .arco-list-item{
                width: 147px;
                margin: 0 18px;
            }
        }
        .services-package-ul::before{
            position: absolute;
            content: '';
            width: 1px;
            height: 80%;
            border-radius: 1px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #F1F1F1;
        }
    }
    .engine-resources{
        width: 100%;
        padding-bottom: 10px;
        padding-top: 26px;
        box-sizing: border-box;
        .resources{
            position: relative;
            width: 100%;
            min-height: 144px;
            padding-top: 18px;
            padding-bottom: 17px;
            border-radius: 22px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #D8D8D8;
            color: #1D2129;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
            cursor: pointer;
            transition: transform 0.4s linear;
           
            &:hover{
                background-image: var(--gradientcolor);
                .mantle-tag{
                    display: block;
                }
            }
            .resources-box{
                padding: 0 16px;
                box-sizing: border-box;
            }
            .resources-title{
                font-size: 18px;
                margin-bottom: 20px;
                .arco-tag-icon{
                    color: var(--tagColor);
                }
                .arco-tag{
                    color: var(--tagColor);
                    border-radius: 22px;
                    background: var(--bgColordefault);
                    background-image: var(--gradienticoncolor);
                }
            }
            .arco-statistic-content .arco-statistic-value-int, .arco-statistic-value-prefix, .arco-statistic-value-decimal,.arco-statistic-value{
                font-size: 22px;
            }
            .arco-statistic-content .arco-statistic-value-suffix{
                font-size: 12px;
            }
            .arco-statistic-extra{
                margin-top: 0;
                color: #4E5969;
                font-size: 14px;
            }
           
            .resources-type{
                font-size: 12px;
                .resources-icon{
                    width: 50px;
                    height: 50px;
                    background: var(--backgroundImage);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }
        .resources-mantles{
            position: absolute;
            left: 0px;
            top: -1px;
            width: calc(100% + 1px);
            height: calc(100% + 1px);
            box-sizing: border-box;
            border-radius: 22px;
            overflow: hidden;
            z-index: 99;
            .resources-mantle{
                position: absolute;
                left: -1px;
                top: 0px;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%);
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
                border-radius: 22px;
                z-index: 98;
            }
            .arco-tag{
                display: block;
                width: 113px;
                height: 41px;
                line-height: 40px;
                text-align: center;
                border-radius: 14px;
                background: var(--bgColordefault);
                color: #FFFFFF;
                box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
                &:hover{
                    background: var(--bgColorhover);
                }
                &:active{
                    background: var(--bgColor);
                }
            }
            .mantle-tag{
                display: none;
                position: absolute;
                left: 50%;
                bottom: 14px;
                transform: translateX(-50%);
                z-index: 99;
            }
        }
        // css实现角标
        .tag-mode-container{
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            z-index: 99;
            font-size: 12px;
            .tag{
                width: 105px;
                height: 92px;
                transform: translate(-50px,-50px) rotate(45deg);
                font-size: 12px;
                color: #FFFFFF; 
                overflow: hidden;
                span{
                    position: absolute;
                    top: 70px;
                    left: 36px;
                }
            }
        }
       
        &:hover{
            .resources{
             transform: translateY(-15px);
             transition: transform 0.4s linear;
           }
          
        }
    }
    .payment-explain{
        margin-bottom: 26px;
    }
}
.arco-modal.open-engine{
    width: 673px;
    border-radius: 24px;
    background: #FFFFFF;
    text-align: left;
    box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.2);
    text-align: center;
    
    .arco-modal-content{
        font-size: 16px;
        line-height: 28px;
        color: #0A1629;
        .custom-title{
            font-size: 20px;
            margin-bottom: 36px;
            span{
                color: #406EFF;
            }
        }
    }
    Button{
        margin-top: 45px;
        margin-bottom: 21px;
    }
}

.engine-services .addpadding.services-package-list {
    display: flex;
    gap: 20px;
    background-color: #FFFFFF;
    border-radius: 22px;
    margin: 0 20px;
    padding: 44px 30px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
    .services-package-box {
        max-width: 31.5%;
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        background-color: #FFFFFF;
        border-radius: 22px;
        overflow: hidden;
        transition: transform .4s linear,-webkit-transform .4s linear;
        &:hover{
            transform: translateY(-15px);
        }
    }
    .TEXT-box{
        border: 1px solid #76BCE9;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        .services-package-btn{
            background-color: #76BCE9;
            &:hover{
                background-color: #60A5D1;
            }
        }
        .tag{
            background-color: #76BCE9;  
        }
        &:hover{
            background: linear-gradient(180deg, #D3EDFD 0%, #FFFFFF 100%);   
        }
    }
    .FILE-box{
        border: 1px solid #B299E4;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        .services-package-btn{
            background-color: #B299E4;
            &:hover{
                background-color: #9F85D1;
            }
        }
        .tag{
            background-color: #B299E4;  
        }
        &:hover{
            background: linear-gradient(180deg, #F1E9FF 0%, #FFFFFF 100%);  
        }
    }
    .IMAGE-box{
        border: 1px solid #F2A56C;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        .services-package-btn{
            background-color: #F2A56C;
            &:hover{
                background-color: #DE935D;
            }
        }
        .tag{
            background-color: #F2A56C;  
        }
        &:hover{
            background: linear-gradient(180deg, #FFE7D6 7%, #FFFFFF 100%);   
        }
    }
    .tag-mode-container{
        font-size: 12px;
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        z-index: 99;
        .tag{
            color: #fff;
            font-size: 12px;
            height: 92px;
            overflow: hidden;
            -webkit-transform: translate(-80px,-32px) rotate(45deg);
            transform: translate(-80px,-32px) rotate(45deg);
            width: 140px;
            span{
                left: 49px;
                position: absolute;
                top:49px;
                transform: rotate(-40deg);
            }
        }
    }
    .services-package-item{
        display: flex;
        align-items: center;
        text-align: left;
        margin-left: 5rem;
        margin-top: 35px;
        img{
            width: 11rem;
            max-width: 103px;
            height: 11rem;
            max-height: 103px;
        }
        .services-package-ul{
            margin-left: 30px;
            .services-package-name{
                color: #3D3D3D;
                font-size: 22px;
                font-weight: 500;
                line-height: 22px;
                margin-bottom: 10px;
            }
            .services-package-desicrt{
                color: #767676;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
            }
        }
    }
    .services-package-btn{
        margin: 25px 23px 23px 23px;
        height: 52px;
        line-height: 52px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        &:hover{
            cursor: pointer;
            color: #ffffff;
        }
    }
    // .services-package-btn.services-package-btn-play{
    //     cursor: auto;
    //     color: #9DA5B1;
    //     background-color: #F2F3F5;
    //     &:hover{
    //         cursor: auto;
    //         color: #9DA5B1;
    //         background-color: #F2F3F5;
    //     }
    // }
}
