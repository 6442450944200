.image2text-index {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    margin-top: 14px;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

  }

  .semanticprototype-Layout {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    .arco-layout-sider-light.Sider-left {
      width: 409px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-right: 3.3rem;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 1.4rem 1.4rem 2rem 1.4rem;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-select-view {
          background: #ffffff;
          border: 1px solid #e6ebf5;
          border-radius: 10px;
          color: #8d93a1;
          height: 50px;
          width: 100%;

          .arco-select-view-value {
            line-height: 50px;
            color: #0b172a;
          }
        }

        .jobDesc-content {
          margin-top: 10px;

          .arco-textarea {
            background: #ffffff;
            resize: none;
            border-radius: 14px;
          }
        }
      }
    }

    .semanticprototype-title {
      font-size: 16px;
      color: #0a1629;
    }

    .Upload-pictures-bottom {
      width: 100%;
      text-align: right;
      padding-right: 12px;
      margin-top: 20px;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }

  .continuation-body {
    position: relative;
    width: 100%;
    margin-top: 17px;
    overflow: auto;
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 14px;
    padding: 15px 26px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    text-align: left;

    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      padding-left: 7px;
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      margin-top: 17px;
      overflow: auto;

      .Continuation-list {
        position: relative;
        width: 100%;
        height: auto;
        text-align: left;

        .Continuation-item {
          position: absolute;
          width: 162px;
          width: var(--width);
          background: #ffffff;
          border: 1px solid #d8e0f0;
          border-radius: 6px;
          box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
          cursor: pointer;
          overflow: hidden;
          opacity: 0;
          transition: all .5s;

          &:hover:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: rgba(0, 0, 0, 0.10);
            border-radius: 6px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            z-index: 2;
          }

          iframe {
            border: none;
            width: 1125px;
            transform-origin: 0 0;
          }
        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 16.6rem;
      height: 4.8rem;
      background: #ffffff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      margin-top: 2rem;
      border: 1px solid #d8e0f0;
      border-radius: 1rem;
    }

    .Spin-loding {
      margin-bottom: 2rem;
    }
  }

  .Continuation-Empty,
  .Continuation-loding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .newcontent-item{
    margin-bottom: 20px;
    .job-title{
      font-size: 14px;
      color: #969696;
    }
    .arco-upload{
      margin-top: 11px;
    }
    .trigger,.upload-demo-list{
      width: 75px;
      height: 75px;
      border: 1px dashed #D8E0F0;
      color: rgb(78, 89, 105);
      text-align: center;
      font-size: 14px;
    }
    .arco-upload-list-item-picture{
      width: 75px;
      height: 75px;
      border: 0;
      border-radius: 0;
    }
    .arco-upload-list-item{
      margin: 0;
      padding: 0;
    }
    .upload-demo-list{
      position: relative;
      // margin-top: 11px;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        margin: auto;
      }
      &:hover{
        .arco-upload-mask{
          opacity: 1;
        }
      }
      .arco-upload-mask{
        // opacity: 0;
        position: absolute;
        width: 100%;
        height: 23px;
        background: rgba(0, 0, 0, 0.6);
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        bottom: 0;
        line-height: 73px;
        line-height: 23px;
      }
      .file-Delete {
        position: relative;
        width: 100%;
        color: #ffffff;

        &:hover {
          color: #406EFF;
          cursor: pointer;
        }

        &:active {
          color: #0E42D2;
        }
      }
    }
  }
  .new-input.arco-input-inner-wrapper{
    width: 100%;
    background: transparent;
    border:0;
    border-bottom: 1px solid #D8E0F0;
    margin-top: 10px;
    padding: 0;
    padding-bottom: 7px;
  }
  .arco-input-inner-wrapper:focus,
  .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
    border-color: #406EFF;
  }
}


.image2text-modal-content {
  width: 396px !important;

  .arco-btn.download-btn {
    height: 44px;
    border-radius: 10px;
  }


  .arco-modal-content {
    padding: 0;
  }

  .image2text-modal {
    min-height: auto;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    overflow: hidden;
    text-align: center;

    .image2text-img {
      margin: 20px;
      margin-top: 0;
      border-radius: 6px;
      padding-top: 20px;
      max-height: 80vh;
      overflow-y: auto;

      img {
        width: 353px !important;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10)
      }
    }

    .image2text-opera {
      width: 100%;
      height: 86px;

      button {
        margin-top: 10px;
      }
    }
  }

  .arco-modal-close-icon {
    top: 0 !important;
    right: -60px !important;
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    font-size: 18px;
    color: #0a1629;
    border-radius: 14px;
    line-height: 46px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}