#breadcrumb_id{
    background: transparent;
    text-align: left;
    .arco-card-body{
        padding: 0;
    }
    .arco-breadcrumb-item-separator{
        color: #0C0C0F;
        margin: 0;
    }
    .arco-breadcrumb-item{
        color: #0C0C0F;
    }
    .arco-breadcrumb-item>a{
        color: #4e5969;
    }
}