.table-Select.arco-select .arco-select-view {
  height: 42px !important;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  color: #0c0c0f;
  font-size: 16px !important;
  line-height: 40px !important;
}


.model-mangement {
  position: relative;
  height: 100%;

  .Work-order-card {
    overflow: hidden;
  }

  .custom-card {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    padding: 11px 16px;
  }

  .custom-table {
    width: 100%;
    display: flex;
    justify-items: center;
    text-align: center;
    color: #0c0c0f;

    .custom-th,
    .custom-td {
      width: 200px;
    }

    .custom-th {
      font-size: 14px;
      padding: 8px;
    }

    .custom-td {
      font-size: 16px;
      padding: 8px;
    }
  }

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      // background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .primary-white {
      width: 100px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
    }

  }

  .GroupTable-Search {

    .arco-input-inner-wrapper {
      height: 46px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }

  .GroupTable {
    width: auto;
    height: 45px;
    background: #f2f6ff;
    border-radius: 24px;
    overflow: hidden;

    .arco-radio-button {
      min-width: 111px;
      height: 38px;
      line-height: 38px;
      color: #1d2129;
      font-size: 14px;
      text-align: center;
    }

    .arco-radio-button:hover {
      border-radius: 24px;
    }

    .arco-radio-button::after {
      background: #f2f6ff;
    }

    .arco-radio-button:not(:first-of-type)::before {
      background: transparent;
      color: transparent;
    }

    .arco-radio-button.arco-radio-checked {
      min-width: 111px;
      height: 38px;
      background: #406eff;
      border-radius: 20px;
      color: #ffffff;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
    }
  }

  .Work-order-card {
    margin-top: 32px;
    padding-bottom: 23px;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
  }

  .arco-divider-vertical {
    border-left: 5px solid #406EFF !important;
    margin-left: 0 !important;
  }

  .arco-progress-large .arco-progress-line-text {
    margin-left: 13px;
    color: #0c0c0f;
  }

  .table-button {
    button {
      width: 87px;
      height: 32px;
      border-radius: 8px !important;
    }
  }

  .arco-table-custom-filter {
    padding: 10px;
    background-color: var(--color-bg-5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  .multi {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}


.Model {
  position: relative;
  height: 100%;

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #4E5969;
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }
  }

  .GroupTable-Search {

    .arco-input-inner-wrapper {
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }
  }
  .table-card{
    margin-top: 33px;
    border-radius: 22px;
    background: #FFFFFF;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    overflow: hidden;
    .table-tabs{
        width: 100%;
        height: 48px;
        background: #F2F6FF;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #0C0C0F;
        padding: 13px 24px;
        padding-bottom: 0;
        box-sizing: border-box;
        .table-lable{
            word-break:keep-all;       /* 不换行 */
            white-space:nowrap;       /* 不换行 */
            margin-right: 24px;
            cursor: pointer;
            
            .tabs-title{
                padding: 0 1px;
                border-radius: 5px;
            }
            .table-hr{
                width: 100%;
                height: 3px;
                border-radius: 5px;
                margin-top: 7px;
            }
            &:hover{
                .tabs-title{
                   color: #3F8CFF;
                }
            }
        &.active{
            color: #416FFF;
            .table-hr{
                background: #416FFF;
                transition: all 0.3s linear;
            }
        }
    }
    }
  }
  .GroupTable {
    width: auto;
    height: 45px;
    background: #f2f6ff;
    border-radius: 24px;
    overflow: hidden;

    .arco-radio-button {
      min-width: 111px;
      height: 38px;
      line-height: 38px;
      color: #1d2129;
      font-size: 14px;
      text-align: center;
    }

    .arco-radio-button:hover {
      border-radius: 24px;
    }

    .arco-radio-button::after {
      background: #f2f6ff;
    }

    .arco-radio-button:not(:first-of-type)::before {
      background: transparent;
      color: transparent;
    }

    .arco-radio-button.arco-radio-checked {
      height: 38px;
      background: #406eff;
      border-radius: 20px;
      color: #ffffff;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
    }
  }

  .arco-empty .arco-empty-wrapper img {
    width: 128px;
    height: 113px;
  }

  .Emptydata {
    font-size: 16px;
    text-align: center;
    margin-top: 23px;

    .arco-typography {
      color: #4E5969;
    }
  }

  .arco-alert {
    position: relative;
    padding: 0;

    .arco-alert-close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .alertimg {
    width: 100%;
  }

  .myapplist-footBox {
    position: relative;
    width: 100%;
    // top: 0;
    // left: 0;
    // bottom: 23px;
    margin-top: 10px;
    z-index: 99;

    .circleloding.arco-btn-size-default.arco-btn-shape-circle {
      position: absolute;
      right: -20px;
      top: 145px;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50% !important;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.53);
    }

    .circleloding.left {
      left: -20px;
    }

    .myapplist-foot {
      width: 100%;
      height: 73px;
      background: #f2f6ff;
      border-radius: 10px;
      text-align: center;
      transition: height 0.3s linear;
      overflow: hidden;

      &.Down-open {
        height: 296px;
        transition: height 0.3s linear;
      }

      .swiper-list {
        position: relative;
        width: 100%;
      }

      .swiper-wrap {
        position: relative;
        width: 100%;
        white-space: nowrap;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 29px;
        overflow-y: hidden;
        overflow-x: scroll;
      }

      .swiper-item {
        width: 387px;
        height: 186px;
        background: #ffffff;
        border-radius: 22px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        margin-left: 34px;
        padding: 30px;
        box-sizing: border-box;
        white-space: nowrap;
        display: inline-block;
        text-align: left;

        .state-fix {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .arco-tag {
            border-radius: 4px;
          }
        }

        .swiper-Title {
          margin: 0 !important;
        }

        .swiper-label {
          text-align: left;
          margin-top: 8px;
          margin-bottom: 21px;
        }

        .swiper-myadd {
          width: 128px;
          height: 44px;
          border-radius: 14px !important;
        }
      }

 
      .Down-UP {
        position: absolute;
        right: 13px;
        top: 13px;
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #e6ecfa;
        border-radius: 14px;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }




  .Tooltiphid {
    max-width: calc(100% - 300px) !important;
    max-height: 200px;
    overflow-y: scroll;
  }

  .arco-modal.addModal {
    width: 656px;
  }

  .outline {
    width: 87px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
  }
}

// 单选组件样式
.arco-modal.addModal {
  width: 676px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-header {
    padding: 0px 36px;
    height: 80px;
  }

  .arco-modal-title {
    font-size: 22px;
    text-align: left;
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    border-radius: 14px;
    text-align: center;
    line-height: 44px;
  }

  .arco-icon-hover::before {
    width: 44px;
    height: 44px;
    border-radius: 14px;
  }

  .Modaladd-title {
    font-size: 20px;
    margin-bottom: 21px;
    margin-top: 20px;
  }

  .arco-modal-content {
    padding: 0px 36px;
  }

  .arco-radio-group .arco-radio {
    margin-right: 27px;
    margin-bottom: 27px;
  }

  .arco-radio-group .arco-radio:nth-child(3n+3) {
    margin-right: 0;
  }

  .arco-spin {
    padding: 0px !important;
  }

  .arco-radio-text {
    margin-left: 0 !important;
  }

  .arco-radio {
    padding-left: 0 !important;
  }

  .custom-radio-card {
    padding: 13px 8px;
    border: 1px solid #D8E0F0;
    border-radius: 4px;
    width: 183px;
    height: 78px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);

    .custom-radio-tag-disabled {
      position: absolute;
      top: 0;
      right: -1px;
      width: 51px;
      height: 19px;
      background-image: url('/asserts/developing.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .custom-radio-card-mask {
    height: 22px;
    width: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--color-border-2);
    box-sizing: border-box;
  }

  .custom-radio-card-mask-dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }

  .custom-radio-card-title {
    width: 135px;
    font-size: 16px;
    margin-top: 0px !important;
    margin-left: 0px;

  }

  .arco-typography-secondary {
    width: 135px;
    margin-bottom: 0px !important;
    margin-top: 5px;
    font-size: 12px;
  }

  .custom-Group .arco-radio-disabled .custom-radio-card {
    pointer-events: none;
    background: #fafafa;
    border-color: #e5e6eb;
    color: #a5a5a5;

    .arco-typography {
      color: #a5a5a5;
    }
  }

  .custom-radio-card:hover,
  .custom-radio-card-checked,
  .custom-radio-card:hover .custom-radio-card-mask,
  .custom-radio-card-checked .custom-radio-card-mask {
    border-color: #406EFF;
  }

  .custom-radio-card-checked {
    background-color: var(--color-primary-light-1);
  }

  .custom-radio-card:hover .custom-radio-card-title,
  .custom-radio-card:hover .arco-typography-secondary,
  .custom-radio-card-checked .custom-radio-card-title,
  .custom-radio-card-checked .arco-typography-secondary {
    color: #4A76FF;
  }

  .custom-radio-card-checked .custom-radio-card-mask-dot {
    background-color: #4A76FF;
  }

  // .custom-radio-card:hover .arco-typography-secondary{
  //   color: #4A76FF;
  // }
  .custom-Group .arco-icon-hover {
    display: none !important;
  }

  .arco-modal-footer {
    .arco-btn-secondary {
      width: 98px;
      height: 48px;
      background: #e7eeff;
      border-radius: 14px;
      color: #406eff;
    }

    .arco-btn-primary {
      width: 98px;
      height: 48px;
      background: #406eff;
      border-radius: 14px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

  }

  .arco-modal-footer>.arco-btn {
    margin-left: 18px;
  }

}