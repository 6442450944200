.IdentitySelection {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 999;

  .IdentitySelection-left {
    position: relative;
    width: 40%;
    // width: 718px;
    min-width: 386px;
    height: 100%;
    font-size: 40px;
    color: #ffffff;
    background: #406eff;
    background-image: url('/asserts/mmmotif.png');
    background-size: cover;
    background-repeat: no-repeat;

    .yoo_logo {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 140px;
      height: 18px;
      background-image: url('/asserts/yoo_logo.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    .IdentitySelection-left-describe {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .IdentitySelection-left-h1 {
        font-size: 40px;
      }

      .IdentitySelection-left-text {
        opacity: 0.8;
        font-size: 20px;
        letter-spacing: 10px;
        margin-top: 9px;
      }
    }
  }

  .IdentitySelection-right {
    position: relative;
    width: 60%;
    min-width: 500px;
    color: #202124;
    font-size: 30px;
    text-align: center;

    .IdentitySelection-list-title {
      // margin-top: 46px;
    }

    .IdentitySelection-list {
      margin-top: 46px;

      .IdentitySelection-item {
        width: 418px;
        height: 93px;
        font-size: 22px;
        border: 1px solid rgba(32, 33, 36, 0.1);
        margin-bottom: 29px;
        padding: 0 13px;
        padding-right: 21px;
        text-align: left;
        cursor: pointer;
        transition: box-shadow .2s linear;

        &:hover {}

        .company-name {
          font-size: 22px;
          color: #202124;
        }

        .user-name {
          font-size: 20px;
          color: #919499;
        }
      }

      .card-custom-hover-style {
        transition-property: all;
      }

      .card-custom-hover-style:hover {
        transform: translateY(-4px);
      }
    }
  }



  /* 动画绑定 */
  .move_right {
    -webkit-animation-name: move_right;
    animation-name: move_right;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .move_left {
    -webkit-animation-name: move_left;
    animation-name: move_left;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .move_up {
    -webkit-animation-name: move_up;
    animation-name: move_up;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .fadeIn {
    -webkit-transform: translateX(120px);
    transform: translateX(120px);
    opacity: 1;
  }

  .fadeInUp {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
    opacity: 1;
    -webkit-transition: -webkit-transform .2s ease-out, opacity .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out;
  }

  .fadeOutLeft {
    -webkit-transform: translateX(-120px);
    transform: translateX(-120px);
    opacity: 0.0;
    -webkit-transition: -webkit-transform .2s ease-out, opacity .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out;
  }

  /* 动画定义 */
  @-webkit-keyframes move_right {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes move_right {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @-webkit-keyframes move_left {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes move_left {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
  }

  @-webkit-keyframes move_up {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-250px);
      transform: translateY(-250px);
    }
  }

  @keyframes move_up {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-250px);
      transform: translateY(-250px);
    }
  }
}