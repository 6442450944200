.semanticprototype {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      background: #406eff;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 116px;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

  }

  .Continuation-input {
    .arco-textarea {
      resize: none;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      padding: 19px 16px;
    }
  }

  .semanticprototype-Layout {
    width: 100%;
    height: 100%;
    margin-top: 32px;

    .arco-layout-sider-light.Sider-left {
      width: 509px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 22px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-left: 3.3rem;
    }

    .Sider-left-w {
      width: 100%;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 2.8rem 3.2rem;

      .Upload-pictures-title {
        font-size: 14px;
        color: #4e5969;
        margin-top: 1.7rem;
        margin-bottom: 1.1rem;
      }

      .Upload-pictures-tip {
        font-size: 0.14em;
        color: #8e95a1;
        margin-top: 18px;
      }

      .upload-demo-trigger {
        display: flex;
        // flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        -webkit-flex-direction: row-reverse;

        .arco-upload-list {
          width: auto !important;
        }

      }

      .upload-demo-trigger .trigger {
        width: 126px;
        height: 150px;
        color: #88909d;
        font-size: 12px;
        border: 1px solid #d8e0f0;
        border-radius: 6px;
        text-align: center;
        background-color: transparent;
        // margin-left: 31px;

      }

      .arco-card.upload-demo-list:nth-of-type(3) {
        margin-right: 0px;
      }

      .arco-card.upload-demo-list {
        position: relative;
        width: 126px;
        height: 150px;
        border: 1px solid #d8e0f0;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 32px;

        .arco-card-cover {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0px;

          img {
            width: 100%;
            height: auto !important;
            margin: auto;
          }

          img.fileType {
            width: 82px;
            height: 116px;
          }
        }

        .arco-card-body {
          display: none;
          position: absolute;
          width: 100%;
          background: rgba(0, 0, 0, 0.70);
          bottom: 0;
          right: 0;
          font-size: 16px;

          .arco-card-actions {
            margin-top: 0;
          }

          .file-Delete {
            position: relative;
            width: 100%;
            color: #ffffff;

            &:hover {
              color: #406EFF;
            }

            &:active {
              color: #0E42D2;
            }
          }
        }

        &:hover {
          .arco-card-body {
            display: block;
          }
        }
      }
    }

    .semanticprototype-title {
      font-size: 16px;
      color: #0a1629;
    }

    .Upload-pictures-bottom {
      width: 100%;
      text-align: right;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }

    .pdf-link {
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 15.2rem;
    }

    .IconFilePdf {
      color: rgb(223, 132, 132);
    }
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 22px;
    padding: 26px;
    padding-top: 28px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    text-align: left;

    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      padding-left: 7px;
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      margin-top: 17px;
      overflow: auto;

      .Continuation-list {
        width: 100%;
        height: auto;
        text-align: left;
        -webkit-column-count: 4;
        /* Chrome, Safari, Opera */
        -moz-column-count: 4;
        /* Firefox */
        column-count: 4;
        -webkit-column-gap: 2.7rem;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 2.7rem;
        /* Firefox */
        column-gap: 2.7rem;

        .Continuation-item {
          display: inline-block;
          // width: 25%;
          height: auto;
          background: transparent;
          border-radius: 6px;
          // padding: 0 7px;
          padding-bottom: 2.7rem;
          cursor: pointer;
          break-inside: avoid;

          .arco-avatar-image,
          .arco-avatar {
            width: 100%;
            height: auto;
          }

          .Continuation-img {
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 6px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            overflow: hidden;
            // img{
            //     width: auto;
            //     height: 100%;
            // }
          }

          .Continuation-img:hover:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: rgba(0, 0, 0, 0.10);
            border-radius: 6px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            z-index: 2;
          }

        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 16.6rem;
      height: 4.8rem;
      background: #ffffff;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      color: #0b172a;
      margin-top: 2rem;
      border: 1px solid #d8e0f0;
      border-radius: 1rem;
    }

    .Continuation-loding {
      margin-top: 22.8rem;
    }

    .Spin-loding {
      margin-bottom: 2rem;
    }
  }

  .Continuation-Empty {
    margin-top: 22.8rem;
  }
}


.arco-modal.custom-modal-download-sem {
  padding: 30px 29px;
  border-radius: 8px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

  .arco-modal-footer {
    padding: 0;
    padding-top: 20px;
    text-align: center;
  }

  .arco-btn.download-btn {
    height: 44px;
    border-radius: 10px;
  }

  .arco-modal-content {
    padding: 0;
  }

  .custom-modal-download-content {
    width: 100%;
    max-height: 700px;
    background: #ffffff;
    overflow: auto;

    img {
      width: 100%;
      height: auto;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10)
    }
  }

  .arco-modal-close-icon {
    top: 0px;
    right: -53px;
    width: 34px;
    height: 34px;
    background: #f5f8ff;
    font-size: 16px;
    color: #0a1629;
    border-radius: 7px;
    line-height: 34px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}