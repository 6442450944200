.main,
.layout-basic-demo {
  width: 100%;
  height: 100%;
}

.layout-basic-demo .arco-layout-header,
.layout-basic-demo .arco-layout-footer,
.layout-basic-demo .arco-layout-sider,
.layout-basic-demo .arco-layout-sider-children,
.layout-basic-demo .arco-layout-content {
  color: var(--color-white);
  text-align: center;
  font-stretch: condensed;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.arco-spin.spin_nopadding{
  padding: 0 !important;
}
.Upgrade-svp.arco-btn {
  color: #34302D !important;
  box-shadow: 0px 6px 12px 0px rgba(152, 134, 91, 0.26);
}

.scale-box {
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s;
}

.imgsheoll {
  width: 464px;
  height: 168px;
  background: #406eff;
  border-radius: 24px;
  background-image: url("/asserts/heoll_img.svg");
}

.userlogo {
  position: relative;
  width: 113px;
  height: 65px;
  overflow: hidden;
  .enterprise_logo{
    width: 100%;
  }
}

.Tag-bordered {
  position: relative;
  z-index: 1;
}

.Tag-bordered::before {
  position: absolute;
  left: 1px;
  top: 1px;
  content: '';
  background: #ffffff;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 4px;
  z-index: -1;
}

.heollmodal.arco-modal {
  width: 564px;
  height: 419px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-checkbox {
    margin-top: 31px;
    margin-bottom: 4px;
  }

  .Exclamation-tip {
    font-size: 14px;
    color: #ff7d00;
    padding-left: 38px;
  }

  .Exclamation-open {
    margin-top: 28px;
  }

  .arco-checkbox-mask {
    width: 20px;
    height: 20px;
    border: 1px solid #e7ebf5;
    border-radius: 4px;
  }

  .arco-checkbox-text {
    color: #1d2129;
    font-size: 14px;
    margin-left: 13px;
  }
}

.heollmodal .arco-modal-content {
  padding: 50px 54px !important;
  box-sizing: border-box;
}

.Contactmodal .arco-modal-content {
  padding: 54px 28px !important;
  padding-top: 29px !important;
  box-sizing: border-box;
}

.Contactmodal.arco-modal {
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);
}

.Contactmodal {
  width: 472px;
  height: auto;
  background: #ffffff;
  .arco-modal-header {
    height: 70px;
  }

  .arco-modal-close-icon {
    width: 34px;
    height: 34px;
    background: #f5f8ff;
    font-size: 16px;
    color: #0a1629;
    border-radius: 7px;
    line-height: 34px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }

  .serviceQRcode {
    width: 247px;
    height: 244px;
    margin: auto;
    background-image: url('/asserts/serviceQRcode.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 53px;
  }
}
.arco-modal.Contactmodal.visit{
  width: 715px;
  .arco-modal-content{
    padding: 0 72px 72px !important;
    box-sizing: border-box;
  }
  .keyinfo{
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #0A1629;
    margin:0 8px;
    width: 600px;
    .keyinfo-title{
      font-size: 20px;
      margin-bottom: 37px;
    }
    .key-li{
      display: block;
      width: 120px;
      text-align: right;
    }
    .key-value{
      display: flex;
      justify-content: space-between;
      width: 400px;
      padding: 12px 28px;
      text-align: center;
      background: #E7ECF7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-left: 2px;
      box-sizing: border-box;
    }
    .operation-copy{
      padding:0px 4px;
      border-radius: 2px;
      box-sizing: border-box;
      .arco-icon{
        color: #406EFF;
      }
      &:hover{
        background: #EEF3FF;
        cursor: pointer;
      }
      &:active{
        .arco-icon{
          color: #2759E0;
        }
      }
    }
    .arco-btn{
      width: 102px;
      height: 48px;
      border-radius: 14px;
      margin-top: 52px;
    }
  }
  .demo-trigger-popup {
    padding: 10px;
    width: 154.5px;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #E5E6EB;
    box-shadow: 0px -3px 12px 0px rgba(196, 203, 214, 0.3);
  }
  .arco-trigger-arrow{border: 1px solid #E5E6EB;background-color: #ffffff;}
}
.arco-modal.enterprise{
  width: 517px;
  max-height: 580px;
  border-radius: 24px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.2);
  .arco-modal-close-icon {
    width: 34px;
    height: 34px;
    font-size: 16px;
    color: #0a1629;
    border-radius: 7px;
    line-height: 34px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
  .enterprise-hell{
    padding: 21px;
    padding-top: 34px;
    color: #1D2129;
    box-sizing: border-box;
    .enterprise-hell-title{
      font-size: 24px;
      margin-top: 9px;
    }
    .enterprise-hell-line{
      width: 100%;
      text-align: center;
      color: #AC7522;
      font-size: 20px;
      margin-top: 45px;
      padding-bottom: 16px;
      span{
        margin: 0 16px;
      }
      div{
        width: 118.5px;
        height: 2px;
        border-radius: 2px;
      }
      div:nth-of-type(1){
        background: linear-gradient(to right, rgba(0,0,0,0) 0%, #FFF3D4 , #FFD87E);
      }
      div:nth-of-type(2){
        background: linear-gradient(to left, rgba(0,0,0,0) 0%, #FFF3D4 , #FFD87E);
      }
    }
    .identity-level{
      position: absolute;
      right: 42px;
      top: 60px;
    }
    .identity-level-bg{
      width: 105px;
      height: 83.12px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: bottom;
    }
    .svip1_icon{
      background-image: url('/asserts/svip1_icon.svg');
    }
    .svip2_icon{
      background-image: url('/asserts/svip2_icon.svg');
    }
    .svip3_icon{
      background-image: url('/asserts/svip3_icon.svg');
    }
    .enterprise-hell-check{
      padding: 0 40px;
      font-size: 20px;
      .enterprise-hell-check-item{
        margin-top: 23px;
      }
      .hell-check{
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: linear-gradient(270deg, #EAB87C 0%, #F8D9AD 100%);
        margin-right: 12px;
        text-align: center;
        .arco-icon{
          stroke-linecap: round; stroke-linejoin: round;
          color: #FFFFFF;
        }
      }
    }
    .enterprise-btns{
      text-align: center;
      margin-top: 60px;
    .enterprise-btn{
      width: 366px;
      height: 65px;
      outline: none;
      border: 0;
      border-radius: 6.75px;
      margin: auto;
      font-size: 22px;
      color: #34302D;
      cursor: pointer;
    }
  }
  }
}
.Support {
  position: relative;
  width: 141px;
  height: 151px;
  background-image: url('/asserts/Support.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: auto;
  margin-bottom: 18px;

  .arco-btn {
    position: absolute;
    left: 18.5px;
    bottom: 20px;
    width: 104px;
    height: 39px;
    border-radius: 14px;
    font-size: 12px;
  }

  &:hover {
    width: 161px;
    height: 151px;
    background-image: url('/asserts/Support_hover.png');
    left: 10px;

    .arco-btn {
      left: 18.5px;
    }
  }
  &.vipServices{
    width: 141px;
    height: 150px;
    background-image: url('/asserts/vipServices.svg');
    &:hover {
      background-image: url('/asserts/vipServices.svg');
      left: 0px;
    }
  }
}
.global_prompt{
  .arco-modal-footer{
    .arco-btn{
      min-width: 87px;
      height: 48px;
      border-radius: 14px;
    }
  }
  &.arco-modal .arco-modal-close-icon{
    font-size: 16px;
  }
}
.yoo-link{
  color: #86909C;
}
.identityVip{
  .Support {
    position: relative;
    width: 141px;
    height: 151px;
    background-image: url('/asserts/Support_vip.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin: auto;
    margin-bottom: 18px;
    .arco-btn {
      position: absolute;
      left: 19px;
      bottom: 20px;
      width: 104px;
      height: 39px;
      border-radius: 14px;
      font-size: 12px;
    }
    &:hover {
      width: 164px;
      background-image: url('/asserts/Support_vip_hover.png');
      left: 12px;
      // .arco-btn {
      //   left: 18px;
      // }
      svg{
        margin-left: -1px;
      }
      span{
        margin-left: 8px;
      }
    }
  }
  .yoo-link{
    color: #897160;
    &:hover{
      color: #AD7623;
    }
  }
}