.arco-modal.UpgradeModal {
  width: 789px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 6px 58px 0px rgba(121, 145, 173, 0.20);

  .arco-modal-header {
    height: 80px;
    padding: 0px 36px;
  }

  .arco-modal-title {
    text-align: left;
    font-size: 20px;
  }

  .arco-modal-content {
    max-height: 75.3rem;
    padding: 0 56px;
    padding-bottom: 40px;
    overflow: auto;

    .serviceQRcode {
      position: relative;
      width: 256px;
      height: 253px;
      margin: auto;
      background-image: url('/asserts/serviceQRcode.png');
      background-repeat: no-repeat;
      background-size: 100%;
      margin-bottom: 12px;
    }

    .Upgrade-img {
      width: 395px;
      height: 324px;
      background: linear-gradient(136deg, #fffbef 3%, #fcf3d7);
      background-image: url('/asserts/upgrade-img.svg');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 19px;
      text-align: center;

      .arco-typography {
        color: #c7a23d;
      }

      div.arco-typography {
        color: #DCB86A;
      }
    }
  }

  .arco-modal-close-icon {
    width: 44px;
    height: 44px;
    background: #f5f8ff;
    border-radius: 14px;
    text-align: center;
    line-height: 44px;
  }

  .arco-icon-hover::before {
    width: 44px;
    height: 44px;
    border-radius: 14px;
  }
}